import React from "react";

function CustomTextField({ placeholder, id, name }) {
  return (
    <div className="bg-white  h-[130px] max-h-[140px] my-2 w-full">
      <textarea
        className="w-full h-full outline-0 resize-none px-4 py-2 border focus:border-[1.5px] border-[#EDEDED] px-4 focus:border-LightGreen rounded transition-all ease-in duration-200"
        placeholder={placeholder}
        id={id}
        name={name}
      />
    </div>
  );
}
export default CustomTextField;
