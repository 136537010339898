import CustomCheckbox from "../../../../components/general/CustomCheckbox";
import CustomInput from "../../../../components/general/CustomInput";

export const TenthStep = ({ className }) => (
  <div className={className}>
    <h3 className="text-Dark font-bold text-3xl">10. Observações Finais</h3>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Porque é que procura investidores para o seu projeto?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"procura investidores"}
          id="procura investidores"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Como soube do VCapital?
          <CustomInput
            placeholder={"Escreva aqui"}
            type="text"
            name={"VCapital"}
            id="VCapital"
          />
        </p>
      </div>
    </div>
    <div className="flex flex-col">
      <div className="w-full md:w-1/2 md:pr-[10px]">
        <p className="font-semibold text-DarkGray">
          Alguma outra questão relevante que não tenha sido abordada neste
          formulário?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="question"
          name={"question"}
        />
      </div>
    </div>
    <div className="border border-LightGray rounded w-full p-4">
      <CustomCheckbox
        squared
        label={
          "Autorizo que esta informação seja partilhada entre os membros do VCAPITAL."
        }
      />
      <CustomCheckbox
        squared
        label={
          "Estou aberto a ser contactado por empresas que vendem serviços de angariação de fundos e incentivos europeus para propor negócios relacionados com o VCAPITAL."
        }
      />
      <CustomCheckbox
        squared
        label={
          "Estou aberto a ser contactado por empresas que vendem serviços de incubação e aceleração de start-ups relacionados com o VCAPITAL."
        }
      />
    </div>
  </div>
);
