import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function TeamCard({
  img,
  title,
  name,
  linkedin,
  secondary,
  subtitle,
  job,
  credentials,
}) {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-[200px] h-[200px] mb-5">
        <img src={img} alt="" className="object-contain w-full h-full" />
      </div>
      <p
        className={`${
          secondary ? "text-Salmon" : "text-LightGreen"
        } text-sm font-extrabold uppercase text-center`}
      >
        {title}{" "}
        {subtitle && <span className="text-[#FF9A7E80]">{subtitle}</span>}
      </p>
      {credentials && (
        <p
          className={`${
            secondary ? "text-Salmon" : "text-LightGreen"
          } text-sm font-medium uppercase text-center`}
        >
          {credentials}
        </p>
      )}
      <p className="text-Gray font-semibold text-center">{name}</p>
      {job && (
        <p className="text-Gray uppercase font-semibold text-center text-xs">
          {job}
        </p>
      )}
      {linkedin && (
        <a
          href={linkedin}
          className="text-Dark"
          target={"_blank"}
          rel="noopener noreferrer"
        >
          <LinkedInIcon />
        </a>
      )}
    </div>
  );
}

export default TeamCard;
