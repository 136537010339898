import React, { useEffect } from "react";

import AnthonyLogo from "../../../assets/portfolio/anthony_icon.svg";
import BiomaskLogo from "../../../assets/portfolio/biomask_icon.svg";
import HWLLogo from "../../../assets/portfolio/hwl_icon.svg";
import IlhaPneuLogo from "../../../assets/portfolio/ilhapneu_icon.png";
import NMDSLogo from "../../../assets/portfolio/nmds_icon.svg";
import PneuzarcoLogo from "../../../assets/portfolio/pneuzarco_icon.svg";
import PoderLogo from "../../../assets/portfolio/poder_icon.png";
import SeehealthLogo from "../../../assets/portfolio/seehealth_icon.svg";
import VipaconnectLogo from "../../../assets/portfolio/vipaconnect_icon.svg";
import VipaenergyLogo from "../../../assets/portfolio/vipaenergy_icon.svg";
import VipainternationalLogo from "../../../assets/portfolio/vipainternational_icon.svg";
import VipalabLogo from "../../../assets/portfolio/vipalab_icon.svg";
import VipaskyLogo from "../../../assets/portfolio/vipasky_icon.svg";
import VipatelecomLogo from "../../../assets/portfolio/vipatelecom_icon.png";
import VipacorpLogo from "../../../assets/portfolio/vipacorp_icon.svg";
import dsLogo from "../../../assets/portfolio/DS_icon.svg";
import corporateLogo from "../../../assets/portfolio/corporate_icon.svg";
import psLogo from "../../../assets/portfolio/PS_icon.svg";
import GDELogo from "../../../assets/portfolio/GDE_icon.svg";

function CompanyItem({ company, className, onClick }) {
  function HandleLogo(company) {
    switch (company) {
      case "vipaconnect":
        return (
          <img
            src={VipaconnectLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      case "vipalab":
        return (
          <img
            src={VipalabLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "anthony":
        return (
          <img
            src={AnthonyLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "biomask":
        return (
          <img
            src={BiomaskLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "hwl":
        return (
          <img src={HWLLogo} alt="" className="w-full h-full object-contain" />
        );

      case "nmds":
        return (
          <img src={NMDSLogo} alt="" className="w-full h-full object-contain" />
        );

      case "pneuzarco":
        return (
          <img
            src={PneuzarcoLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "seehealth":
        return (
          <img
            src={SeehealthLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "vipaenergy":
        return (
          <img
            src={VipaenergyLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "vipainternational":
        return (
          <img
            src={VipainternationalLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "vipasky":
        return (
          <img
            src={VipaskyLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      case "ilhapneu": {
        return (
          <img
            src={IlhaPneuLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      }
      case "vipatelecom": {
        return (
          <img
            src={VipatelecomLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      }
      case "poder": {
        return (
          <img
            src={PoderLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      }
      case "ps": {
        return (
          <img src={psLogo} alt="" className="w-full h-full object-contain" />
        );
      }
      case "corporate": {
        return (
          <img
            src={corporateLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      }
      case "gde": {
        return (
          <img src={GDELogo} alt="" className="w-full h-full object-contain" />
        );
      }
      case "vipacorp": {
        return (
          <img
            src={VipacorpLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      }
      case "ds": {
        return (
          <img src={dsLogo} alt="" className="w-full h-full object-contain" />
        );
      }
      default:
        break;
    }
  }

  useEffect(() => {
    HandleLogo(company);
  }, [company]);
  return (
    <div
      onClick={onClick}
      className={`${className} w-[110px] h-[110px] rounded-full border-[3px] border-[rgba(51,126,132,0.25)] flex items-center justify-center relative z-[1]`}
    >
      <div className="w-[104px] h-[104px] rounded-full border-[6px] border-Light bg-white flex items-center justify-center">
        <div className="w-4/6 h-4/6">{HandleLogo(company)}</div>
      </div>
    </div>
  );
}

export default CompanyItem;
