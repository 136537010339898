import React, { useEffect, useRef, useState } from "react";
import InnerHero from "../../components/general/InnerHero";
import AboutBG from "../../assets/about/about_bg.png";
import ContentImg from "../../components/general/ContentImg";
import Wrapper from "../../components/general/Wrapper";
import styled from "styled-components";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InnerNavigation from "../../components/general/InnerNavigation";

import SustainBG from "../../assets/about/about_sustain_bg.png";
import Mission from "../../assets/about/about_mission.png";
import Innovative from "../../assets/about/about_innovative.png";

import CapitalBrand from "../../assets/about/about_capital.svg";
import Buildings from "../../assets/about/about_buildings.svg";
import ResourcesIcon from "../../assets/about/about_resources.svg";
import ExperienceIcon from "../../assets/about/about_experience.svg";
import ConnectionIcon from "../../assets/about/about_connection.svg";
import MonitorIcon from "../../assets/about/about_monitor.svg";
import InnovationIcon from "../../assets/about/about_innovation.svg";
import EconomyIcon from "../../assets/about/about_economy.svg";
import SocialIcon from "../../assets/about/about_social.svg";
import LandScape from "../../assets/about/about_values_bg.svg";
import Digital from "../../assets/about/about_digital.svg";
import Circuit from "../../assets/about/about_circuit.svg";
import Health from "../../assets/about/about_health.svg";
import Energy from "../../assets/about/about_energy.svg";
import Governance from "../../assets/about/about_governance.svg";
import Pot1 from "../../assets/about/about_pot_1.svg";
import Pot2 from "../../assets/about/about_pot_2.svg";
import Pot3 from "../../assets/about/about_pot_3.svg";
import Pattern from "../../assets/about/about_pattern_bg.svg";

import { ReactComponent as LargeFan } from "../../assets/hero/large_fan.svg";
import { Link, useNavigate } from "react-router-dom";

const AnimationDiv = styled("div")`
  & #large_fan {
    & #fan {
      & #fan_center {
        transform-origin: 65px 65px;
        -webkit-animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          infinite both;
        animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s
          infinite both;
      }
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
function About() {
  const navigate = useNavigate();

  const Break = styled.div`
    height: 23px;
    border: 1px solid #ebeff2;
    border-radius: 0.25rem;
  `;

  const sectionRef = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry?.isIntersecting) {
            setIsAnimated(true);
            observer?.unobserve(sectionRef.current);
          }
        });
      },
      { rootMargin: "-250px", threshold: 0.5 }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        // eslint-disable-next-line
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <>
      <InnerHero img={AboutBG} alt="" />
      <InnerNavigation
        actualPage={"Quem Somos"}
        innerSections={
          <div className="flex gap-5 text-Gray font-semibold">
            <a href="#mission" className="hover:text-LightGreen">
              Missão e Valores
            </a>
            <a href="#strategy" className="hover:text-LightGreen">
              Estratégias
            </a>
          </div>
        }
        routerLinks={
          <div className="flex gap-2 text-Gray font-semibold">
            <Link to={"/about/team"} className="hover:text-LightGreen">
              Equipa
            </Link>
            <Break />
            <Link to={"/about/sustain"} className="hover:text-LightGreen">
              Sustentabilidade e Responsabilidade
            </Link>
            <Break />
            <Link to={"/about/society"} className="hover:text-LightGreen">
              Identificação da Sociedade
            </Link>
            <Break />
            <Link to={"/about/regulation"} className="hover:text-LightGreen">
              Regulamentos
            </Link>
          </div>
        }
      />
      <div className="relative w-full z-[1] py-10 overflow-hidden">
        <Wrapper>
          <ContentImg
            img={CapitalBrand}
            subHeader={
              <h4 className="font-extrabold uppercase text-Salmon text-sm">
                QUEM SOMOS
              </h4>
            }
            header={
              <h3 className="text-Dark text-2xl font-bold my-2 leading-8">
                Somos a ponte para a construção de um futuro mais sustentável.
              </h3>
            }
            content={
              <p className="text-Gray text-justify leading-8 mt-5">
                A VCapital é uma sociedade veículo de investimento em empresas
                que prezam a sustentabilidade ambiental, social e financeira.
                <br />
                O nosso principal foco vai além do fornecimento de recursos
                financeiros. Apoiamos e acompanhamos ativamente as empresas da
                nossa carteira de investimentos de modo a favorecer o sucesso de
                ambas as partes. <br />
                Na VCapital, estamos constantemente à procura de investir em
                projetos inovadores que acrescentem valor não só ao negócio,
                como também ao ambiente socioambiental.
              </p>
            }
          />
          <div className="my-10">
            {/* <p className="text-Gray">
              Consulte o PDF do nosso Dossier de Apresentação
            </p>
            <p className="text-Gray font-semibold flex items-center gap-2 mt-2 hover:text-Salmon cursor-pointer">
              <span>
                <FileCopyIcon fontSize="medium" />
              </span>
              Dossier de Apresentação
            </p> */}
          </div>
        </Wrapper>
        <img
          src={Buildings}
          alt=""
          className="absolute -bottom-20 object-bottom object-contain h-full w-full md:-right-[25%] z-[-1]"
        />
      </div>
      <div className="w-full relative min-h-[600px] md:h-[600px] z-[2] overflow-hidden py-20">
        <div className="relative z-[3] flex items-center" ref={sectionRef}>
          <Wrapper>
            <h4
              className={`text-Salmon uppercase text-center mb-5 font-extrabold text-sm ${
                isAnimated ? "animate-fade-in" : "hidden"
              }`}
            >
              O QUE FAZEMOS
            </h4>
            <h3
              className={`text-white font-bold text-center text-2xl leading-8 ${
                isAnimated ? "animate-fade-in" : "hidden"
              }`}
            >
              Apoiamos startups através do nosso conhecimento <br /> e
              competências adquiridas ao longo dos anos.
            </h3>
            <div
              className={`flex flex-col gap-10 md:flex-row items-center justify-around mt-24 ${
                isAnimated ? "animate-fade-in" : "hidden"
              }`}
            >
              <div className="basis-full md:basis-1/4 flex flex-col items-center gap-5">
                <img
                  src={ExperienceIcon}
                  alt=""
                  className="max-w-[90px] max-h-[90px]"
                />
                <p className="text-white text-center text-lg">
                  Experiência e <br />
                  Conhecimento
                </p>
              </div>
              <div className="basis-full md:basis-1/4 flex flex-col items-center gap-5">
                <img
                  src={ResourcesIcon}
                  alt=""
                  className="max-w-[90px] max-h-[90px]"
                />
                <p className="text-white text-center text-lg">
                  Recursos e<br /> Oportunidades
                </p>
              </div>
              <div className="basis-full md:basis-1/4 flex flex-col items-center gap-5">
                <img
                  src={ConnectionIcon}
                  alt=""
                  className="max-w-[90px] max-h-[90px]"
                />
                <p className="text-white text-center text-lg">
                  Conexão com <br />
                  especialistas do sector
                </p>
              </div>
              <div className="basis-full md:basis-1/4 flex flex-col items-center gap-5">
                <img
                  src={MonitorIcon}
                  alt=""
                  className="max-w-[90px] max-h-[90px]"
                />
                <p className="text-white text-center text-lg">
                  Orientação e<br /> Monitorização
                </p>
              </div>
            </div>
          </Wrapper>
        </div>
        <div className="w-full h-full absolute backdrop-blur bg-Dark/30 z-[1] top-0" />
        <img
          src={SustainBG}
          alt=""
          className="absolute w-full h-full object-cover z-[-1] top-0"
        />
      </div>
      <Wrapper>
        <div className="bg-Light rounded-lg p-3 md:p-10 my-20" id="mission">
          <ContentImg
            img={Mission}
            reverse
            subHeader={
              <h4 className="font-extrabold uppercase text-Dark text-sm">
                <span className="text-darkGreen">PORQUE O FAZEMOS</span> •
                MISSÃO
              </h4>
            }
            header={
              <h3 className="text-Dark text-2xl font-bold leading-8 my-5">
                Queremos transformar o mundo através do empreendedorismo
                sustentável.
              </h3>
            }
            content={
              <>
                <p className="text-Gray leading-8">
                  Acreditamos que na diversidade se encontra a geração de novas
                  ideias - e utilizar essas ideias para tornar o mundo um lugar
                  melhor, faz com que o nosso trabalho obtenha o seu verdadeiro
                  significado.
                </p>
                <p className="text-Gray leading-8 mt-5">
                  Somos movidos pelos nossos valores e procuramos, acima e em
                  tudo o que fazemos, contribuir para uma sociedade mais
                  sustentável e desenvolvida, onde a promoção pelos direitos
                  humanos e a dignidade, o apoio pela justiça social e
                  económica, e a proteção e revitalização dos sistemas naturais
                  da Terra se tornam no{" "}
                  <span className="text-Gray font-semibold">
                    centro da nossa missão.
                  </span>
                </p>
              </>
            }
          />
          <div className="flex flex-col gap-20 md:flex-row items-start justify-around mt-20">
            <div className="basis-full md:basis-1/3 flex flex-col items-center gap-5">
              <div className="w-32 h-32 rounded-full bg-[#337E841A] flex items-center justify-center">
                <img
                  src={InnovationIcon}
                  alt=""
                  className="max-w-[90px] max-h-[90px]"
                />
              </div>
              <p className="text-darkGreen text-center text-xl font-bold">
                Missão de Inovação
              </p>
              <p className="text-Gray text-center leading-8 text-lg">
                Um compromisso contínuo de incorporar a inovação, o dinamismo e
                a relevância de forma a promover soluções inteligentes.
              </p>
            </div>
            <div className="basis-full md:basis-1/3 flex flex-col items-center gap-5">
              <div className="w-32 h-32 rounded-full bg-[#337E841A] flex items-center justify-center">
                <img
                  src={EconomyIcon}
                  alt=""
                  className="max-w-[90px] max-h-[90px]"
                />
              </div>
              <p className="text-darkGreen text-center text-xl font-bold">
                Missão Económica
              </p>
              <p className="text-Gray text-center leading-8 text-lg">
                Uma base financeira sustentável de crescimento rentável aumenta
                o valor para todas as partes interessadas, expandindo as
                oportunidades de desenvolvimento e crescimento.
              </p>
            </div>
            <div className="basis-full md:basis-1/3 flex flex-col items-center gap-5">
              <div className="w-32 h-32 rounded-full bg-[#337E841A] flex items-center justify-center">
                <img
                  src={SocialIcon}
                  alt=""
                  className="max-w-[90px] max-h-[90px]"
                />
              </div>
              <p className="text-darkGreen text-center text-xl font-bold">
                Missão Social
              </p>
              <p className="text-Gray text-center leading-8 text-lg">
                Formas inovadoras para melhorar a qualidade de vida e o ambiente
                social.
              </p>
            </div>
          </div>
        </div>
      </Wrapper>
      <div className="w-full min-h-[800px] md:h-[800px] relative overflow-hidden bg-Light">
        <Wrapper>
          <div className="flex flex-col items-center h-full justify-center py-10">
            <h4 className="font-extrabold uppercase text-Salmon">
              os nossos valores
            </h4>
            <h3 className="text-Dark text-2xl font-bold">
              Valores que cultivam o nosso sucesso
            </h3>
            <div className="flex flex-col md:flex-row justify-around w-full mt-20 gap-10 md:gap-0">
              <div className="group/item flex flex-col relative h-auto md:h-[440px] z-[1]">
                <div className=" w-[100px] md:w-[220px] h-[220px] flex items-center justify-center bg-[#005267] shadow-[0px_2px_25px_rgba(9,51,66,0.08)]">
                  <p className="uppercase font-extrabold text-white text-xl -rotate-90 md:rotate-0">
                    Lealdade
                  </p>
                </div>
                <div className="w-[220px] h-[220px] bg-[#004152] p-3 absolute z-[-1] top-0 transition-all duration-300 group-hover/item:md:top-[220px] left-[100px] md:left-[0] flex items-center">
                  <p className="text-white text-center">
                    A VCapital assumiu a responsabilidade de ser leal ao cumprir
                    as suas promessas e tratar todos os colaboradores, sócios e
                    demais interessados com o devido respeito.
                  </p>
                </div>
              </div>
              <div className="group/item flex flex-col relative h-auto md:h-[440px] z-[1] self-end">
                <div className=" w-[100px] md:w-[220px] h-[220px] flex items-center justify-center bg-[#337E84] shadow-[0px_2px_25px_rgba(9,51,66,0.08)]">
                  <p className="uppercase font-extrabold text-white text-xl rotate-90 md:rotate-0">
                    Rigor
                  </p>
                </div>
                <div className="w-[220px] h-[220px] bg-[#006D75] p-3 absolute z-[-1] top-0 transition-all duration-300 group-hover/item:md:top-[220px] right-[100px] md:right-[0] flex items-center">
                  <p className="text-white text-center">
                    Apresentamos uma visão, orientação e mentoria valiosa por
                    meio de um elevado padrão de qualidade, profissionalismo e
                    responsabilidade para o bem estar da sociedade.
                  </p>
                </div>
              </div>
              <div className="group/item flex flex-col relative h-auto md:h-[440px] z-[1]">
                <div className="w-[100px] md:w-[220px] h-[220px] flex items-center justify-center bg-[#32988F] shadow-[0px_2px_25px_rgba(9,51,66,0.08)]">
                  <p className="uppercase font-extrabold text-white text-xl -rotate-90 md:rotate-0">
                    Transparência
                  </p>
                </div>
                <div className="w-[220px] h-[220px] bg-[#2F8D85] p-3 absolute z-[-1] top-0 transition-all duration-300 group-hover/item:md:top-[220px]  left-[100px] md:left-[0] flex items-center">
                  <p className="text-white text-center">
                    Tanto nas relações empresariais internas como externas, a
                    VCapital promove uma cultura empresarial que encoraja a
                    comunicação honesta entre as pessoas.
                  </p>
                </div>
              </div>
              <div className="group/item flex flex-col relative h-auto md:h-[440px] z-[1]">
                <div className=" w-[100px] md:w-[220px] h-[220px] flex items-center justify-center bg-[#51B89F] shadow-[0px_2px_25px_rgba(9,51,66,0.08)] self-end">
                  <p className="uppercase font-extrabold text-white text-xl rotate-90 md:rotate-0">
                    Ambição
                  </p>
                </div>
                <div className="w-[220px] h-[220px] bg-[#4BAA93] p-3 absolute z-[-1] top-0 transition-all duration-300 group-hover/item:md:top-[220px]  right-[100px] md:right-[0] flex items-center">
                  <p className="text-white text-center">
                    A ambição proporciona o impulso interno que nos move a
                    trabalhar para objetivos a longo prazo - e continuar a
                    estabelecer novas metas cada vez mais ambiciosas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
        <img
          src={LandScape}
          alt=""
          className="w-full h-full object-cover top-20 absolute"
        />
      </div>
      <div id="strategy">
        <Wrapper>
          <div className="bg-[#006970] rounded-lg overflow-hidden my-20">
            <div className="p-10">
              <ContentImg
                img={Innovative}
                subHeader={
                  <h4 className="font-extrabold uppercase text-[#D4F0E6] text-sm">
                    ESTRATÉGIA de investimento
                  </h4>
                }
                header={
                  <h3 className="text-white text-2xl font-bold my-4 leading-8">
                    Acreditamos que ideias inovadoras merecem um lugar.
                  </h3>
                }
                content={
                  <p className="text-Light leading-8">
                    Após uma apreciação inicial com os nossos parceiros de
                    empreendimento, avaliamos cuidadosamente o plano de
                    negócios, desde o projeto e a equipa, ao mercado e
                    concorrência circundantes, a fim de decidir o avanço ou não
                    para Investimento. Um conjunto de profissionais experientes
                    nas fases de crescimento Early Stage reúne ainda para
                    fornecer considerações sobre o potencial do negócio, assim
                    como o que este poderá exigir de modo a obter o melhor
                    acompanhamento. Deste modo, conseguimos determinar se a
                    empresa possui o necessário para ser financiada por capital
                    de risco.
                  </p>
                }
              />
              <div>
                <p
                  className="text-Light font-bold flex text-sm uppercase items-center gap-2 hover:text-Salmon cursor-pointer mt-7 md:mt-5"
                  onClick={() => navigate("/investors/politics")}
                >
                  <span>
                    <FileCopyIcon fontSize="medium" />
                  </span>
                  Política de investimento VCAPITAL
                </p>
              </div>
            </div>
            <div className="bg-[#32988F]">
              <div className="py-10 px-5 flex flex-col justify-center items-center text-center">
                <h3 className="text-[#FFFFFF80] text-2xl font-bold ">
                  Em que <span className="text-white">projetos </span>
                  investimos:
                </h3>
                <div className="flex flex-row items-center md:items-start justify-start md:justify-around gap-5 w-full mt-20 snap-x overflow-auto touch-pan-x snap-proximity">
                  <div className="flex flex-col justify-center items-center snap-center basis-full md:basis-auto flex-shrink-0">
                    <div className="bg-LightGreen/25 w-36 h-36 rounded-full flex justify-center items-center ">
                      <img src={Digital} alt="" />
                    </div>
                    <p className="text-xl font-semibold text-LightGray">
                      Tecnologia
                    </p>
                    <p className="text-2xl font-bold text-white">Digital</p>
                  </div>
                  <div className="flex flex-col justify-center items-center snap-center basis-full md:basis-auto flex-shrink-0">
                    <div className="bg-LightGreen/25 w-36 h-36 rounded-full flex justify-center items-center ">
                      <img src={Circuit} alt="" />
                    </div>
                    <p className="text-xl font-semibold text-LightGray">
                      Tecnologia
                    </p>
                    <p className="text-2xl font-bold text-white">Industrial</p>
                  </div>
                  <div className="flex flex-col justify-center items-center snap-center basis-full md:basis-auto flex-shrink-0">
                    <div className="bg-LightGreen/25 w-36 h-36 rounded-full flex justify-center items-center ">
                      <img src={Health} alt="" />
                    </div>
                    <p className="text-xl font-semibold text-LightGray">
                      Tecnologia da
                    </p>
                    <p className="text-2xl font-bold text-white">Saúde</p>
                  </div>
                  <div className="flex flex-col justify-center items-center snap-center basis-full md:basis-auto flex-shrink-0">
                    <div className="bg-LightGreen/25 w-36 h-36 rounded-full flex justify-center items-center ">
                      <img src={Energy} alt="" />
                    </div>
                    <p className="text-xl font-semibold text-LightGray">
                      Energias
                    </p>
                    <p className="text-2xl font-bold text-white">Renováveis</p>
                  </div>
                  <div className="flex flex-col justify-center items-center snap-center basis-full md:basis-auto flex-shrink-0">
                    <div className="bg-LightGreen/25 w-36 h-36 rounded-full flex justify-center items-center ">
                      <img src={Governance} alt="" />
                    </div>
                    <p className="text-xl font-semibold text-LightGray">
                      Sistema
                    </p>
                    <p className="text-2xl font-bold text-white">
                      E-Governance
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#278181]">
              <div className="p-10 flex flex-col justify-center items-center text-center">
                <h3 className="text-[#FFFFFF80] text-2xl font-bold">
                  Em que <span className="text-white">fases</span> investimos:
                </h3>
                <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-5 md:gap-0 w-full mt-20">
                  <div className="flex flex-col justify-center items-center gap-5 text-center">
                    <div className="h-[130px]">
                      <img
                        src={Pot1}
                        alt=""
                        className="object-contain h-full"
                      />
                    </div>
                    <p className="text-2xl font-bold text-white">Pré-Seed</p>
                    <p className="font-normal text-white leading-8">
                      Análise do produto bem como do seu
                      <br /> mercado e potenciais consumidores.
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center gap-5 text-center">
                    <div className="h-[130px]">
                      <img
                        src={Pot2}
                        alt=""
                        className="object-contain h-full"
                      />
                    </div>
                    <p className="text-2xl font-bold text-white">Seed</p>
                    <p className="font-normal text-white leading-8">
                      Desenvolvimento e validação do <br />
                      mercado e seus consumidores.
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center gap-5 text-center">
                    <div className="h-[130px]">
                      <img
                        src={Pot3}
                        alt=""
                        className="object-contain h-full"
                      />
                    </div>
                    <p className="text-2xl font-bold text-white">Early Stage</p>
                    <p className="font-normal text-white leading-8">
                      Otimização do produto para os seus
                      <br /> clientes e oportunidade de expansão.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#006970] relative">
              <div className="p-16 flex flex-col justify-center items-center text-center">
                <h3 className="text-[#FFFFFF80] text-2xl font-bold pb-5 md:pb-10">
                  <span className="text-white">Onde</span> investimos:
                </h3>
                <div className="relative pt-2 md:py-0 flex flex-col md:flex-row items-center md:items-start justify-center gap-5 md:gap-0 w-full mt-20">
                  <img
                    src={Pattern}
                    alt=""
                    className="absolute -bottom-5 object-cover h-[100px]"
                  />
                  <h1 className="text-white text-3xl font-bold absolute top-1/2 -translate-y-14 leading-9">
                    Portugal
                  </h1>
                </div>
                <AnimationDiv className="bottom-0 absolute right-0 md:right-10 h-[290px] md:h-[260px]">
                  <LargeFan />
                </AnimationDiv>
                <AnimationDiv className="bottom-0 absolute right-40 h-[230px] hidden md:block">
                  <LargeFan />
                </AnimationDiv>
                <AnimationDiv className="bottom-0 absolute -left-2 md:left-10 scale-x-[-1] h-[200px] md:h-[220px]">
                  <LargeFan />
                </AnimationDiv>
              </div>
            </div>
          </div>
        </Wrapper>
        <div className="bg-darkGreen w-full py-5">
          <div className="w-11/12 md:w-10/12 mx-auto flex flex-col md:flex-row gap-5 justify-between items-start md:items-center">
            <p className="text-white text-xl">
              <b>Possui alguma dúvida?</b> Estamos cá para o ajudar.
            </p>
            <Link
              to={"/contacts"}
              className="bg-white px-10 py-3 uppercase text-Salmon font-bold rounded"
            >
              Contactos
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
