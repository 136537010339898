import CustomCheckbox from "../../../../components/general/CustomCheckbox";
import CustomInput from "../../../../components/general/CustomInput";
import CustomTextField from "../../../../components/general/CustomTextField";

export const SecondStep = ({ className }) => (
  <div className={className}>
    <h3 className="text-Dark font-bold text-3xl">2. Sobre o Projeto</h3>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Designação do Projeto<span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"projeto"}
          id="projeto"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Website (caso exista)<span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"website"}
          id="website"
        />
      </div>
    </div>

    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="basis-full md:basis-1/2 pr-[10px]">
        <p className="font-semibold text-DarkGray">
          Local de Origem<span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"origin"}
          id="origin"
        />
      </div>
    </div>
    <p className="font-semibold text-DarkGray">
      Estado do Projeto<span className="text-[#ED1D24]">*</span>
    </p>

    <p className="font-semibold text-DarkGray">
      Descrição Breve<span className="text-[#ED1D24]">*</span>
    </p>
    <CustomTextField placeholder={"Escreva aqui"} />
    <p className="font-semibold text-DarkGray">
      Selecione uma ou mais áreas em que o seu projeto opera
      <span className="text-[#ED1D24]">*</span>
    </p>
    <CustomCheckbox
      id={"TI"}
      name={"TI"}
      value={"Tecnologias de Informação"}
      label={"Tecnologias de Informação (TI, Software, Blockchain, outros)"}
    />
    <CustomCheckbox
      id={"SB"}
      name={"SB"}
      value={"Saude e Bem-estar"}
      label={
        "Saúde e Bem-estar (Saúde, Farmácia, Biotecnologia, Desporto, Alimentação, outros)"
      }
    />
    <CustomCheckbox
      id={"ER"}
      name={"ER"}
      value={"Energia e Recursos"}
      label={"Energia e Recursos (Energia, Água, Eficiência, outros)"}
    />
    <CustomCheckbox
      id={"F"}
      name={"F"}
      value={"Finanças"}
      label={"Finanças (Fintech, Finanças Descentralizadas, outros)"}
    />
    <CustomCheckbox
      id={"Food"}
      name={"Food"}
      value={"Foodtech"}
      label={"Foodtech (Agricultura, Alimentos e Bebidas, outros)"}
    />
    <CustomCheckbox
      id={"C"}
      name={"C"}
      value={"Criatividade"}
      label={"Criatividade (Media, Conteúdos, Artes, outros)"}
    />
    <CustomCheckbox
      id={"T"}
      name={"T"}
      value={"Turismo"}
      label={"Turismo e Lazer (Hotelaria, Restaurante, Eventos, outros)"}
    />
    <CustomCheckbox
      id={"B"}
      name={"B"}
      value={"Industrial"}
      label={"Bens Industriais"}
    />
    <CustomCheckbox
      id={"S"}
      name={"S"}
      value={"Serviços"}
      label={"Serviços (Educação, Aprendizagem, outros)"}
    />
    <CustomCheckbox
      id={"other"}
      name={"other"}
      value={"Outra"}
      label={"Outra"}
    />
    <p className="font-semibold text-DarkGray">
      Selecione um ou mais temas nos quais esteja interessado em aprender sobre
      projetos de investimento (caso as suas preferências não estejam claramente
      indicadas abaixo, por favor informe-nos):{" "}
      <span className="text-[#ED1D24]">*</span>
    </p>
    <CustomCheckbox
      id={"II"}
      name={"II"}
      value={"investimento"}
      label={"Investimento de Impacto"}
    />
    <CustomCheckbox
      id={"TD"}
      name={"TD"}
      value={"Digital"}
      label={"Transformação Digital"}
    />
    <CustomCheckbox
      id={"AC"}
      name={"AC"}
      value={"Climática"}
      label={"Ação Climática"}
    />
    <CustomCheckbox
      id={"IS"}
      name={"IS"}
      value={"Social"}
      label={"Inovação Social"}
    />
    <CustomCheckbox
      id={"TC"}
      name={"TC"}
      value={"Consumidor"}
      label={"Tendências do Consumidor"}
    />
    <CustomCheckbox
      id={"Inter"}
      name={"Inter"}
      value={"Internacionalização"}
      label={"Internacionalização"}
    />
    <CustomCheckbox
      id={"other"}
      name={"other"}
      value={"Outra"}
      label={"Outra"}
    />
    <div className="flex flex-col">
      <p className="font-semibold text-DarkGray">
        Principais indicadores-chave de desempenho que pretende monitorizar
        <span className="text-[#ED1D24]">*</span>
      </p>
      <div className="w-full md:w-1/2 md:pr-[10px]">
        <CustomInput placeholder={"Escreva aqui"} />
      </div>
    </div>
  </div>
);
