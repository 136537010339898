import CustomCheckbox from "../../../../components/general/CustomCheckbox";
import CustomInput from "../../../../components/general/CustomInput";

export const EightStep = ({ className }) => (
  <div className={className}>
    <h3 className="text-Dark font-bold text-3xl">8. Os Financeiros</h3>
    <p className="text-Gray mb-3">
      Descreva como foi financiada a empresa e quais os seus planos futuros.
    </p>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Quais são os seus conhecimentos sobre capital de risco e equidade
          privada? <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"text"}
          id="text"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Quanto capital já foi investido no projecto?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Quanto capital já foi garantido por outras fontes (se houver)?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"text"}
          id="text"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Quanto capital é necessário dos investidores de business angels?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Possui ou vai tentar outras potenciais fontes de capital? Quais delas?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"text"}
          id="text"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Qual é a proposta financeira para novos investidores do nosso grupo?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
    <div className="flex flex-col">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Quando é que os novos investidores podem precisar uma potencial
          "saída" (venda do seu investimento)?
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomCheckbox type={"radio"} label="2 a 5 anos" />
        <CustomCheckbox type={"radio"} label="5 a 7 anos" />
        <CustomCheckbox type={"radio"} label="Mais de 7 anos" />
      </div>
    </div>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Quem são os potenciais compradores para a empresa?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"text"}
          id="text"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          A que tipo de comprador tentaremos chegar?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
  </div>
);
