import CustomInput from "../../../../components/general/CustomInput";

export const SixthStep = ({ className }) => (
  <div className={className}>
    <h3 className="text-Dark font-bold text-3xl">6. O Impacto</h3>
    <p className="text-Gray mb-3">
      Avaliar o potencial impacto do seu projeto.
    </p>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">Impacto no Ambiente</p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"text"}
          id="text"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">Impacto na Economia</p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">Impacto na Sociedade</p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"text"}
          id="text"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Impacto nos Objetivos de Desenvolvimento Sustentáveis da ONU
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Impacto na Digitalização da Economia
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"text"}
          id="text"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Impacto no Futuro do Trabalho
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
    <div className="flex flex-col">
      <div className="w-full md:w-1/2 md:pr-[10px]">
        <p className="font-semibold text-DarkGray">
          Impacto sobre quaisquer outras questões relevantes não aqui abordadas
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Atividade"
          name={"Atividade"}
        />
      </div>
    </div>
  </div>
);
