import React from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../../../components/general/Wrapper";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CustomInput from "../../../components/general/CustomInput";
import CustomTextField from "../../../components/general/CustomTextField";
import CustomCheckbox from "../../../components/general/CustomCheckbox";
import CustomFileInput from "../../../components/general/CustomFileInput";

function CreateTeaser() {
  const navigate = useNavigate();
  return (
    <>
      <div className=" bg-Light mt-20 border-b border-b-[#E2E8F0]">
        <Wrapper>
          <div
            className="flex items-center gap-2 py-5 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ArrowBackRoundedIcon />
            <span className="text-Gray uppercase text-sm font-bold">
              Retroceder
            </span>
          </div>
          <div className="py-10">
            <h3 className="text-center text-3xl font-bold text-darkGreen mb-5">
              Submeter Teaser
            </h3>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto">
              A VC - SGPS, Lda., comercialmente denomidada de VCapital, é uma
              sociedade de gestão de participações sociais.
            </p>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto my-5">
              Este formulário é dirigido a startups, desde a semente (ideação)
              até ao mercado, desejando apresentar os projetos ao nosso grupo de
              investidores com o objectivo de angariar capital e/ou obter
              mentoria e aconselhamento.
            </p>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto ">
              Em caso de dúvidas, envie por favor um e-mail para
              info@vcapital.pt
            </p>
          </div>
        </Wrapper>
      </div>
      <Wrapper>
        <form className="mt-10">
          <h3 className="text-Dark font-bold text-3xl">1. Identificação</h3>
          <p className="font-semibold text-DarkGray">Nome</p>
          <CustomInput
            placeholder={"Escreva aqui"}
            type="text"
            name={"nome"}
            id="nome"
          />
          <div className="flex gap-5">
            <div className="w-full md:w-1/2 md:pr-[10px]">
              <p className="font-semibold text-DarkGray">
                Email<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"O seu email"}
                type="email"
                name={"email"}
                id="email"
              />
            </div>
          </div>

          <p className="font-semibold text-DarkGray">
            Descrição Breve e Motivação<span className="text-[#ED1D24]">*</span>
          </p>
          <CustomTextField
            placeholder={"Escreva aqui"}
            type="text"
            name={"nome"}
            id="nome"
          />
          <h3 className="text-Dark font-bold text-3xl">
            2. Apresentação do Projeto
          </h3>
          <p className="font-semibold text-DarkGray">
            Designação do Projeto<span className="text-[#ED1D24]">*</span>
          </p>
          <CustomInput
            placeholder={"Escreva aqui"}
            type="text"
            name={"nome"}
            id="nome"
          />
          <p className="font-semibold text-DarkGray">
            Estado do Projeto<span className="text-[#ED1D24]">*</span>
          </p>
          <CustomCheckbox label={"Em Ideação"} />
          <CustomCheckbox label={"Em Arranque"} />
          <CustomCheckbox label={"Em Crescimento (entrada no mercado)"} />
          <CustomCheckbox label={"Em Expansão"} />
          <CustomCheckbox label={"Outra"} />
          <p className="font-semibold text-DarkGray">
            Descrição Breve<span className="text-[#ED1D24]">*</span>
          </p>
          <CustomTextField placeholder={"Escreva aqui"} />
          <p className="font-semibold text-DarkGray">
            Selecione uma ou mais áreas em que o seu projeto opera
            <span className="text-[#ED1D24]">*</span>
          </p>
          <CustomCheckbox
            label={
              "Tecnologias de Informação (TI, Software, Blockchain, outros)"
            }
          />
          <CustomCheckbox
            label={
              "Saúde e Bem-estar (Saúde, Farmácia, Biotecnologia, Desporto, Alimentação, outros)"
            }
          />
          <CustomCheckbox
            label={"Energia e Recursos (Energia, Água, Eficiência, outros)"}
          />
          <CustomCheckbox
            label={"Finanças (Fintech, Finanças Descentralizadas, outros)"}
          />
          <CustomCheckbox
            label={"Foodtech (Agricultura, Alimentos e Bebidas, outros)"}
          />
          <CustomCheckbox
            label={"Criatividade (Media, Conteúdos, Artes, outros)"}
          />
          <CustomCheckbox
            label={"Turismo e Lazer (Hotelaria, Restaurante, Eventos, outros)"}
          />
          <CustomCheckbox label={"Bens Industriais"} />
          <CustomCheckbox label={"Serviços (Educação, Aprendizagem, outros)"} />
          <CustomCheckbox label={"Outra"} />
          <p className="font-semibold text-DarkGray">
            Carregamento da Apresentação (PDF)
            <span className="text-[#ED1D24]">*</span>
          </p>
          <CustomFileInput className={"text-LightGreen"} />
          <div className="border border-LightGray rounded w-full p-4 my-10">
            <CustomCheckbox
              squared
              label={
                "Autorizo que esta informação seja partilhada entre os membros do VCAPITAL."
              }
            />
            <CustomCheckbox
              squared
              label={
                "Estou aberto a ser contactado por empresas que vendem serviços de angariação de fundos e incentivos europeus para propor negócios relacionados com o VCAPITAL."
              }
            />
            <CustomCheckbox
              squared
              label={
                "Estou aberto a ser contactado por empresas que vendem serviços de incubação e aceleração de start-ups relacionados com o VCAPITAL."
              }
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-darkGreen  px-10 py-4 uppercase text-white font-bold rounded text-sm mb-10"
            >
              Submeter
            </button>
          </div>
        </form>
      </Wrapper>
    </>
  );
}

export default CreateTeaser;
