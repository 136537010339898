import React from "react";
import { AfterHero } from "./components/AfterHero";
import { HeroSection } from "./components/HeroSection";
import LastSection from "./components/LastSection";
import StatsSection from "./components/StatsSection";
import Testimonials from "./components/Testimonials";
import WalletSection from "./components/WalletSection";

function HomePage() {
  return (
    <div className="bg-white h-full">
      <HeroSection />
      <AfterHero />
      <StatsSection />
      <Testimonials />
      <WalletSection />
      <LastSection />
    </div>
  );
}

export default HomePage;
