import React from "react";
import CustomCheckbox from "../../../components/general/CustomCheckbox";
import CustomFileInput from "../../../components/general/CustomFileInput";
import CustomInput from "../../../components/general/CustomInput";
import CustomTextField from "../../../components/general/CustomTextField";
import Wrapper from "../../../components/general/Wrapper";

function KYCForm() {
  return (
    <div className="mt-32">
      <Wrapper>
        <h3 className="text-3xl text-center font-bold text-Salmon">
          Submeter ficha de KYC
        </h3>
        <form>
          <p className="font-semibold text-DarkGray">
            Selecione uma ou mais áreas
            <span className="text-[#ED1D24]">*</span>
          </p>
          <CustomCheckbox
            label={"KYC Pessoa Singular"}
            name="singular"
            id="singular"
            type={"radio"}
          />
          <CustomCheckbox
            label={"KYC Pessoa Coletiva"}
            name="coletiva"
            id="coletiva"
            type={"radio"}
          />
          <p className="font-semibold text-DarkGray">Nome Completo</p>
          <CustomInput
            placeholder={"Escreva aqui"}
            type="text"
            name={"nome"}
            id="nome"
          />
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Email<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"O seu email"}
                type="email"
                name={"email"}
                id="email"
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Telemóvel<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"000 000 000"}
                type="number"
                name={"telemovel"}
                id="telemovel"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">Nacionalidade</p>
              <CustomInput
                placeholder={"Escreva aqui"}
                type="text"
                name={"nacionality"}
                id="nacionality"
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Cartão de Identificação
              </p>
              <CustomInput
                placeholder={"000 000 000"}
                type="number"
                name={"id"}
                id="id"
              />
            </div>
          </div>
          <div className="w-full">
            <p className="font-semibold text-DarkGray">Descrição</p>
            <CustomTextField
              placeholder={"Escreva aqui"}
              name={"desc"}
              id="desc"
            />
          </div>
          <p className="font-semibold text-DarkGray">
            Ficheiro KYC (PDF) <span className="text-[#ED1D24]">*</span>
          </p>
          <CustomFileInput className={"text-Salmon"} />
          <div className="justify-center flex my-10">
            <button
              type="submit"
              className="bg-Salmon px-10 py-4 uppercase text-white font-bold rounded text-sm"
            >
              Submeter
            </button>
          </div>
        </form>
      </Wrapper>
    </div>
  );
}

export default KYCForm;
