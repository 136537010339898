import React from "react";
import Wrapper from "../../../components/general/Wrapper";
import { useNavigate } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { ReactComponent as KYCBG } from "../../../assets/investors/kyc_bg.svg";

function KYC() {
  const navigate = useNavigate();

  return (
    <div className="mt-32 relative z-[1]">
      <Wrapper>
        <div className="flex flex-col lg:flex-row gap-5 py-10">
          <div className="basis-1/2">
            <h3 className="text-2xl font-bold text-LightGreen">Ficha KYC</h3>

            <p className="text-lg font-semibold text-LightGreen">
              Elaborado por
              <span className="text-darkGreen"> Direção de Conformidade </span>|
              setembro 2022
            </p>
            <p className="text-Gray mt-10 leading-7">
              A presente Ficha de Identificação consubstancia um tratamento de
              dados pessoais na aferição contida na Lei n.º 58/2019, de 8 de
              agosto. A entidade responsável pelo tratamento dos dados é o Banco
              PortuFIguês de Fomento, S.A. (doravante designado por Banco ou
              BPF), com sede na Rua Prof. Mota Pinto, 42F, 2º, Sala 211,
              4100-353 Porto, Portugal. A finalidade subjacente ao tratamento de
              dados é o cumprimento das obrigações legais que recaem sobre o
              Banco ao abrigo das medidas de Combate ao Branqueamento de
              Capitais e ao Financiamento do Terrorismo – Lei n.º 83/2017, de 18
              de agosto. <br />
              Os destinatários dos dados disponibilizados através da Ficha de
              Identificação são os colaboradores do Banco responsáveis por
              garantir o cumprimento das obrigações legais ao abrigo das medidas
              de Combate ao Branqueamento de Capitais e ao Financiamento do
              Terrorismo, podendo o Banco recorrer a entidades terceiras para
              armazenamento da informação, em conformidade com o Regulamento
              Geral da Proteção de Dados.
              <br /> O preenchimento da presente Ficha de Identificação é
              obrigatório nos termos e em cumprimento do disposto na Lei n.º
              83/2017, de 18 de agosto. Os direitos de acesso e de retificação
              conferidos pela Lei, poderão ser exercidos pelo titular dos dados
              mediante pedido escrito dirigido ao Encarregado da Proteção de
              Dados, através de e-mail – protecao.dados.pessoais@bfomento.pt –
              ou carta registada. <br />
              Nos termos da legislação e regulamentação aplicável, o
              Cliente/Contraparte compromete-se a comunicar, por escrito, ao BPF
              quaisquer alterações quanto aos elementos de identificação ou à
              restante informação disponibilizada na presente Ficha de
              Identificação.
            </p>
          </div>
          <div className="basis-1/2 ">
            <div
              onClick={() => navigate("form")}
              className="bg-Light p-6 mb-5 flex items-center justify-between basis-full md:basis-[calc(50%_-_10px)] cursor-pointer transition-all duration-300 group/item hover:bg-LightGreen"
            >
              <p className="text-lg text-LightGreen font-bold group-hover/item:text-Light">
                Submeter Ficha de KYC
              </p>
              <div className="flex items-center text-darkGreen group-hover/item:text-Light">
                <p className="font-semibold mx-3">Submeter</p>
                <ArrowForwardIcon />
              </div>
            </div>
            <div
              // onClick={} Descarregar PDF
              className="bg-Light p-6 flex items-center justify-between basis-full md:basis-[calc(50%_-_10px)] cursor-pointer transition-all duration-300 group/item hover:bg-LightGreen"
            >
              <p className="text-lg text-LightGreen font-bold group-hover/item:text-Light">
                Descarregar Ficha de Identificação
              </p>
              <div className="flex items-center text-darkGreen group-hover/item:text-Light">
                <a href="/files/Ficha KYC - Pessoas Singulares.docx">
                  <p className="font-bold">
                    <FileCopyIcon />
                    <span className="mx-2">Ficha de KYC</span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
      <div className="absolute right-0 bottom-0 z-[-1]">
        <KYCBG />
      </div>
    </div>
  );
}

export default KYC;
