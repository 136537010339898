import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./routes/homepage";
import NavBar from "./components/navbar/NavBar";
import ScrollToTop from "./components/general/ScrollTop";
import Footer from "./components/footer/Footer";
import About from "./routes/about";
import Sustain from "./routes/sustain";
import Society from "./routes/society";
import Regulations from "./routes/regulations";
import Entrepreneurs from "./routes/entrepreneurs";
import Investors from "./routes/investors";
import Politics from "./routes/investors/politics";
import Portfolio from "./routes/portfolio";
import CompanyShow from "./routes/portfolio/CompanyShow";
import Contacts from "./routes/contacts";
import CreateProject from "./routes/entrepreneurs/create/project";
import Team from "./routes/team";
import CreateTeaser from "./routes/entrepreneurs/create/teaser";
import Adesao from "./routes/investors/create/adesao";
import Individual from "./routes/investors/create/individual";
import Coletiva from "./routes/investors/create/coletiva";
import PolicyPrivacy from "./routes/privacy";
import Complaint from "./routes/privacy/complaint";
import KYCForm from "./routes/investors/create/kycform";
import KYC from "./routes/investors/create/kyc";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/about/team" element={<Team />} />
        <Route path="/about/sustain" element={<Sustain />} />
        <Route path="/about/society" element={<Society />} />
        <Route path="/about/regulation" element={<Regulations />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/investors/adesao" element={<Adesao />} />
        <Route path="/investors/individual" element={<Individual />} />
        <Route path="/investors/coletiva" element={<Coletiva />} />
        <Route path="/investors/kyc" element={<KYC />} />
        <Route path="/investors/kyc/form" element={<KYCForm />} />
        <Route path="/investors/politics" element={<Politics />} />
        <Route path="/entrepreneurs" element={<Entrepreneurs />} />
        <Route path="/entrepreneurs/create-teaser" element={<CreateTeaser />} />
        <Route
          path="/entrepreneurs/create-project"
          element={<CreateProject />}
        />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path={`/portfolio/show`} element={<CompanyShow />} />
        <Route path={`/contacts`} element={<Contacts />} />
        <Route path={`/privacy-policy`} element={<PolicyPrivacy />} />
        <Route path={`/complaint`} element={<Complaint />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
