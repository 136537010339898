import React from "react";
import InnerHero from "../../components/general/InnerHero";
import Wrapper from "../../components/general/Wrapper";

import HeroBG from "../../assets/investors/investors_hero.png";
import Img1 from "../../assets/investors/investors_1.svg";
import Img2 from "../../assets/investors/investors_2.svg";
import Img3 from "../../assets/investors/investors_3.svg";
import Img4 from "../../assets/investors/investors_4.svg";
import Image1 from "../../assets/investors/investors_main.png";
import { ReactComponent as KYCBG } from "../../assets/investors/kyc_bg.svg";
import { ReactComponent as InvestorsBG } from "../../assets/investors/investors_bg.svg";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link, useNavigate } from "react-router-dom";
import InnerNavigation from "../../components/general/InnerNavigation";
import ContentImg from "../../components/general/ContentImg";

function Investors() {
  const navigate = useNavigate();
  return (
    <>
      <InnerHero
        img={HeroBG}
        header="Invista o seu capital para um futuro sensacional."
      />
      <InnerNavigation
        actualPage={"Para Investidores"}
        innerSections={
          <div className="flex gap-5 text-Gray font-semibold">
            <a href="#general" className="hover:text-Salmon">
              Visão Geral
            </a>
            <a href="#subscribe" className="hover:text-Salmon">
              Inscrição
            </a>
          </div>
        }
        // routerLinks={
        //   <div className="flex gap-5 text-Gray font-semibold">
        //     <Link to={"politics"} className="hover:text-Salmon">
        //       Política de Investimento
        //     </Link>
        //   </div>
        // }
      />
      <Wrapper>
        <div className="py-20">
          <ContentImg
            img={Image1}
            header={
              <h3 className="text-Dark font-bold text-2xl mb-5">
                A VCapital está constantemente à procura de novos Business
                Angels
              </h3>
            }
            content={
              <>
                <p className="text-Gray mt-5 w-full leading-8 text-justify">
                  Os business angels tratam-se de investidores particulares que
                  investem diretamente parte do seu capital em startups com o
                  objetivo de valorizar e fazer crescer as mesmas, contribuindo
                  para o crescimento económico e avanços tecnológicos. <br />
                  Para além de suporte financeiro e gestão empresarial, os
                  business angels também partilham a sua experiência,
                  conhecimento e rede de contactos com os empreendedores, de
                  modo a juntos alcançarem o sucesso.
                  <br />
                  <br />
                  Na VCapital, a admissão de Business Angels é realizada
                  mediante um pedido de adesão/convite e uma posterior aprovação
                  por parte da direção.
                </p>
              </>
            }
          />
        </div>

        {/* <div className="text-center w-full my-20 mb-30">
          <h4 className="text-sm text-Salmon uppercase font-extrabold mb-10">
            Investidores
          </h4>
          <h3 className="text-2xl font-bold text-Dark">
            A VCapital está constantemente à procura de novos Business Angels
          </h3>
          <p className="text-Gray mt-5 w-full md:w-3/4 mx-auto leading-8 text-justify">
            Os business angels tratam-se de investidores particulares que
            investem diretamente parte do seu capital em startups com o objetivo
            de valorizar e fazer crescer as mesmas, contribuindo para o
            crescimento económico e avanços tecnológicos. <br />
            Para além de suporte financeiro e gestão empresarial, os business
            angels também partilham a sua experiência, conhecimento e rede de
            contactos com os empreendedores, de modo a juntos alcançarem o
            sucesso.
            <br />
            <br />
            Na VCapital, a admissão de Business Angels é realizada mediante um
            pedido de adesão/convite e uma posterior aprovação por parte da
            direção.
          </p>
        </div> */}
      </Wrapper>
      <div className="bg-Light py-20" id="general">
        <Wrapper>
          <h3 className="text-4xl font-bold text-darkBlue text-center">
            Como se tornar num Business Angel
          </h3>
          <div className="grid lg:grid-cols-2 xl:grid-cols-4 items-center w-full mx-auto md:items-start gap-10 mt-10">
            <div className="flex flex-col items-center basis-full md:basis-1/4 gap-5">
              <div className="h-[80px] w-[80px]">
                <img
                  src={Img1}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <h4 className="text-darkGreen text-2xl font-bold text-center md:h-[80px]">
                Compreender
              </h4>
              <p className="text-Gray text-center leading-8 md:mt-3 text-lg mx-10 sm:mx-0">
                Compreender o ecossistema das startups e os riscos associados é
                essencial antes de se comprometer a uma, assim como o pode
                ajudar a tomar melhores decisões.
              </p>
            </div>
            <div className="flex flex-col items-center basis-full md:basis-1/4 gap-5">
              <div className="h-[80px] w-[80px]">
                <img
                  src={Img2}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <h4 className="text-darkGreen text-2xl font-bold text-center md:h-[80px]">
                Avaliar os Potenciais Investimentos
              </h4>
              <p className="text-Gray text-center leading-8 md:mt-3 text-lg mx-10 sm:mx-0">
                Qualquer investimento numa startup tem frequentemente um risco
                elevado associado. Por essa razão, os investidores analisam
                minuciosamente o plano de negócios de modo a qualificar o
                retorno final.
              </p>
            </div>
            <div className="flex flex-col items-center basis-full md:basis-1/4 gap-5">
              <div className="h-[80px] w-[80px]">
                <img
                  src={Img3}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <h4 className="text-darkGreen text-2xl font-bold text-center md:h-[80px]">
                Desenvolver uma Estratégia
              </h4>
              <p className="text-Gray text-center leading-8 md:mt-3 text-lg mx-10 sm:mx-0">
                Assuntos como o tipo de investidor e que investimentos envolvem
                os seus interesses devem ser tomados em consideração de modo a
                que o sujeito acrescente valor ao negócio.
              </p>
            </div>
            <div className="flex flex-col items-center basis-full md:basis-1/4 gap-5">
              <div className="h-[80px] w-[80px]">
                <img
                  src={Img4}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <h4 className="text-darkGreen text-2xl font-bold text-center md:h-[80px]">
                Apoiar, Orientar e Acompanhar
              </h4>
              <p className="text-Gray text-center leading-8 md:mt-3 text-lg mx-10 sm:mx-0">
                Estar regularmente a par do seu investimento é a chave para
                avaliar e contribuir para o seu sucesso. Para além de apoio
                financeiro, deve ajudar a tomar decisões comerciais e fornecer
                contactos essenciais na prestação de aconselhamento
                especializado.
              </p>
            </div>
          </div>
        </Wrapper>
      </div>
      <div className="bg-Dark py-20 relative z-[1]" id="subscribe">
        <Wrapper>
          <h4 className="text-sm text-Salmon uppercase font-extrabold mb-5 text-center">
            inscrição
          </h4>
          <h3 className="text-2xl font-bold text-Light text-center leading-8">
            Junte-se a nós a contribuir para uma sociedade
            <br /> mais sustentável e desenvolvida.
          </h3>
          <div className="flex flex-col md:flex-row gap-5 w-full mt-5 md:mt-20">
            <div
              onClick={() => navigate("adesao")}
              className="bg-Light py-8 px-10 flex items-center justify-between basis-full md:basis-[calc(50%_-_10px)] cursor-pointer transition-all duration-300 group/item hover:bg-LightGreen"
            >
              <p className="text-lg text-LightGreen font-bold group-hover/item:text-Light">
                Pedido de Adesão
              </p>
              <div className="flex items-center text-darkGreen group-hover/item:text-Light">
                <p className="font-medium mr-2">Inscrição</p>
                <ArrowForwardIcon />
              </div>
            </div>
            <div
              onClick={() => navigate("kyc")}
              className="bg-Light py-8 px-10 flex items-center justify-between basis-full md:basis-[calc(50%_-_10px)] cursor-pointer transition-all duration-300 group/item hover:bg-LightGreen"
            >
              <p className="text-lg text-LightGreen font-bold group-hover/item:text-Light">
                Ficha KYC
              </p>
              <div className="flex items-center text-darkGreen group-hover/item:text-Light">
                <p className="font-medium mr-2">Ver Mais</p>
                <ArrowForwardIcon />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-5 w-full mt-5 md:mb-10">
            <div
              onClick={() => navigate("individual")}
              className="bg-Light py-8 px-10 flex items-center justify-between basis-full md:basis-1/2 cursor-pointer transition-all duration-300 group/item hover:bg-Salmon"
            >
              <p className="text-lg text-Salmon font-bold group-hover/item:text-Light">
                Investidor Nacional Individual
              </p>
              <div className="flex items-center text-darkGreen group-hover/item:text-Light">
                <p className="font-medium mr-2">Inscrição</p>
                <ArrowForwardIcon />
              </div>
            </div>
            <div
              onClick={() => navigate("coletiva")}
              className="bg-Light py-8 px-10 flex items-center justify-between basis-full md:basis-1/2 cursor-pointer transition-all duration-300 group/item hover:bg-Salmon"
            >
              <p className="text-lg text-Salmon font-bold group-hover/item:text-Light">
                Investidor Nacional Pessoa Coletiva
              </p>
              <div className="flex items-center text-darkGreen group-hover/item:text-Light">
                <p className="font-medium mr-2">Inscrição</p>
                <ArrowForwardIcon />
              </div>
            </div>
          </div>
        </Wrapper>
        <InvestorsBG className="absolute right-1/2 top-20 z-[-1]" />
        <KYCBG className="absolute right-0 bottom-0 z-[-1]" />
      </div>
      <div className="bg-darkGreen w-full py-5">
        <div className="w-11/12 md:w-10/12 mx-auto gap-5 flex flex-col md:flex-row justify-between items-start md:items-center">
          <p className="text-white text-xl">
            <b>Possui alguma dúvida?</b> Estamos cá para o ajudar.
          </p>
          <Link
            to={"/contacts"}
            className="bg-white px-10 py-3 uppercase text-Salmon font-bold rounded text-sm"
          >
            Contactos
          </Link>
        </div>
      </div>
    </>
  );
}

export default Investors;
