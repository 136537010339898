import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import InnerNavigation from "../../components/general/InnerNavigation";
import Wrapper from "../../components/general/Wrapper";

function Society() {
  const Break = styled.div`
    height: 23px;
    border: 1px solid #ebeff2;
    border-radius: 0.25rem;
  `;
  return (
    <div className="mt-[80px]">
      <InnerNavigation
        actualPage={"Identificação da Sociedade"}
        routerLinks={
          <div className="flex gap-2 text-Gray font-semibold">
            <Link to={"/about/regulation"} className="hover:text-LightGreen">
              Regulamentos
            </Link>
            <Break />
            <Link to={"/about"} className="hover:text-LightGreen">
              Quem Somos
            </Link>
            <Break />
            <Link to={"/about/team"} className="hover:text-LightGreen">
              Equipa
            </Link>
            <Break />
            <Link to={"/about/sustain"} className="hover:text-LightGreen">
              Sustentabilidade e Responsabilidade
            </Link>
          </div>
        }
      />
      <div className="mt-10">
        <Wrapper>
          <h3 className="text-Dark font-bold text-2xl md:text-4xl text-center">
            Identificação da Sociedade
          </h3>
          <p className="text-Gray text-lg font-medium text-center mt-5 leading-8">
            A VC - SGPS, Lda., comercialmente denomidada de VCapital, é<br />{" "}
            uma sociedade de gestão de participações sociais.
          </p>
          <div className="flex flex-col md:flex-row gap-5 mt-20">
            <div className="basis-full md:basis-3/5 bg-darkGreen p-5 min-h-[200px]">
              <div className="flex flex-col items-start justify-center h-full gap-5">
                <p className="text-white font-semibold">VC - SGPS, Lda.</p>
                <p className="text-white">
                  NIPC <b>517 267 498</b>
                </p>
                <p className="text-white">
                  Avenida Fontes Pereira de Melo nº16, Palácio Sotto Mayor
                  1050-121, Lisboa
                </p>
              </div>
            </div>
            <div className="basis-full md:basis-2/5 bg-LightGray p-5 min-h-[200px]">
              <div className="flex flex-col items-start justify-center h-full gap-5">
                <div className="flex gap-5 text-Gray">
                  <div className="flex flex-col items-start gap-5">
                    <p>Capital </p>
                    <p>CAE Principal</p>
                  </div>
                  <div className="flex flex-col items-start basis-2/3 gap-5">
                    <p className="font-bold">5.000,00 €</p>
                    <div>
                      <p className="font-bold">64202 </p>
                      <p>
                        Atividades das sociedades gestoras de participações
                        sociais não financeiras
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-Salmon p-5 h-[200px] mt-5 mb-20">
            <div className="flex flex-col items-start justify-center h-full gap-5">
              <p className="text-white font-semibold">Acionistas</p>
              <p className="text-white">
                52%{" "}
                <span className="font-semibold">Pedro Vieira Paixão</span>
              </p>
              <p className="text-white">
                48% <span className="font-semibold">VIPAPAR - SGPS, Lda.</span>
              </p>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
}
export default Society;
