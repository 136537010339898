import React, { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function CustomFileInput({ name, id, className }) {
  const [inputValue, getInputValue] = useState(null);
  console.log(inputValue);
  return (
    <div
      className={`${className} relative flex justify-center  border border-LightGray max-w-[240px] p-3 mt-3  rounded font-bold uppercase text-sm`}
    >
      <input
        type={"file"}
        name={name}
        id={id}
        onChange={(e) => getInputValue(e.target.value)}
        className="opacity-0 absolute left-0 top-0 w-full h-full cursor-pointer"
      />
      <label for={id} id="label" className="flex gap-2 items-center truncate ">
        <FileUploadIcon />

        {inputValue === null || inputValue === " "
          ? "Adicionar Ficheiro"
          : inputValue?.substr(12, 80)}
      </label>
    </div>
  );
}

export default CustomFileInput;
