import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export const CustomTabWrapper = styled(Tabs)`
  & .MuiTabs-flexContainer {
    @media (min-width: 1280px) {
      justify-content: center;
    }
  }

  & .MuiTouchRipple-root {
    display: none;
  }
  & .MuiTabs-indicator {
    display: none;
  }
`;

export const CustomTab = styled(Tab)`
  font-weight: 400 !important;
  text-transform: capitalize !important;
  font-size: 1.125rem !important;
  position: relative;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;

  &::after {
    content: "";
    display: block;
    height: 18px;
    width: 2px;
    background-color: #99cbc7;
    position: absolute;
    right: 0;
  }

  &:nth-child(5) {
    &::after {
      display: none;
    }
  }
  &.Mui-selected {
    color: #99cbc7 !important;
    font-weight: 700 !important;
  }
`;
export const AnimationDiv = styled("div")`
  & #large_fan {
    & #fan {
      -webkit-animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        infinite both;
      animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s
        infinite both;
      transform-origin: 1129px 96px;
    }
  }
  & #small_fan {
    & #fan_2 {
      -webkit-animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        infinite both;
      animation: rotate-center 8 + s cubic-bezier(0.25, 0.46, 0.45, 0.94) 9s
        infinite reverse;

      transform-origin: 1257px 166px;
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
