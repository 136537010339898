import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as WalletBG } from "../../../assets/wallet/wallet_bg.svg";
import CompanyItem from "../../portfolio/components/CompanyItem";

const AnimationDiv = styled("div")`
  & #large_fan {
    & #fan {
      -webkit-animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        infinite both;
      animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s
        infinite both;
      transform-origin: 1129px 96px;
    }
  }
  & #small_fan {
    & #fan_2 {
      -webkit-animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        infinite both;
      animation: rotate-center 8 + s cubic-bezier(0.25, 0.46, 0.45, 0.94) 9s
        infinite reverse;

      transform-origin: 1257px 166px;
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
function WalletSection() {
  return (
    <div className="w-11/12 md:w-10/12 mx-auto relative">
      <div className="relative w-full z-[1] overflow-hidden bg-Light rounded-[10px] p-10">
        <h3 className="text-4xl text-darkGreen font-bold text-center">
          Carteira de Investimentos
        </h3>
        <p className="text-Gray text-lg font-semibold text-center">
          Temos o prazer de contar com a confiança de quem trabalha connosco.
        </p>
        <div className="flex justify-around mt-20 flex-wrap gap-5">
          <div
            className={
              "basis-[calc(100%_/_3_-_20px)] lg:basis-0 flex justify-center"
            }
          >
            <CompanyItem company={"ilhapneu"} />
          </div>
          <div
            className={
              "basis-[calc(100%_/_3_-_20px)] lg:basis-0  flex justify-center"
            }
          >
            <CompanyItem company={"pneuzarco"} />
          </div>
          <div
            className={
              "basis-[calc(100%_/_3_-_20px)] lg:basis-0  flex justify-center"
            }
          >
            <CompanyItem company={"ds"} />
          </div>
          <div
            className={
              "basis-[calc(100%_/_3_-_20px)] lg:basis-0  flex justify-center"
            }
          >
            <CompanyItem company={"corporate"} />
          </div>
          <div
            className={
              "basis-[calc(100%_/_3_-_20px)] lg:basis-0  flex justify-center"
            }
          >
            <CompanyItem company={"gde"} />
          </div>
          <div
            className={
              "basis-[calc(100%_/_3_-_20px)] lg:basis-0  flex justify-center"
            }
          >
            <CompanyItem company={"vipacorp"} />
          </div>

          <div
            className={
              "basis-[calc(100%_/_3_-_20px)] lg:hidden  flex justify-center"
            }
          >
            <CompanyItem company={"vipaconnect"} />
          </div>
          <div
            className={
              "basis-[calc(100%_/_3_-_20px)] lg:hidden  flex justify-center"
            }
          >
            <CompanyItem company={"poder"} />
          </div>
          <div
            className={
              "basis-[calc(100%_/_3_-_20px)] lg:hidden  flex justify-center"
            }
          >
            <CompanyItem company={"hwl"} />
          </div>
        </div>
        <div className="justify-around mt-10 hidden lg:flex">
          <CompanyItem company={"vipaconnect"} />
          <CompanyItem company={"poder"} />
          <CompanyItem company={"hwl"} />
          <CompanyItem company={"seehealth"} />
          <CompanyItem company={"vipatelecom"} />
        </div>
        <div className="flex justify-center items-center">
          <Link
            to={"/portfolio"}
            className="bg-Dark px-10 py-3 uppercase text-white font-bold rounded mt-20"
          >
            Ver Mais
          </Link>
        </div>
        <AnimationDiv className="absolute bottom-0 md:top-0 md:bottom-auto left-0 z-[-1] w-full ">
          <WalletBG className="relative w-full h-full object-contain " />
        </AnimationDiv>
      </div>
    </div>
  );
}

export default WalletSection;
