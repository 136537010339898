import React from "react";

function InnerHero({ img, header }) {
  return (
    <div className="h-[40vh] md:min-h-[60vh] w-full relative mt-[80px] md:mt-0">
      <div className="w-11/12 md:w-10/12 h-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <h2 className="text-2xl md:text-4xl text-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center font-bold w-full md:w-2/3 px-3 md:px-0 mt-0 sm:mt-10">
          {header}
        </h2>
      </div>
      <img
        src={img}
        alt=""
        className="w-full h-full object-cover object-center"
      />
    </div>
  );
}
export default InnerHero;
