import React from "react";
import CustomCheckbox from "../../components/general/CustomCheckbox";
import CustomInput from "../../components/general/CustomInput";
import CustomTextField from "../../components/general/CustomTextField";
import Wrapper from "../../components/general/Wrapper";

function Complaint() {
  return (
    <div className="mt-32">
      <Wrapper>
        <h3 className="text-3xl text-center font-bold text-Salmon">
          Canal de Denúncias
        </h3>
        <form>
          <p className="font-semibold text-DarkGray">
            Pretende fazer uma denúncia anónima?
            <span className="text-[#ED1D24]">*</span>
          </p>
          <CustomCheckbox
            label={"Sim"}
            name="anonymous"
            id="anonymous"
            type={"radio"}
          />
          <CustomCheckbox
            label={"Não"}
            name="anonymous"
            id="anonymous"
            type={"radio"}
          />
          <p className="font-semibold text-DarkGray">Nome Completo</p>
          <CustomInput
            placeholder={"Escreva aqui"}
            type="text"
            name={"nome"}
            id="nome"
          />
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Email<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"O seu email"}
                type="email"
                name={"email"}
                id="email"
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Telemóvel<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"000 000 000"}
                type="number"
                name={"telemovel"}
                id="telemovel"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Assunto da Denúncia<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"Escreva aqui"}
                type="text"
                name={"complaint"}
                id="complaint"
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Qual a sua relação com o Grupo Vipa?
                <span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"Escreva aqui"}
                type="text"
                name={"relation"}
                id="relation"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 md:pr-[10px]">
            <p className="font-semibold text-DarkGray">
              Data da Ocorrência
              <span className="text-[#ED1D24]">*</span>
            </p>
            <CustomInput type="date" name={"date"} id="date" />
          </div>
          <div className="w-full">
            <p className="font-semibold text-DarkGray">
              Descrição da Denúncia
              <span className="text-[#ED1D24]">*</span>
            </p>
            <CustomTextField
              placeholder={"Escreva aqui"}
              name={"desc"}
              id="desc"
            />
          </div>
          <div className="border border-LightGray rounded w-full p-4 mt-10">
            <CustomCheckbox
              squared
              label={
                "Autorizo que esta informação seja partilhada entre os membros do VCAPITAL."
              }
            />
            <CustomCheckbox
              squared
              label={
                "Estou aberto a ser contactado por empresas que vendem serviços de angariação de fundos e incentivos europeus para propor negócios relacionados com o VCAPITAL."
              }
            />
            <CustomCheckbox
              squared
              label={
                "Estou aberto a ser contactado por empresas que vendem serviços de incubação e aceleração de start-ups relacionados com o VCAPITAL."
              }
            />
          </div>
          <div className="justify-center flex my-10">
            <button
              type="submit"
              className="bg-Salmon px-10 py-4 uppercase text-white font-bold rounded text-sm"
            >
              Submeter
            </button>
          </div>
        </form>
      </Wrapper>
    </div>
  );
}

export default Complaint;
