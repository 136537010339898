import React from "react";
import Wrapper from "../../components/general/Wrapper";

import Hero from "../../assets/team/team_hero.png";
import Antonio from "../../assets/team/Antonio.png";
import Andre from "../../assets/team/Andre.png";
import Paulo from "../../assets/team/Paulo.png";
import Pedro from "../../assets/team/Pedro.png";
import Tatiana from "../../assets/team/Tatiana.png";
import Banner from "../../assets/team/team_banner.png";
import BG1 from "../../assets/team/team_bg_1.svg";
import BG2 from "../../assets/team/team_bg_2.svg";
import InnerHero from "../../components/general/InnerHero";
import InnerNavigation from "../../components/general/InnerNavigation";
import { Link } from "react-router-dom";
import TeamCard from "./components/TeamCard";
import CallToAction from "../../components/general/CalltoAction";
// import IAPMEI from "../../assets/partners/iapmei_logo.png";
// import BP from "../../assets/partners/bp_logo.svg";
// import EU from "../../assets/partners/eu_logo.png";
// import GOV from "../../assets/partners/governo_logo.png";
import VSM from "../../assets/partners/vsm.png";
import Enerbellum from "../../assets/partners/Enerbellum.png";
import styled from "styled-components";

function Team() {
  const Break = styled.div`
    height: 23px;
    border: 1px solid #ebeff2;
    border-radius: 0.25rem;
  `;
  return (
    <>
      <InnerHero
        img={Hero}
        header="Temos as nossas diferenças, mas enquanto equipa, encaixamo-nos todos juntos "
      />
      <InnerNavigation
        actualPage={"Equipa"}
        innerSections={
          <div className="flex gap-5 text-Gray font-semibold">
            <a href="#general" className="hover:text-LightGreen">
              Visão Geral
            </a>
            {/* <a href="#partners" className="hover:text-LightGreen">
              Parceiros
            </a> */}
          </div>
        }
        routerLinks={
          <div className="flex gap-2 text-Gray font-semibold">
            <Link to={"/about/sustain"} className="hover:text-LightGreen">
              Sustentabilidade e Responsabilidade
            </Link>
            <Break />
            <Link to={"/about/society"} className="hover:text-LightGreen">
              Identificação da Sociedade
            </Link>
            <Break />
            <Link to={"/about/regulation"} className="hover:text-LightGreen">
              Regulamentos
            </Link>
            <Break />
            <Link to={"/about"} className="hover:text-LightGreen">
              Quem Somos
            </Link>
          </div>
        }
      />
      <div className="relative z-[1]" id="general">
        <img src={BG1} alt="" className="absolute left-0 z-[0] " />
        <img src={BG2} alt="" className="absolute right-0 bottom-20 z-[-1]" />
        <Wrapper>
          <h3 className="text-3xl text-Salmon font-bold text-center mt-10">
            Equipa
          </h3>
          {/* <p className="text-Gray text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            <br />
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p> */}
          <h4 className="text-Dark text-2xl font-bold text-center mt-20">
            BUSINESS ANGELS
          </h4>
          <h3 className="text-2xl text-[#337E84] font-bold text-center mb-10">
            Credenciados
          </h3>
          <TeamCard
            img={Pedro}
            name="Pedro Vieira Paixão"
            linkedin={"https://www.linkedin.com/in/pedrovieirapaixao/"}
            title="ceo & business angel"
            credentials="IAPMEI BA Nº 431/2022"
          />
          <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start gap-10 py-10">
            <div>
              <h4 className="text-Dark text-2xl font-bold text-center mt-10 mb-[50px]">
                EQUIPA DE OPERAÇÕES
              </h4>

              <div className="flex flex-wrap gap-5 justify-center mb-20">
                <TeamCard
                  img={Tatiana}
                  name="Tatiana Gonçalves"
                  title="COO"
                  linkedin={
                    "https://www.linkedin.com/in/tatiana-gon%C3%A7alves-65a892145/"
                  }
                  secondary
                />
                <TeamCard
                  img={Andre}
                  name="André Almeida"
                  title="CE"
                  linkedin={"https://www.linkedin.com/in/andrealmeida01/"}
                  secondary
                />
                <TeamCard
                  img={Antonio}
                  name="António Ramos"
                  title="CMO"
                  linkedin={
                    "https://www.linkedin.com/in/antonio-ramos-913459116/"
                  }
                  secondary
                />
              </div>
            </div>
            <div>
              <h4 className="text-[#1B1B1B] text-2xl font-bold text-center lg:mt-10">
                DEPARTAMENTO DE COMPLIANCE
              </h4>
              <h4 className="text-darkGreen text-xl font-bold text-center mb-5">
                Auditoria Independente
              </h4>
              <TeamCard
                img={Paulo}
                name="Paulo Teles"
                title="TOC"
                subtitle="Nº19127"
                job="Auditor"
                secondary
              />
            </div>
          </div>
        </Wrapper>
      </div>
      <div className="w-full relative min-h-[450px] z-[1]">
        <h3 className="text-white text-center text-3xl font-bold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full">
          Paixão e compromisso constroem uma equipa de confiança.
        </h3>
        <img
          src={Banner}
          alt=""
          className="w-full h-full object-cover absolute top-0 left-0  z-[-1]"
        />
      </div>
      <div className="my-10 flex flex-col gap-10" id="partners">
        <Wrapper>
          <div className="bg-Light rounded-lg py-5  md:px-20">
            <h3 className="text-3xl font-bold text-darkGreen text-center mt-5">
              Parceiros
            </h3>
            <div className="flex flex-wrap justify-center gap-5 sm:gap-20 items-start h-full overflow-x-hidden py-10">
              <a
                href="https://vsmadvisors.com/vsm-investment-studio/"
                target="_blank"
                rel="noreferrer"
                className=" items-center md:max-w-[300px] p-2 flex flex-col gap-3"
              >
                <img
                  src={VSM}
                  alt=""
                  className="w-[250px] h-full object-contain"
                />
                <p className="text-Gray leading-5 text-center">
                  Clube internacional de "investidores-anjo" em startups
                  portuguesas baseado em Lisboa e Madrid.
                </p>
              </a>
              <a
                href="http://enerbellum.pt/"
                target="_blank"
                rel="noreferrer"
                className=" items-center md:max-w-[300px] p-2 flex flex-col gap-3"
              >
                <img
                  src={Enerbellum}
                  alt=""
                  className="w-[250px] h-full object-contain"
                />
                <p className="text-Gray leading-5 text-center">
                  Sociedade de investimento em startups e scaleups, detida e
                  gerida por business-angels credenciados.
                </p>
              </a>
            </div>
          </div>
        </Wrapper>

        <CallToAction />
      </div>
      <div className="bg-darkGreen w-full py-5">
        <div className="w-11/12 md:w-10/12 mx-auto flex flex-col md:flex-row justify-between items-start gap-5 md:items-center">
          <p className="text-white text-xl">
            <b>Possui alguma dúvida?</b> Estamos cá para o ajudar.
          </p>
          <Link
            to={"/contacts"}
            className="bg-white px-10 py-3 uppercase text-Salmon font-bold rounded text-sm"
          >
            Contactos
          </Link>
        </div>
      </div>
    </>
  );
}

export default Team;
