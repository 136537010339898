import CustomInput from "../../../../components/general/CustomInput";
import CustomTextField from "../../../../components/general/CustomTextField";

export const FirstStep = ({ className }) => (
  <div className={className}>
    <h3 className="text-Dark font-bold text-3xl">1. Identificação</h3>
    <p className="font-semibold text-DarkGray">Nome</p>
    <CustomInput
      placeholder={"Escreva aqui"}
      type="text"
      name={"nome"}
      id="nome"
    />
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Email<span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"O seu email"}
          type="email"
          name={"email"}
          id="email"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Telemóvel<span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"000 000 000"}
          type="number"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
    <p className="font-semibold text-DarkGray">Outras Informações Relevantes</p>
    <CustomTextField placeholder={"Escreva aqui"} />
  </div>
);
