import { Stepper, Step } from "@mui/material";
import styled from "styled-components";

export const CustomStepper = styled(Stepper)`
  padding: 40px 0;
  border-bottom: 1px solid #ebeff2;
  margin-bottom: 40px;
  overflow-y: auto;

  & .MuiStepConnector-root {
    left: calc(-60%);
    right: calc(40%);
    z-index: -1;
    @media (min-width: 768px) {
      left: calc(-50% + 12px);
      right: calc(50% + 12px);
    }
    & .MuiStepConnector-line {
      border-color: #ebeff2;
      border-top-width: 2px;
    }
    & .Mui-active {
      & .MuiStepConnector-line {
        border-color: #99cbc7;
      }
    }
  }
  & .MuiStepLabel-root {
    white-space: nowrap;
  }
`;
export const CustomStep = styled(Step)`
  font-weight: 500;
  & .MuiStepLabel-root {
    & .MuiSvgIcon-root {
      color: #f8f9fa;
      &.Mui-completed {
        color: #99cbc7;
      }
    }
    & .Mui-active {
      & .MuiSvgIcon-root {
        color: #99cbc7;
      }
    }
    & .MuiStepIcon-text {
      display: none;
    }
    & .MuiStepLabel-iconContainer {
      position: relative;
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
        background-color: #ebeff2;
      }
      &.Mui-completed {
        &::after {
          display: none;
        }
      }
      &.Mui-active {
        background-color: #f8f9fa;
      }
    }

    & .MuiStepLabel-labelContainer {
      & .MuiStepLabel-label.Mui-active {
        color: #99cbc7;
      }
    }
  }
`;
