import React from "react";
import InnerHero from "../../components/general/InnerHero";
import Wrapper from "../../components/general/Wrapper";
import ContentImg from "../../components/general/ContentImg";

import HeroBG from "../../assets/entrepreneurs/entrepreneur_hero.png";
import Image1 from "../../assets/entrepreneurs/entrepreneur_img_1.png";
import Image2 from "../../assets/entrepreneurs/entrepreneur_img_2.png";
import BG from "../../assets/entrepreneurs/entrepreneur_bg.svg";
import BuildingLeft from "../../assets/sustain/sustain_building_left.svg";
import BuildingRight from "../../assets/sustain/sustain_building_right.svg";
import SubmitItem from "./components/submitItem";
import { useNavigate } from "react-router-dom";
import InnerNavigation from "../../components/general/InnerNavigation";

function Entrepreneurs() {
  const navigate = useNavigate();
  return (
    <>
      <InnerHero
        img={HeroBG}
        header={
          "Procura financiamento e/ou mentoria para tornar o seu projeto em realidade?"
        }
      />
      <InnerNavigation
        actualPage={"Para Empreendedores"}
        innerSections={
          <div className="flex gap-5 text-Gray font-semibold">
            <a href="#general" className="hover:text-Salmon">
              Visão Geral
            </a>
            <a href="#subscribe" className="hover:text-Salmon">
              Inscrição
            </a>
          </div>
        }
      />
      <div className="relative my-20">
        <Wrapper>
          <ContentImg
            img={Image1}
            header={
              <h3 className="text-Dark font-bold text-2xl mb-5 leading-7">
                Deixe os sonhos de parte.
                <br /> Comece a construir o futuro.
              </h3>
            }
            content={
              <>
                <p className="text-Gray text-justify leading-7">
                  A nossa equipa multifuncional possui um vasto conhecimento do
                  mercado e de profissionais que possam avaliar e prosperar o
                  seu projeto, bem como guiá-lo e dar toda a mentoria necessária
                  para que juntos tornemos a sua ideia em realidade.
                </p>
                <p className="text-Gray mt-5">
                  Tem um projeto em mente?{" "}
                  <span
                    className="font-semibold cursor-pointer hover:text-[#99CBC7]"
                    onClick={() => navigate("create-project")}
                  >
                    Submeta a sua inscrição.
                  </span>
                </p>
              </>
            }
          />
        </Wrapper>
      </div>
      <div className="relative py-20 bg-Dark overflow-hidden" id="general">
        <Wrapper>
          <ContentImg
            reverse
            img={Image2}
            header={
              <h3 className="text-Light font-bold text-2xl mb-5">
                Como obter financiamento de um business angel e manter uma boa
                comunicação empresarial
              </h3>
            }
            content={
              <>
                <h4 className="text-LightGray text-justify font-extrabold text-sm uppercase my-8">
                  defina o seu negócio
                  <span className="text-Gray"> (o quê, porquê, como?)</span>
                </h4>
                <h4 className="text-LightGray text-justify font-extrabold text-sm uppercase">
                  Prepare a sua apresentação
                  <span className="text-Gray"> (pitch)</span>
                </h4>
                <p className="text-LightGray mt-3 leading-7 text-justify">
                  Faça uma pesquisa de mercado, concorrentes, potenciais
                  investidores de modo a avaliar o seu negócios e caminhos a
                  tomar.
                </p>
                <h4 className="text-LightGray text-justify text-sm font-extrabold uppercase my-8">
                  Encontre-se com investidores, negocie os seus termos
                </h4>
                <h4 className="text-LightGray text-justify text-sm font-extrabold uppercase">
                  mantenha o<span className="font-normal">(s)</span> seu
                  <span className="font-normal">(s)</span> investidor
                  <span className="font-normal">(es)</span> atualizado
                  <span className="font-normal">(s)</span> e informado
                  <span className="font-normal">(s)</span>
                </h4>
                <p className="text-LightGray mt-3 text-justify leading-7">
                  Para manter uma boa relação entre empreendedor / investidor, é
                  importante haver uma comunicação transparente - quaisquer
                  alterações importantes, dúvidas, necessidades devem ser
                  reportadas.
                </p>
                <h4 className="text-LightGray text-justify font-extrabold uppercase my-8 text-sm">
                  Ouça o feedback e sugestões do seu investidor
                </h4>
                <h4 className="text-LightGray text-justify font-extrabold uppercase text-sm">
                  Seja responsivo
                </h4>
                <p className="text-LightGray mt-3 leading-7 text-justify">
                  Esteja sempre pronto para reagir rapidamente a pessoas ou
                  problemas.
                </p>
              </>
            }
          />
        </Wrapper>
        <img
          src={BG}
          alt=""
          className="absolute right-0 bottom-1/2 translate-y-1/2"
        />
      </div>
      <div className="my-20" id="subscribe">
        <Wrapper>
          <div className="bg-[#D4F0E6] rounded-lg relative overflow-hidden py-20 px-5 mb-10 z-[1]">
            <h4 className="uppercase text-sm text-darkGreen font-extrabold text-center">
              Inscrição
            </h4>
            <h3 className="text-Dark text-2xl font-bold text-center mb-10">
              Queremos dar asas às suas ideias!
            </h3>
            <div className="flex flex-col md:flex-row items-stretch justify-center gap-5">
              <SubmitItem onClick={() => navigate("create-teaser")} />
              <SubmitItem project onClick={() => navigate("create-project")} />
            </div>
            <img
              src={BuildingLeft}
              alt=""
              className="bottom-0 left-0 absolute z-[-1] opacity-50 hidden md:block"
            />
            <img
              src={BuildingRight}
              alt=""
              className="bottom-0 right-0 absolute z-[-1] opacity-50"
            />
          </div>
        </Wrapper>
      </div>
    </>
  );
}

export default Entrepreneurs;
