import React from "react";
import InnerHero from "../../components/general/InnerHero";
import Wrapper from "../../components/general/Wrapper";
import FullWrapper from "../../components/general/FullWrapper";
import ContentImg from "../../components/general/ContentImg";

import HeroBG from "../../assets/sustain/sustain_hero.png";
import Image1 from "../../assets/sustain/sustain_image_1.png";
import Image2 from "../../assets/sustain/sustain_image_2.png";
import Image3 from "../../assets/sustain/sustain_image_3.png";
import Image4 from "../../assets/sustain/sustain_image_4.png";

import InnerNavigation from "../../components/general/InnerNavigation";
import { Link } from "react-router-dom";
import CallToAction from "../../components/general/CalltoAction";
import styled from "styled-components";

function Sustain() {
  const SeedAnimated = () => (
    <svg
      width="171"
      height="164"
      viewBox="0 0 171 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="seedRotate">
        <ellipse
          id="Ellipse 3"
          cx="85.5"
          cy="82.0005"
          rx="85"
          ry="82.0005"
          fill="white"
          fill-opacity="0.5"
        />
        <g id="Frame 1686552866">
          <g id="Group">
            <g id="Group_2">
              <path
                id="Vector"
                d="M78.3085 71.0708C84.5267 77.289 83.3299 88.6067 83.3299 88.6067C83.3299 88.6067 72.0123 89.8295 65.794 83.6113C59.5758 77.3931 60.7986 66.0754 60.7986 66.0754C60.7986 66.0754 72.1163 64.8526 78.3345 71.0968L78.3085 71.0708Z"
                fill="#32988F"
              />
              <path
                id="Vector_2"
                d="M106.017 90.5311C99.7988 96.7493 88.4811 95.5526 88.4811 95.5526C88.4811 95.5526 87.2843 84.2348 93.5025 78.0166C99.7207 71.7984 111.038 73.0213 111.038 73.0213C111.038 73.0213 112.261 84.3389 106.043 90.5572L106.017 90.5311Z"
                fill="#79E6CB"
              />
              <path
                id="Vector_3"
                d="M88.3769 117.097C88.3769 118.476 87.2582 119.569 85.9053 119.569C84.5523 119.569 83.4336 118.45 83.4336 117.097V68.4443C83.4336 67.0654 84.5523 65.9727 85.9053 65.9727C87.2582 65.9727 88.3769 67.0914 88.3769 68.4443V117.097Z"
                fill="#32988F"
              />
            </g>
            <g id="Group_3">
              <path
                id="Vector_4"
                d="M60.7732 66.0449C60.7732 66.0449 59.5504 77.3626 65.7686 83.5808C71.9868 89.799 83.3045 88.5761 83.3045 88.5761L60.7472 66.0449H60.7732Z"
                fill="#79E6CB"
              />
              <path
                id="Vector_5"
                d="M111.013 72.998L88.4561 95.5554C88.4561 95.5554 99.7738 96.7782 105.992 90.5339C112.21 84.3157 110.987 72.998 110.987 72.998H111.013Z"
                fill="#32988F"
              />
              <path
                id="Vector_6"
                d="M83.4346 68.443V117.096C83.4346 118.475 84.5533 119.568 85.9062 119.568V65.9453C84.5533 65.9453 83.4346 67.064 83.4346 68.4169V68.443Z"
                fill="#79E6CB"
              />
            </g>
          </g>
        </g>
        <g id="center">
          <path
            id="Vector_7"
            d="M50.729 84.5859L49.0732 84.643L50.729 84.5859Z"
            fill="white"
            fill-opacity="0.8"
          />
          <g
            id="rotate"
            className="group-hover/item:rotate-180 origin-center transition-all duration-300"
          >
            <path
              id="Union"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M47.6837 44.2255C58.18 34.0711 72.1948 28.4805 87.0671 28.4805H87.1262C101.998 28.4805 115.984 34.0711 126.509 44.2255C136.207 53.5813 141.914 65.875 142.712 79.0244C138.1 79.7375 133.517 80.4506 128.934 81.1637C128.668 70.7525 124.351 61.0259 116.723 53.6668C108.799 46.051 98.273 41.8581 87.0671 41.8581C75.8612 41.8581 65.3648 46.051 57.4704 53.6668C56.3576 54.7404 55.3397 55.8827 54.3743 57.0526L59.841 62.3263L34.876 65.9444L38.578 41.8604L44.4969 47.5574C45.516 46.4096 46.5638 45.3059 47.6837 44.2255Z"
              fill="white"
              fill-opacity="0.8"
            />
            <path
              id="Union_2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M45.8212 85.8398C46.6491 95.1956 50.6997 103.439 57.8845 110.399V110.427C65.7789 118.043 76.3048 122.236 87.5108 122.236C97.4537 122.236 106.875 118.899 114.399 112.787L108.385 106.98L133.35 103.408L129.583 127.492L124.178 122.262C114.016 130.891 101.14 135.585 87.4812 135.585C72.5794 135.585 58.5942 129.995 48.0683 119.84C38.9617 111.083 33.3144 99.5312 32.043 87.209C32.1073 87.2001 32.1744 87.1885 32.2427 87.1767C32.3941 87.1505 32.5508 87.1234 32.6934 87.1234C33.7283 87.0093 34.7631 86.9523 35.5319 86.9238H35.7979L45.8212 85.8398Z"
              fill="white"
              fill-opacity="0.8"
            />
          </g>
        </g>
        <g id="Group_4">
          <path
            id="Vector_8"
            d="M41.2429 85.3027L39.123 85.3757L41.2429 85.3027Z"
            fill="#616E7D"
          />
        </g>
      </g>
    </svg>
  );

  const Break = styled.div`
    height: 23px;
    border: 1px solid #ebeff2;
    border-radius: 0.25rem;
  `;

  return (
    <>
      <InnerHero
        img={HeroBG}
        header={
          "Respeito e inovação são a ponte para a construção de um futuro mais sustentável."
        }
      />
      <InnerNavigation
        actualPage={"Sustentabilidade e Responsabilidade"}
        routerLinks={
          <div className="flex gap-2 text-Gray font-semibold">
            <Link to={"/about/society"} className="hover:text-LightGreen">
              Identificação da Sociedade
            </Link>
            <Break />
            <Link to={"/about/regulation"} className="hover:text-LightGreen">
              Regulamentos
            </Link>
            <Break />
            <Link to={"/about"} className="hover:text-LightGreen">
              Quem Somos
            </Link>
            <Break />
            <Link to={"/about/team"} className="hover:text-LightGreen">
              Equipa
            </Link>
          </div>
        }
      />
      <FullWrapper>
        <div className="bg-[#006970] rounded-none sm:rounded-lg w-full p-8 md:p-10 my-20">
          <ContentImg
            imageReverse
            img={Image1}
            subHeader={
              <h4 className="text-[#D4F0E6] text-sm uppercase font-extrabold mb-5">
                ESTRATÉGIA de Sustentabilidade
              </h4>
            }
            header={
              <h3 className="text-white font-bold text-2xl leading-8">
                Procuramos contribuir para um planeta mais sustentável.
              </h3>
            }
            content={
              <>
                <p className="text-white my-5 text-justify leading-8">
                  A inovação acrescenta valor ao negócio. Porém, o nosso
                  principal foco consiste em apostar no desenvolvimento das
                  capacidades e competências das mais diversas e brilhantes
                  mentes de uma forma sustentável.
                </p>
                <p className="text-white text-justify leading-8">
                  Acreditamos que podemos acelerar o progresso para um planeta
                  sustentável através da potencialização de soluções inovadoras.
                  Para tal, ao analisarmos a sustentabilidade dos nossos
                  potenciais investimentos, temos em consideração os critérios{" "}
                  <span className="font-semibold">ASG</span> (ambientais,
                  sociais e de governo corporativo).
                </p>
              </>
            }
          />
        </div>
      </FullWrapper>
      <Wrapper>
        <h3 className="text-darkGreen text-3xl font-bold text-center mb-10">
          <span className="text-[#337E8480]">Critérios</span> A, S, & G:
        </h3>
        <div className="py-5 md:py-10">
          <div
            className={`group/item flex flex-col-reverse lg:flex-row gap-20 hover:gap-10 transition-all duration-500 md:hover:px-2`}
          >
            <div className="basis-1/2">
              <h3 className="text-Dark text-2xl font-bold transition-all duration-500 md:group-hover/item:text-darkGreen">
                <span className="text-Dark transition-all duration-500 md:group-hover/item:text-darkGreen">
                  Critérios Ambientais
                </span>
              </h3>
              <p className="text-Gray mt-5 text-justify leading-8 mb-10">
                Nos negócios, a VCapital tem sempre em consideração a
                minimização dos factores de risco que intensifiquem a mudança
                climática ou metam em causa os recursos naturais do planeta.
              </p>
              <h4 className="text-LightGreen md:text-Gray transition-all duration-500 md:group-hover/item:text-LightGreen text-sm uppercase font-extrabold">
                Como contribuímos
              </h4>
              <h3 className="text-Dark text-xl font-bold mt-3">
                <li>Objetivos de Desenvolvimento Sustentáveis</li>
              </h3>
              <p className="text-Gray my-5 text-justify px-2 leading-8">
                Assumimos o compromisso de adoptar e integrar nas nossas ações e
                decisões de investimento os
                <b> Objetivos de Desenvolvimento Sustentáveis</b>, definidos
                pelas Nações Unidas.
              </p>
              <p className="text-Gray my-5 text-justify px-2 leading-8">
                Tal compromisso viabiliza a participação de forma positiva na
                sociedade, e com as gerações futuras em mente.
              </p>
            </div>
            <div className="basis-1/2 relative flex items-start justify-center md:grayscale md:group-hover/item:grayscale-0">
              <div className="absolute top-1/4 sm:top-1/3">
                <SeedAnimated />
              </div>
              <img src={Image2} alt="" className="h-full" />
            </div>
          </div>
        </div>
        <div className="py-10">
          <div
            className={`group/item flex flex-col-reverse lg:flex-row-reverse gap-20 hover:gap-10 transition-all duration-500 md:hover:px-2`}
          >
            <div className="basis-1/2">
              <h3 className="text-darkGreen md:text-Dark text-2xl font-bold transition-all duration-500 md:group-hover/item:text-darkGreen">
                Critérios Sociais
              </h3>
              <p className="text-Gray mb-5 text-justify leading-8">
                Os nossos investimentos são sempre examinados tendo em vista o
                impacto que os mesmos poderão causar na sociedade, tanto para
                com os funcionários, clientes e comunidades em que se encontrem
                presentes.
              </p>
              <h4 className="text-LightGreen md:text-Gray transition-all duration-500 group-hover/item:text-LightGreen text-sm uppercase font-extrabold">
                a nossa responsabilidade
              </h4>
              <h3 className="text-Dark text-xl font-bold mt-3">
                <li>Direitos Humanos, Diversidade e Inclusão</li>
              </h3>
              <p className="text-Gray my-2 text-justify px-2 leading-8">
                O respeito e valorização da dignidade, diversidade e direitos
                das nossas pessoas e da comunidade é fundamental para a
                construção de um ambiente inclusivo, onde através de diferentes
                visões com objetivos comuns conseguimos agregar valor às
                relações de entreajuda entre as nossas equipas e resultados
                apresentados.
              </p>
              <h3 className="text-Dark text-xl font-bold">
                <li>Condições de Trabalho dos Funcionários</li>
              </h3>
              <p className="text-Gray my-2 text-justify px-2 leading-8">
                Dedicamo-nos a capacitar os nossos colaboradores, dando um
                acompanhamento e orientação necessários para uma maior
                eficiência e um alcançar de metas cada vez mais ambiciosas.
              </p>
              {/* <h3 className="text-Dark text-xl font-bold">
                <li>Igualdade de Oportunidades</li>
              </h3> */}
              {/* <p className="text-Gray my-2 text-justify px-2 leading-8">
                Temos o orgulho de ...
                <br />
                <b>50%</b> Homens
                <br />
                <b>50%</b> Mulheres
                <br />
              </p> */}
            </div>
            <div className="basis-1/2 flex items-start justify-center md:grayscale group-hover/item:grayscale-0">
              <img src={Image3} alt="" />
            </div>
          </div>
        </div>
        <div className="py-10">
          <div
            className={`group/item flex flex-col-reverse lg:flex-row gap-20 hover:gap-10 transition-all duration-500 md:hover:px-2`}
          >
            <div className="basis-1/2 flex flex-col justify-center">
              <h3 className="text-darkGreen md:text-Dark text-2xl font-bold transition-all duration-500 group-hover/item:text-darkGreen mb-3">
                Critérios de Governo Corporativo
              </h3>
              <p className="text-Gray mb-5 text-justify leading-8">
                Uma boa administração e direção são fundamentais para que
                qualquer negócio se torne verdadeiramente sustentável. A nossa
                responsabilidade, lealdade, transparência, ambição e rigor
                refletem o nosso sucesso.
              </p>
            </div>
            <div className="basis-1/2 flex items-start justify-center md:grayscale group-hover/item:grayscale-0">
              <img src={Image4} alt="" />
            </div>
          </div>
        </div>
      </Wrapper>
      <CallToAction />
      <div className="bg-darkGreen w-full py-5">
        <div className="w-11/12 md:w-10/12 mx-auto flex flex-col gap-5 md:flex-row justify-between  items-start md:items-center">
          <p className="text-white text-xl">
            <b>Possui alguma dúvida?</b> Estamos cá para o ajudar.
          </p>
          <Link
            to={"/contacts"}
            className="bg-white px-10 py-3 uppercase text-Salmon font-bold rounded"
          >
            Contactos
          </Link>
        </div>
      </div>
    </>
  );
}

export default Sustain;
