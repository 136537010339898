import CustomCheckbox from "../../../../components/general/CustomCheckbox";
import CustomInput from "../../../../components/general/CustomInput";

export const SeventhStep = ({ className }) => (
  <div className={className}>
    <h3 className="text-Dark font-bold text-3xl">7. Governação</h3>
    <p className="text-Gray mb-3">
      Descreva as suas ideias sobre a forma como a empresa será gerida e o papel
      dos acionistas.
    </p>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Que decisões empresariais são tomadas pelos gestores?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"text"}
          id="text"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Que decisões empresariais são tomadas pelos accionistas?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Existem limitações ao direito de voto?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"text"}
          id="text"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Existem restrições à venda de ações?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">A empresa terá auditores?</p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"text"}
          id="text"
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Existem outras questões de governação empresarial relevantes?
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          name={"telemovel"}
          id="telemovel"
        />
      </div>
    </div>
    <div className="flex flex-col">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Como é que a empresa se comunicará aos seus investidores?
          <span className="text-[#ED1D24]">*</span>
        </p>
        <table className="w-full table-auto border-separate border-spacing-0 border border-LightGray">
          <tr className="bg-Light text-Gray font-semibold leading-10 text-center border border-LightGray">
            <th></th>
            <th>Mensal</th>
            <th>Trimestral</th>
            <th>Anual</th>
            <th>Por Pedido</th>
            <th>Nunca</th>
          </tr>
          <tr className="leading-10 text-center">
            <td className="text-left text-Gray font-semibold px-3 border border-LightGray bg-Light">
              Divulgação dos KPIs Financeiros
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="kpi financeiro" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="kpi financeiro" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="kpi financeiro" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="kpi financeiro" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="kpi financeiro" align />
            </td>
          </tr>
          <tr className="leading-10 text-center">
            <td className="text-left text-Gray font-semibold px-3 border border-LightGray bg-Light">
              Divulgação dos KPIs Não Financeiros
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="kpi nao financeiro" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="kpi nao financeiro" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="kpi nao financeiro" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="kpi nao financeiro" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="kpi nao financeiro" align />
            </td>
          </tr>
          <tr className="leading-10 text-center">
            <td className="text-left text-Gray font-semibold px-3 border border-LightGray bg-Light">
              Relatório Escrito da Direção
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="relatorio" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="relatorio" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="relatorio" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="relatorio" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="relatorio" align />
            </td>
          </tr>
          <tr className="leading-10 text-center">
            <td className="text-left text-Gray font-semibold px-3 border border-LightGray bg-Light">
              Reuniões com Acionistas
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="acionistas" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="acionistas" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="acionistas" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="acionistas" align />
            </td>
            <td className="border border-LightGray">
              <CustomCheckbox type={"radio"} name="acionistas" align />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
);
