import React from "react";
import TestimonialsBG from "../../../assets/testimonials/testimonials_bg.png";
import TopIcon from "../../../assets/testimonials/aspas.svg";

function Testimonials() {
  return (
    <div className="w-11/12 md:w-10/12 mx-auto h-full">
      <div className="flex items-center min-h-[300px] relative rounded-[10px] overflow-hidden my-10 z-[1]">
        <div className="w-full h-full p-10">
          <div className="flex flex-col w-full md:w-4/6 mx-auto items-center justify-center h-full gap-10">
            <div>
              <img src={TopIcon} alt="" />
            </div>
            <p className="text-[#EBEFF2] text-lg text-center">
              Temos orgulho no passado, mas queremos reforçar a confiança no
              futuro.
            </p>
            <div>
              <h4 className="text-[#EBEFF2] text-xl font-semibold text-center">
                Pedro Vieira Paixão
              </h4>
              <p className="text-LightGreen text-sm uppercase font-bold text-center leading-8">
                CEO & Business Angel
              </p>
            </div>
            {/* <div className="w-20 h-20 rounded-full overflow-hidden">
              <img src={Pic} alt="" className="object-cover w-full h-full" />
            </div> */}
          </div>
        </div>
        <img
          src={TestimonialsBG}
          alt=""
          className="absolute top-0 w-full h-full object-cover z-[-1]"
        />
      </div>
    </div>
  );
}

export default Testimonials;
