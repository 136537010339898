import React from "react";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../../components/general/CustomCheckbox";
import CustomInput from "../../../components/general/CustomInput";
import CustomTextField from "../../../components/general/CustomTextField";
import Wrapper from "../../../components/general/Wrapper";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

function Adesao() {
  const navigate = useNavigate();
  return (
    <>
      <div className=" bg-Light mt-20 border-b border-b-[#E2E8F0]">
        <Wrapper>
          <div
            className="flex items-center gap-2 py-5 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ArrowBackRoundedIcon />
            <span className="text-Gray uppercase text-sm font-bold">
              Retroceder
            </span>
          </div>
          <div className="py-10">
            <h3 className="text-center text-3xl font-bold text-darkGreen mb-5">
              Pedido de Adesão
            </h3>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto">
              O VCapital é uma sociedade veículo de investidores em startups.
            </p>
            <p className="text-Gray text-center w-full md:w-2/3 mx-auto my-5">
              O VCapital é gerido pela VIPAPAR - SGPS, Lda ( vipacorporation.com
              ) de uma forma totalmente transparente. A VCapital pode cobrar
              taxas nas operações.
            </p>
          </div>
        </Wrapper>
      </div>
      <Wrapper>
        <form className="mt-10">
          <h3 className="text-Dark font-bold text-3xl">1. Identificação</h3>
          <p className="font-semibold text-DarkGray">
            Nome<span className="text-[#ED1D24]">*</span>
          </p>
          <CustomInput
            placeholder={"Escreva aqui"}
            type="text"
            name={"nome"}
            id="nome"
          />
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Email<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"O seu email"}
                type="email"
                name={"email"}
                id="email"
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray">Localização</p>
              <CustomInput
                placeholder={"Escreva aqui"}
                type="text"
                name={"local"}
                id="local"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 md:pr-[10px]">
            <p className="font-semibold text-DarkGray">
              Telefone de Contacto<span className="text-[#ED1D24]">*</span>
            </p>
            <CustomInput
              placeholder={"000 000 000"}
              type="number"
              name={"tel"}
              id="tel"
            />
          </div>

          <h3 className="text-Dark font-bold text-3xl">
            2. Perfil de Investimento
          </h3>
          <p className="font-semibold text-DarkGray">
            Está interessado em analisar oportunidades de investimento em
            Startups?
          </p>
          <CustomCheckbox label={"Sim"} />
          <CustomCheckbox label={"Talvez - dependendo dos projetos"} />
          <CustomCheckbox label={"Não"} />
          <CustomCheckbox label={"Outra"} />

          <h3 className="text-Dark font-bold text-3xl">
            3. Sectores de Preferência
          </h3>
          <p className="text-Gray">
            No caso de estar disposto a investir capital ou tempo em troca de
            uma percentagem de uma startup.
          </p>
          <p className="font-semibold text-DarkGray">
            Selecione uma ou mais áreas nas quais esteja interessado em aprender
            sobre projetos de investimento (no caso das suas preferências não
            serem claramente indicadas abaixo, por favor indique-nos):
          </p>
          <CustomCheckbox
            label={
              "Tecnologias de Informação (TI, Software, Blockchain, outros)"
            }
          />
          <CustomCheckbox
            label={
              "Saúde e Bem-estar (Saúde, Farmácia, Biotecnologia, Desporto, Alimentação, outros)"
            }
          />
          <CustomCheckbox
            label={"Energia e Recursos (Energia, Água, Eficiência, outros)"}
          />
          <CustomCheckbox
            label={"Finanças (Fintech, Finanças Descentralizadas, outros)"}
          />
          <CustomCheckbox
            label={"Foodtech (Agricultura, Alimentos e Bebidas, outros)"}
          />
          <CustomCheckbox
            label={"Criatividade (Media, Conteúdos, Artes, outros)"}
          />
          <CustomCheckbox
            label={"Turismo e Lazer (Hotelaria, Restaurante, Eventos, outros)"}
          />
          <CustomCheckbox label={"Bens Industriais"} />
          <CustomCheckbox label={"Serviços (Educação, Aprendizagem, outros)"} />
          <CustomCheckbox label={"Outra"} />
          <h3 className="text-Dark font-bold text-3xl">
            4. Temas de Preferência
          </h3>
          <p className="text-Gray">
            No caso de ter temas de preferência específicos.
          </p>
          <p className="font-semibold text-DarkGray">
            Selecione um ou mais temas nos quais esteja interessado em aprender
            sobre projetos de investimento (caso as suas preferências não
            estejam claramente indicadas abaixo, por favor informe-nos):
            <span className="text-[#ED1D24]">*</span>
          </p>
          <CustomCheckbox label={"Investimento de Impacto"} />
          <CustomCheckbox label={"Transformação Digital"} />
          <CustomCheckbox label={"Ação Climática"} />
          <CustomCheckbox label={"Inovação Social"} />
          <CustomCheckbox label={"Tendências do Consumidor"} />
          <CustomCheckbox label={"Internacionalização"} />
          <CustomCheckbox label={"Outra"} />
          <h3 className="text-Dark font-bold text-3xl">
            5. Perfil Profissional
          </h3>

          <p className="font-semibold text-DarkGray">
            Está interessado e disposto a partilhar tempo e experiência com os
            afiliados e a desempenhar um papel ativo?
            <span className="text-[#ED1D24]">*</span>
          </p>
          <CustomCheckbox label={"Sim"} />
          <CustomCheckbox label={"Não"} />
          <CustomCheckbox label={"Talvez"} />
          <p className="font-semibold text-DarkGray">
            Identifique as suas áreas de especialização e mentoria
            <span className="text-[#ED1D24]">*</span>
          </p>
          <CustomCheckbox label={"Gestão"} />
          <CustomCheckbox label={"Distribuição / Logística"} />
          <CustomCheckbox label={"Estratégia Empresarial"} />
          <CustomCheckbox label={"Exportação"} />
          <CustomCheckbox label={"Finanças"} />
          <CustomCheckbox label={"Recursos Humanos"} />
          <CustomCheckbox label={"Consultoria"} />
          <CustomCheckbox label={"Marketing"} />
          <CustomCheckbox label={"Comercial / Vendas"} />
          <CustomCheckbox label={"Aptidões Técnicas"} />
          <CustomCheckbox label={"Legislação"} />
          <CustomCheckbox label={"Produção"} />
          <CustomCheckbox label={"Outra"} />
          <h3 className="text-Dark font-bold text-3xl">
            6. Observações Finais
          </h3>
          <p className="font-semibold text-DarkGray">
            Como ficou a saber da VCapital?
          </p>
          <CustomTextField placeholder={"Escreva aqui"} />
          <p className="font-semibold text-DarkGray">
            Alguma outra questão relevante que não tenha sido abordada neste
            formulário?
          </p>
          <CustomTextField placeholder={"Escreva aqui"} />
          <div className="border border-LightGray rounded w-full p-4 my-10">
            <CustomCheckbox
              squared
              label={
                "Aceito que a VCapital trate os meus dados com o objetivo de divulgar informações sobre o VCapital ou eventos organizados por ele ou pelos seus parceiros."
              }
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-darkGreen  px-10 py-4 uppercase text-white font-bold rounded text-sm mb-10"
            >
              Submeter
            </button>
          </div>
        </form>
      </Wrapper>
    </>
  );
}

export default Adesao;
