import React, { useState } from "react";
import InnerHero from "../../components/general/InnerHero";
import Wrapper from "../../components/general/Wrapper";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "./styles/timeline.css";

import HeroBG from "../../assets/portfolio/portfolio_hero.png";
import { AnimationDiv } from "./styles/index_styles";
import CompanyItem from "./components/CompanyItem";
import TimelineItem from "./components/TimelineItem";
import { ReactComponent as WalletBG } from "../../assets/wallet/wallet_bg.svg";
import { Link, useNavigate } from "react-router-dom";

function Portfolio() {
  const navigate = useNavigate();
  const [value] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        className="mt-10 "
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }
  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     "aria-controls": `simple-tabpanel-${index}`,
  //   };
  // }
  return (
    <>
      <InnerHero
        img={HeroBG}
        header={"Temos o prazer de fazer ideias crescer."}
      />
      <Wrapper>
        <div className="bg-Light p-10 my-20 rounded-lg relative z-[1] overflow-hidden">
          <AnimationDiv className="absolute bottom-0 md:top-0 md:bottom-auto left-0 z-[-1] w-full ">
            <WalletBG className="relative w-full h-full object-contain " />
          </AnimationDiv>
          <h3 className="text-darkGreen font-bold text-center text-4xl">
            Carteira de Investimentos
          </h3>
          <h4 className="text-3xl text-LightGreen font-bold text-center">
            3.795.188,75€
          </h4>
          <p className="text-Gray text-center">Montante Investido</p>
          <div className="bg-transparent h-[0px] w-full py-5" />
          {/* <CustomTabWrapper
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <CustomTab label="Todos" {...a11yProps(0)} />
            <CustomTab label="Tecnologia Digital" {...a11yProps(1)} />
            <CustomTab label="Tecnologia Industrial" {...a11yProps(2)} />
            <CustomTab label="Tecnologias da Saúde" {...a11yProps(3)} />
            <CustomTab label="Energias Renováveis" {...a11yProps(4)} />
          </CustomTabWrapper> */}
          <TabPanel value={value} index={0}>
            <div className="flex flex-wrap 2xl:flex-nowrap justify-center gap-10 mb-5">
              <CompanyItem
                company={"ilhapneu"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=ilhapneu")}
              />
              <CompanyItem
                company={"pneuzarco"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=pneuzarco")}
              />
              <CompanyItem
                company={"ds"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=ds")}
              />
              <CompanyItem
                company={"corporate"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=corporate")}
              />
              <CompanyItem
                company={"gde"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=gde")}
              />
              <CompanyItem
                company={"vipacorp"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=vipacorp")}
              />
            </div>
            <div className="flex flex-wrap 2xl:flex-nowrap justify-center gap-10  mb-5">
              <CompanyItem
                company={"vipaconnect"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=vipaconnect")}
              />
              <CompanyItem
                company={"poder"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=poder")}
              />
              <CompanyItem
                company={"hwl"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=hwl")}
              />
              <CompanyItem
                company={"seehealth"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=seehealth")}
              />
              <CompanyItem
                company={"vipaenergy"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=vipaenergy")}
              />
            </div>
            <div className="flex flex-wrap 2xl:flex-nowrap justify-center gap-10  mb-5">
              <CompanyItem
                company={"nmds"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=nmds")}
              />
              <CompanyItem
                company={"ps"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=ps")}
              />
              <CompanyItem
                company={"anthony"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=anthony")}
              />
              <CompanyItem
                company={"biomask"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=biomask")}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="flex flex-wrap 2xl:flex-nowrap justify-center gap-5 lg:gap-10 mb-5">
              <CompanyItem
                company={"vipaconnect"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=vipaconnect")}
              />
              <CompanyItem
                company={"poder"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=poder")}
              />
              <CompanyItem
                company={"seehealth"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=seehealth")}
              />
              <CompanyItem
                company={"biomask"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=biomask")}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="flex flex-wrap 2xl:flex-nowrap justify-center gap-5 lg:gap-10 mb-5">
              <CompanyItem
                company={"ilhapneu"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=ilhapneu")}
              />
              <CompanyItem
                company={"pneuzarco"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=pneuzarco")}
              />
              <CompanyItem
                company={"ps"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=ps")}
              />
              <CompanyItem
                company={"vipaenergy"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=vipaenergy")}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className="flex flex-wrap 2xl:flex-nowrap justify-center gap-5 lg:gap-10 mb-5">
              <CompanyItem
                company={"seehealth"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=seehealth")}
              />
              <CompanyItem
                company={"biomask"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=biomask")}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <div className="flex flex-wrap 2xl:flex-nowrap justify-center gap-5 lg:gap-10 mb-5">
              <CompanyItem
                company={"vipaenergy"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=vipaenergy")}
              />
              <CompanyItem
                company={"pneuzarco"}
                className={"cursor-pointer"}
                onClick={() => navigate("/portfolio/show?company=pneuzarco")}
              />
            </div>
          </TabPanel>
        </div>
      </Wrapper>
      <div className="bg-Dark py-10">
        <Wrapper>
          <h3 className="text-LightGreen font-bold text-center text-3xl">
            Área de Track-Record
          </h3>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element"
              contentStyle={{ background: "transparent", color: "#fff" }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              date={
                <span>
                  <span className="text-[#99CBC7]">2009</span> - 2017
                </span>
              }
              iconStyle={{ background: "#99CBC7", color: "#fff" }}
              icon={""}
            >
              <div className="flex flex-col items-center gap-5">
                <TimelineItem company={"ilhapneu"} />
                <div className="text-center">
                  <p className="text-Light font-medium text-lg">
                    Despertafama, Lda.
                  </p>
                  <p className="text-Gray mt-0">( Ilha Pneu )</p>
                </div>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element"
              contentStyle={{ background: "transparent", color: "#fff" }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              date={
                <span>
                  <span className="text-[#99CBC7]">2015</span> - 2017
                </span>
              }
              iconStyle={{ background: "#99CBC7", color: "#fff" }}
              icon={""}
            >
              <div className="flex flex-col items-center gap-5">
                <TimelineItem company={"pneuzarco"} />
                <div className="text-center">
                  <p className="text-Light font-medium text-lg">
                    Futurimbatível, Lda.
                  </p>
                  <p className="text-Gray mt-0 ">( Pneuzarco )</p>
                </div>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element"
              contentStyle={{ background: "transparent", color: "#fff" }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              date={
                <span>
                  <span className="text-[#99CBC7]">2017</span> - 2018
                </span>
              }
              iconStyle={{ background: "#99CBC7", color: "#fff" }}
              icon={""}
            >
              <div className="flex flex-col items-center gap-5">
                <TimelineItem company={"ds"} />
                <div className="text-center">
                  <p className="text-Light font-medium text-lg">
                    Dear Smile, Lda.
                  </p>
                </div>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element"
              contentStyle={{ background: "transparent", color: "#fff" }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              date={
                <span>
                  <span className="text-[#99CBC7]">2018</span> - 2019
                </span>
              }
              iconStyle={{ background: "#99CBC7", color: "#fff" }}
              icon={""}
            >
              <div className="flex flex-col items-center gap-5">
                <TimelineItem company={"corporate"} />
                <div className="text-center">
                  <p className="text-Light font-medium text-lg">
                    PeopleCorp, Lda.
                  </p>
                </div>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element"
              contentStyle={{ background: "transparent", color: "#fff" }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              date={<span className="text-[#99CBC7]">2019 - Presente</span>}
              iconStyle={{ background: "#99CBC7", color: "#fff" }}
              icon={""}
            >
              <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-10">
                <div className="flex flex-col items-center gap-5">
                  <TimelineItem company={"poder"} />
                  <div className="text-center">
                    <p className="text-Light font-medium text-lg">
                      Poder & Negócios, Lda.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center gap-5">
                  <TimelineItem company={"vipaconnect"} />
                  <div className="text-center">
                    <p className="text-Light font-medium text-lg">VGPC, Lda.</p>
                    <p className="text-Gray mt-0 ">( Vipa Connect )</p>
                  </div>
                </div>
                <div className="flex flex-col items-center gap-5">
                  <TimelineItem company={"vipacorp"} />
                  <div className="text-center">
                    <p className="text-Light font-medium text-lg">
                      VIPAPAR SGPS, Lda.
                    </p>
                    <p className="text-Gray mt-0 ">( Vipa )</p>
                  </div>
                </div>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element"
              contentStyle={{
                background: "transparent",
                color: "#fff",
              }}
              contentArrowStyle={{
                borderRight: "7px solid transparent",
              }}
              date={<span className="text-[#99CBC7]">2020 - Presente</span>}
              iconStyle={{ background: "#99CBC7", color: "#fff" }}
              icon={""}
            >
              <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-10">
                <div className="flex flex-col items-center gap-5">
                  <TimelineItem company={"hwl"} />
                  <div className="text-center">
                    <p className="text-Light font-medium text-lg">
                      VP - HWL, Lda.
                    </p>
                    <p className="text-Gray mt-0 ">( Homes With Love )</p>
                  </div>
                </div>
                <div className="flex flex-col items-center gap-5">
                  <TimelineItem company={"seehealth"} />
                  <div className="text-center">
                    <p className="text-Light font-medium text-lg">
                      SH - SeeHealth, Lda.
                    </p>
                    <p className="text-Gray mt-0 ">( See Health )</p>
                  </div>
                </div>
                <div className="flex flex-col items-center gap-5">
                  <TimelineItem company={"vipaenergy"} />
                  <div className="text-center">
                    <p className="text-Light font-medium text-lg">
                      VE - ERM, Lda.
                    </p>
                    <p className="text-Gray mt-0 ">( Vipa Energy )</p>
                  </div>
                </div>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element"
              contentStyle={{ background: "transparent", color: "#fff" }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              date={<span className="text-[#99CBC7]">2021 - Presente</span>}
              iconStyle={{ background: "#99CBC7", color: "#fff" }}
              icon={""}
            >
              <>
                <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-10">
                  <div className="flex flex-col items-center gap-5 basis-1/2">
                    <TimelineItem company={"ps"} />
                    <div className="text-center">
                      <p className="text-Light font-medium text-lg">
                        Pesquisa Subtil, Lda.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-5 basis-1/2">
                    <TimelineItem company={"nmds"} />
                    <div className="text-center">
                      <p className="text-Light font-medium text-lg">
                        Números Distintos, Lda.
                      </p>
                      <p className="text-Gray mt-0 text-lg">( NMDS )</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-10 mt-5">
                  <div className="flex flex-col items-center gap-5 basis-1/2">
                    <TimelineItem company={"biomask"} />
                    <div className="text-center">
                      <p className="text-Light font-medium text-lg">
                        The BioMask Company, Lda.
                      </p>
                      <p className="text-Gray mt-0 ">( Vipa Connect )</p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-5 basis-1/2">
                    <TimelineItem company={"anthony"} />
                    <div className="text-center">
                      <p className="text-Light font-medium text-lg">
                        St. Anthony, Lda.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </Wrapper>
      </div>
      <div className="bg-LightGray w-full py-5">
        <div className="w-11/12 md:w-10/12 mx-auto flex flex-col md:flex-row justify-between items-start md:items-center">
          <p className="text-[#337E84] font-bold text-xl mb-5 md:mb-0">
            Continuamente a trabalhar para um futuro sustentável através da
            potencialização de soluções inovadoras.
          </p>
          <Link
            to={"/about"}
            className="bg-white w-3/10 text-center px-5 py-3 uppercase text-LightGreen font-bold rounded"
          >
            Sobre nós
          </Link>
        </div>
      </div>
    </>
  );
}

export default Portfolio;
