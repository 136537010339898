import React from "react";
import { Link, useNavigate } from "react-router-dom";
import IMG1 from "../../../assets/stats/lastsection_img1.png";
import IMG2 from "../../../assets/stats/lastsection_img2.png";
import StatsBG from "../../../assets/stats/stats_bg.svg";

function LastSection() {
  const navigate = useNavigate();
  return (
    <>
      <div className="min-h-[330px] w-full flex items-center bg-[#EBEFF2] relative overflow-hidden z-[1] mt-20 pb-10">
        <h3 className="text-darkGreen text-center text-2xl md:text-3xl font-bold w-10/12 mx-auto">
          Potencializamos soluções inovadoras, levando sempre em consideração os
          critérios ambientais, sociais e de governação empresarial (ASG) nas
          nossas decisões de investimento.
        </h3>
        <img
          src={StatsBG}
          alt=""
          className="absolute left-0 top-0 z-[-1] w-full"
        />
      </div>
      <div className="bg-LightGray py-10">
        <div className="w-11/12 md:w-10/12 mx-auto relative">
          <div className="flex flex-col-reverse lg:flex-row gap-10 justify-start text-left">
            <div className="basis-full md:basis-1/2">
              <p className="text-darkGreen uppercase font-extrabold text-sm">
                Sustentabilidade e Responsabilidade
              </p>
              <h4 className="text-Dark font-bold text-2xl leading-8 mt-3">
                Acreditamos que o respeito e inovação são a ponte para a
                construção de um futuro mais sustentável.
              </h4>
              <p className="text-Gray mt-5 leading-8 text-lg">
                Temos o compromisso de contribuir para um planeta mais
                sustentável. As nossas decisões de investimento visam sempre uma
                participação ativa e positiva na sociedade, potencializando
                soluções inovadoras sem comprometer as gerações futuras.
              </p>
              <button
                onClick={() => navigate("about/sustain")}
                className="bg-darkGreen text-sm px-8 py-3 uppercase text-white font-bold rounded mt-10"
              >
                Saiba Mais
              </button>
            </div>
            <div className="flex gap-5 ">
              <div className="rounded">
                <img src={IMG1} alt="" />
              </div>
              <div className="rounded">
                <img src={IMG2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-full min-h-[200px]">
        <div className="flex flex-wrap justify-center gap-20 items-center h-full overflow-x-hidden py-10">
          <div className="w-[150px] h-[100px]  flex-shrink-0 p-2">
            <img src={IAPMEI} alt="" className="w-full h-full object-contain" />
          </div>
          <div className="w-[150px] h-[100px]  flex-shrink-0 p-2">
            <img src={BP} alt="" className="w-full h-full object-contain" />
          </div>
          <div className="w-[150px] h-[100px] flex-shrink-0 p-2">
            <img src={EU} alt="" className="w-full h-full object-contain" />
          </div>
          <div className="w-[150px] h-[100px]  flex-shrink-0 p-2">
            <img src={GOV} alt="" className="w-full h-full object-contain" />
          </div>
        </div>
      </div> */}
      <div className="bg-darkGreen w-full py-5">
        <div className="w-11/12 md:w-10/12 mx-auto flex flex-col md:flex-row justify-between items-start gap-5 md:items-center">
          <p className="text-white text-xl">
            <b>Possui alguma dúvida?</b> Estamos cá para o ajudar.
          </p>
          <Link
            to={"/contacts"}
            className="bg-white px-10 py-3 uppercase text-Salmon font-bold rounded text-sm"
          >
            Contactos
          </Link>
        </div>
      </div>
    </>
  );
}

export default LastSection;
