import CustomFileInput from "../../../../components/general/CustomFileInput";

export const NinthStep = ({ className }) => (
  <div className={className}>
    <h3 className="text-Dark font-bold text-3xl">9. Documentos</h3>
    <p className="text-Gray mb-3">
      Carregue os documentos relevantes para a sua proposta.
    </p>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Apresentação (PDF) <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomFileInput
          type="text"
          name={"text"}
          id="text"
          className={"text-LightGreen"}
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Documentos Oficiais da Empresa
        </p>
        <CustomFileInput
          type="text"
          name={"telemovel"}
          id="telemovel"
          className={"text-LightGreen"}
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">Breve Vídeo do Projeto</p>
        <CustomFileInput
          type="text"
          name={"text"}
          id="text"
          className={"text-LightGreen"}
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Material de Marketing (caso exista)
        </p>
        <CustomFileInput
          type="text"
          name={"telemovel"}
          id="telemovel"
          className={"text-LightGreen"}
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">Estimativa Financeira</p>
        <CustomFileInput
          type="text"
          name={"text"}
          id="text"
          className={"text-LightGreen"}
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Outros Documentos Pertinentes
        </p>
        <CustomFileInput
          type="text"
          name={"telemovel"}
          id="telemovel"
          className={"text-LightGreen"}
        />
      </div>
    </div>
  </div>
);
