import React, { useState } from "react";
import styled from "styled-components";
import Wrapper from "./Wrapper";
import { ReactComponent as Bag } from "../../assets/hero/bag.svg";
import { ReactComponent as Ship } from "../../assets/hero/ship.svg";
import BuildingLeft from "../../assets/sustain/sustain_building_left.svg";
import BuildingRight from "../../assets/sustain/sustain_building_right.svg";
import { useNavigate } from "react-router-dom";

const IconAnimator = styled("div")`
  & #bag {
    & #bag_open {
      opacity: ${(props) => (props.open ? 1 : 0)};
      transition: all 0.3s ease-in-out;
    }
  }
  & #ship {
    & #trace {
      opacity: ${(props) => (props.open ? 1 : 0)};
      transition: all 0.3s ease-in-out;
      translate: -5px 10px;
    }
    & #ship_normal {
      transform: ${(props) => (props.open ? "rotate(32deg)" : "rotate(0deg)")};
      transform-origin: center;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const CornerArrow = styled("div")`
  clip-path: polygon(0% 0%, 100% 99%, 100% 0%);
  background-color: #989ea4;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
`;
function CallToAction() {
  const navigate = useNavigate();
  const [bagopen, setBagOpen] = useState(false);
  const [shiptilt, setShipTilt] = useState(false);
  return (
    <Wrapper>
      <div className="bg-[#D4F0E6] rounded-lg relative overflow-hidden py-10 md:py-20 px-5 mb-10 z-[1]">
        <div className="flex flex-col lg:flex-row gap-5 items-center w-full justify-center">
          <div
            className="bg-white border border-[#EBEFF2] px-5 min-h-[200px] max-h-[200px] min-w-full md:min-w-[400px] flex items-center relative group/item cursor-pointer shadow-[0px_2px_25px_rgba(9,51,66,0.08)]"
            onMouseEnter={() => setBagOpen(true)}
            onMouseLeave={() => setBagOpen(false)}
            onClick={() => navigate("/investors")}
          >
            <div className="absolute top-5 right-5">
              <CornerArrow className="group-hover/item:bg-darkGreen group-hover/item:scale-150" />
            </div>
            <IconAnimator className="flex gap-5 items-center " open={bagopen}>
              <Bag />
              <div className="flex flex-col items-start">
                <p className="font-semibold text-Gray text-xl group-hover/item:text-darkGreen">
                  Investidor
                </p>
                <p className="text-Gray leading-8">
                  Está à procura de um projeto para
                  <br /> impulsionar? <b>Descubra mais.</b>
                </p>
              </div>
            </IconAnimator>
          </div>
          <div
            className="bg-white border border-[#EBEFF2] px-5 min-h-[200px] max-h-[200px] min-w-full md:min-w-[400px] flex items-center relative group/item cursor-pointer shadow-[0px_2px_25px_rgba(9,51,66,0.08)]"
            onMouseEnter={() => setShipTilt(true)}
            onMouseLeave={() => setShipTilt(false)}
            onClick={() => navigate("/entrepreneurs")}
          >
            <div className="absolute top-5 right-5">
              <CornerArrow className="group-hover/item:bg-Salmon group-hover/item:scale-150" />
            </div>
            <IconAnimator className="flex gap-5 items-center " open={shiptilt}>
              <Ship />
              <div className="flex flex-col items-start">
                <p className="font-semibold text-Gray text-xl group-hover/item:text-Salmon">
                  Empreendedor
                </p>
                <p className="text-Gray leading-8">
                  Precisa de apoio para tornar o seu
                  <br /> projeto em realidade? <b>Descubra mais.</b>
                </p>
              </div>
            </IconAnimator>
          </div>
        </div>
        <img
          src={BuildingLeft}
          alt=""
          className="bottom-0 left-0 absolute z-[-1] opacity-50 hidden md:block"
        />
        <img
          src={BuildingRight}
          alt=""
          className="bottom-0 right-0 absolute z-[-1] opacity-50"
        />
      </div>
    </Wrapper>
  );
}

export default CallToAction;
