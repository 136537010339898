import React from "react";

function ContentImg({
  img,
  reverse,
  subHeader,
  header,
  content,
  imageReverse,
}) {
  return (
    <div>
      <div
        className={`flex  ${
          reverse
            ? "flex-col-reverse lg:flex-row-reverse"
            : "flex-col lg:flex-row"
        }  ${
           imageReverse
             ? "flex-col-reverse lg:flex-row"
             : "flex-col lg:flex-row"
         } gap-10 items-center `}
      >
        <div className="basis-1/2">
          {subHeader}
          {header}
          {content}
        </div>
        <div className="basis-1/2 flex items-center justify-center">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ContentImg;
