import CustomInput from "../../../../components/general/CustomInput";

export const FourthStep = ({ className }) => (
  <div className={className}>
    <h3 className="text-Dark font-bold text-3xl">4. O Mercado</h3>
    <p className="text-Gray mb-3">
      Ajude-nos a entender o seu plano de negócios.
    </p>
    <div className="flex flex-col md:flex-row items-center md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Que problema resolverá o seu projeto?
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Empresa"
          name={"Empresa"}
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Qual o seu tipo de clientes?
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Morada"
          name={"Morada"}
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row items-center md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Onde estão os seus clientes?
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="corporativo"
          name={"corporativo"}
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Porque acha que terá (mais) clientes?
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Capital"
          name={"Capital"}
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row items-center md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Como é que o seu projeto irá crescer?
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Gestor"
          name={"Gestor"}
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Quem são os seus concorrentes diretos?
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Atividade"
          name={"Atividade"}
        />
      </div>
    </div>
    <div className="flex flex-col">
      <div className="w-full md:w-1/2 md:pr-[10px]">
        <p className="font-semibold text-DarkGray">
          Quem são os seus concorrentes diretos?
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Atividade"
          name={"Atividade"}
        />
      </div>
    </div>
  </div>
);
