import React from "react";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../../components/general/CustomCheckbox";
import CustomInput from "../../../components/general/CustomInput";
import Wrapper from "../../../components/general/Wrapper";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CustomFileInput from "../../../components/general/CustomFileInput";

function Coletiva() {
  const navigate = useNavigate();
  return (
    <>
      <div className=" bg-Light mt-20 border-b border-b-[#E2E8F0]">
        <Wrapper>
          <div
            className="flex items-center gap-2 py-5 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ArrowBackRoundedIcon />
            <span className="text-Gray uppercase text-sm font-bold">
              Retroceder
            </span>
          </div>
          <div className="py-10">
            <h3 className="text-center text-3xl font-bold text-Salmon mb-5">
              Inscrição • Investidor Nacional •
              <span className="text-Dark"> Coletiva</span>
            </h3>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto">
              Formulário de identificação a ser preenchido por pessoas coletivas
              de direito português que invistam nos veículos da VCapital.
            </p>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto my-5">
              Esta informação é reservada e utilizada internamente para efeitos
              de identificação dos detentores dos títulos representativos do
              investimento com procedimentos de KYC (Know Your Client) e AML
              (Anti Money Laundering), e destinam-se apenas ao cumprimento de
              contrato celebrado ou a celebrar entre a Sociedade e os
              investidores, podendo estes dados ser entregues aos Serviços
              Públicos e à autoridade judiciária por força de disposição legal.
              Nos termos da lei, o signatário pode solicitar, à Sociedade, o
              acesso ou rectificação dos seus dados pessoais.
            </p>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto my-5">
              1. O signatário autoriza a conservação dos presentes dados e o seu
              tratamento para fins oficiais
            </p>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto my-5">
              2. O signatário fica com o direito de, em qualquer momento, exigir
              a retirada de todo e qualquer dado constante da sua ficha, a sua
              alteração, e a sua não utilização.
            </p>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto my-5">
              O tratamento da informação respeitará o Regulamento Geral de
              Proteção de Dados.
            </p>
          </div>
        </Wrapper>
      </div>
      <Wrapper>
        <form className="mt-10">
          <h3 className="text-Dark font-bold text-3xl">1. Identificação</h3>
          <p className="font-semibold text-DarkGray">
            Designação Social<span className="text-[#ED1D24]">*</span>
          </p>
          <CustomInput
            placeholder={"Escreva aqui"}
            type="text"
            name={"nome"}
            id="nome"
          />
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Número de Pessoa Coletiva
                <span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                type="number"
                name={"email"}
                id="email"
                placeholder={"000 000 000"}
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Domicílio Social<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"Escreva aqui"}
                type="text"
                name={"social"}
                id="social"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Código de Certidão Permanente
              </p>
              <CustomInput
                type="number"
                name={"email"}
                id="email"
                placeholder={"000 000 000"}
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Código do Registo Central de Beneficiário Efectivo
              </p>
              <CustomInput
                placeholder={"Escreva aqui"}
                type="text"
                name={"social"}
                id="social"
              />
            </div>
          </div>

          <h3 className="text-Dark font-bold text-3xl">
            2. Identificação dos Contactos da Sociedade
          </h3>
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Telefone de Contacto<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"000 000 000"}
                type="number"
                name={"phone"}
                id="phone"
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Website (caso exista)
              </p>
              <CustomInput
                placeholder={"www.exemplo.com"}
                type="text"
                name={"website"}
                id="website"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Telefone de Contacto<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"000 000 000"}
                type="number"
                name={"contacto"}
                id="contacto"
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Endereço completo de residência permanente
                <span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"Escreva aqui"}
                type="text"
                name={"residencia"}
                id="residencia"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Email<span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                placeholder={"O seu email"}
                type="text"
                name={"email"}
                id="email"
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Autorizo a utilização do email para notificações oficiais?
              </p>
              <CustomCheckbox label={"Sim"} />
              <CustomCheckbox label={"Não"} />
            </div>
          </div>

          <h3 className="text-Dark font-bold text-3xl">
            3. Informação Fiscal e de Origem dos Fundos
          </h3>
          <p className="text-Gray">
            Os gerentes deverão preencher a informação destinada a pessoas
            físicas.
          </p>
          <p className="font-semibold text-DarkGray">
            Identificação dos Administradores / Gerentes (nome completo e número
            de identificação fiscal)<span className="text-[#ED1D24]">*</span>
          </p>
          <div className="w-full flex flex-col md:flex-row md:gap-5 items-center">
            <div className="flex items-center w-full gap-2">
              <p className="text-Gray whitespace-nowrap">Nome Completo</p>
              <CustomInput
                placeholder={"Escreva aqui"}
                type="text"
                name={"contacto"}
                id="contacto"
              />
            </div>
            <div className="flex items-center w-full gap-2">
              <p className="text-Gray">NIF</p>
              <CustomInput
                placeholder={"000 000 000"}
                type="number"
                name={"contacto"}
                id="contacto"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 md:pr-[10px]">
            <p className="font-semibold text-DarkGray">
              Telefone de Contacto<span className="text-[#ED1D24]">*</span>
            </p>
            <CustomInput
              placeholder={"000 000 000"}
              type="number"
              name={"contacto"}
              id="contacto"
            />
          </div>
          <p className="font-semibold text-DarkGray">
            Endereço completo de residência fiscal (se diferente da morada de
            residência)
          </p>
          <div className="w-full">
            <CustomInput
              placeholder={"Escreva aqui"}
              type="text"
              name={"contacto"}
              id="contacto"
            />
          </div>

          <h3 className="text-Dark font-bold text-3xl">
            4. Informação de Beneficiários Efetivos
          </h3>
          <p className="text-Gray mb-5">
            Os Beneficiários Efectivos não Administradores ou Gerentes deverão
            preencher o questionário para pessoas físicas
          </p>
          <p className="font-semibold text-DarkGray">
            Identificação dos Beneficiários Efectivos (nome completo e número de
            identificação fiscal)
          </p>
          <CustomInput
            placeholder={"Escreva aqui"}
            type="text"
            name={"contacto"}
            id="contacto"
          />
          <h3 className="text-Dark font-bold text-3xl">5. Documentos</h3>
          <div className="flex flex-col md:flex-row md:gap-5">
            <div className="w-full">
              <p className="font-semibold text-DarkGray">
                Comprovativo de IBAN de origem dos fundos a transferir (PDF)
              </p>
              <CustomFileInput className={"text-Salmon"} />
            </div>
          </div>
          <div className="border border-LightGray rounded w-full p-4 my-10">
            <CustomCheckbox
              squared
              label={
                "Autorizo a utilização da informação por parte da VCapital para cumprimento de requisitos legais no enquadramento com a legislação em vigor."
              }
            />
            <CustomCheckbox
              squared
              label={
                "Autorizo a utilização do endereço de email fornecido para comunicações oficiais por parte da VCapital."
              }
            />
            <CustomCheckbox
              squared
              label={
                "Autorizo o envio para todos os investidores da lista de acionistas/sócios da sociedade."
              }
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-Salmon  px-10 py-4 uppercase text-white font-bold rounded text-sm mb-10"
            >
              Submeter
            </button>
          </div>
        </form>
      </Wrapper>
    </>
  );
}

export default Coletiva;
