import { AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from "react";
import Wrapper from "../../components/general/Wrapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CustomAccordion } from "./styles/index_styles";
import { Link, useSearchParams } from "react-router-dom";
import Fans from "../../assets/privacy-policy/privacy_fans.svg";

function PolicyPrivacy() {
  const [value, setValue] = useState(0);
  const [searchParams] = useSearchParams();
  function Sections(props) {
    const { tabValue, children } = props;
    return (
      <div className={tabValue === value ? "block" : "hidden"}>{children}</div>
    );
  }
  useEffect(() => {
    if (searchParams.get("tab") === "0") {
      handleChange(0);
    } else if (searchParams.get("tab") === "1") {
      handleChange(1);
    }
  }, [searchParams]);
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <div className="mt-32 bg-white pb-20 relative z-[1]">
      <Wrapper>
        <div className="flex flex-col md:flex-row gap-5">
          <div className="flex flex-col basis-1/4 text-Gray gap-5">
            <p
              onClick={() => handleChange(0)}
              className={`font-bold uppercase cursor-pointer ${
                value === 0 && "text-darkGreen"
              }`}
            >
              Política de Privacidade
            </p>
            <p
              onClick={() => handleChange(1)}
              className={`font-bold uppercase cursor-pointer ${
                value === 1 && "text-darkGreen"
              }`}
            >
              Termos de Serviço
            </p>
            <a
              href={"https://www.livroreclamacoes.pt/Inicio/"}
              className="font-bold uppercase"
              target="_blank"
              rel="noreferrer"
            >
              Elogios, Sugestões e Reclamações
            </a>
            <Link to={"/complaint"} className="font-bold uppercase">
              Canal de Denúncias
            </Link>
          </div>
          <div className="flex flex-col basis-3/4">
            <Sections tabValue={0}>
              <h3 className="text-2xl md:text-3xl font-bold text-darkGreen">
                Política de Privacidade
              </h3>
              <p className="text-sm text-LightGreen uppercase font-extrabold mb-10">
                Última atualização a 2 de Fevereiro de 2023
              </p>
              <div className="flex flex-col gap-5">
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Estrutura</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Neste documento, a VC - SGPS, Lda., doravante designada
                      por VCapital, site ou empresa, descreve o armazenamento e
                      fins dos dados que pode escolher fornecer durante o uso
                      deste website (www.vcapital.com) .
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>
                      Declaração de compromisso com a privacidade e proteção de
                      dados pessoais
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      A VCapital, enquanto organização que lida diariamente com
                      dados pessoais, considera-se comprometida com as matérias
                      reguladas e as obrigações impostas pela legislação
                      europeia sobre proteção de dados constituída,
                      nomeadamente, pelo Regulamento (EU) 2016/679 do Parlamento
                      e do Conselho (relativo à proteção das pessoas singulares
                      no que diz respeito ao tratamento de dados pessoais e à
                      livre circulação desses dados) doravante designado RGPD,
                      assim como pela Lei 58/2019, de 8 de agosto que assegura a
                      execução do referido regulamento no contexto português.
                    </p>
                    <li className="mt-3">
                      O responsável pelo tratamento de dados
                    </li>
                    <p>
                      A entidade responsável pela recolha e tratamento dos dados
                      pessoais é a VC - SGPS, Lda., com o número de
                      identificação de pessoa coletiva 515 381 632, com
                      instalações na Avenida Fontes Pereira de Melo nº16,
                      Palácio Sotto Mayor 1050-121, Lisboa, Telefone: +351 291
                      600 300, que no seu contexto, decide quais os dados
                      recolhidos, os meios de tratamento, o período de
                      conservação e as finalidades para que são utilizados.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Aviso Legal</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      O conteúdo deste site (incluindo, mas não se limitando a,
                      texto, imagens, componentes de áudio e vídeo, doravante
                      CONTEÚDOS) é protegido pela Lei de Propriedade
                      Intelectual. A distribuição, modificação, transmissão,
                      cópia ou qualquer uso do conteúdo do site para fins
                      públicos ou comerciais sem a permissão da empresa é uma
                      violação da lei aplicável. É-lhe atribuída também uma
                      licença gratuita, pessoal e não exclusiva para usar,
                      copiar, imprimir e fazer download de qualquer material ou
                      documento disponível no site para o seu computador
                      pessoal.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Resolução Alternativa de disputa</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      A resolução alternativa de litígios é a possibilidade de
                      todos os consumidores recorrerem a entidades oficiais para
                      os auxiliar na resolução ou direcionamento de qualquer
                      conflito antes de iniciarem o litígio nos tribunais. Regra
                      geral, o procedimento é o seguinte: O cliente pede a um
                      terceiro imparcial que atue como intermediário entre ele e
                      o comerciante submetido à reclamação. O intermediário pode
                      sugerir uma solução para a sua reclamação, impor uma
                      solução a ambas as partes ou reunir as partes para
                      encontrar uma solução. Pode conhecer o conceito de
                      resolução alternativa de disputas por outro nome:
                      'mediação', 'conciliação', 'arbitragem' ou 'comissão
                      responsável por disputas de consumo'. A resolução
                      alternativa de disputas é geralmente menos dispendiosa,
                      menos formal e mais rápida do que a via judicial.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Responsabilidade pelo conteúdo e atualização do site</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      A empresa isenta-se de qualquer responsabilidade
                      decorrente do uso indevido do conteúdo e reserva-se o
                      direito de atualizar, modificar ou excluir as informações
                      contidas no site e de limitar ou não permitir o acesso a
                      essas informações em tempo oportuno ou definitivo. As
                      informações contidas nestas páginas da web são atuais na
                      data da sua última atualização e devem ser consideradas
                      como informações orientadas ao utilizador sobre produtos e
                      serviços e outras informações adicionais.
                    </p>
                    <p>
                      A empresa não se responsabiliza por quaisquer
                      discrepâncias que possam surgir entre a versão dos seus
                      documentos impressos e a versão eletrónica dos mesmos
                      publicada no site. Os conteúdos, atividades comerciais,
                      produtos e serviços incluídos no site da empresa e
                      quaisquer relacionados ou vinculados. Simultaneamente, são
                      dirigidos a consumidores residentes em Portugal, pelo que
                      em qualquer caso é aplicável a Legislação Portuguesa e os
                      Tribunais Portugueses competentes.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Política de privacidade</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Os dados fornecidos são confidenciais e serão incorporados
                      num arquivo automatizado da empresa pelo qual a empresa é
                      responsável. Os candidatos autorizam a empresa a
                      transferir os dados para as empresas do seu grupo, a fim
                      de cumprir com diligência o seu pedido. O requerente tem o
                      direito de aceder, retificar e cancelar estes dados, quer
                      por via eletrónica, quer por pedido de correio eletrónico
                      através do endereço a que se referem os dados eletrónicos.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <h3 className="text-2xl md:text-3xl font-bold text-darkGreen">
                  Política de Cookies
                </h3>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Definição e função dos cookies</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Um cookie é um arquivo que é descarregado para o seu
                      computador quando acede a certas páginas da web. Os
                      cookies permitem que uma página da web, entre outras
                      coisas, armazene e recupere informações sobre os hábitos
                      de navegação do utilizador ou do seu dispositivo e,
                      dependendo das informações que eles contêm e como usam o
                      seu dispositivo, podem permitir que eles reconheçam o
                      utilizador. Podem também recolher, por exemplo, o endereço
                      IP que o computador utiliza para aceder ao site, o URL da
                      página Web solicitada, o tempo de ligação e outros
                      parâmetros relacionados com o sistema operativo do
                      contexto informático utilizado. Os cookies de sessão são
                      armazenados na memória do navegador do utilizador, embora
                      as informações neles contidas não sejam utilizadas.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Que tipo de cookies usa o website?</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Cookies técnicos: incluem cookies de desempenho que ajudam
                      a melhorar a qualidade do nosso serviço e cookies de
                      funcionalidade que permitem armazenar as preferências do
                      utilizador.
                    </p>
                    <p>
                      Cookies estatísticos: Recolhem informaçõessobre a data da
                      visita, o URL e o título da página visitada. Osrelatórios
                      gerados com essas informações serão puramenteestatísticos,
                      fornecendo informações sobre o comportamento denavegação
                      anonimamente.
                    </p>
                    <p>
                      Cookies analíticos: são usados para saber dequal mecanismo
                      de pesquisa o utilizador visitou o site e quaisforam os
                      termos de pesquisa usados para encontrá-lo. Eles
                      tambémcalculam o tempo gasto na web em cada sessão e o
                      número de vezesque visitou a página.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Tipos de cookies por duração</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Os cookies usados no site podem ter a seguinte duração:
                    </p>
                    <p>
                      Cookies de sessão: São temporários, permanecem nos cookies
                      do seu navegador de internet (browser) até sair do site. A
                      informação obtida permite identificar problemas e fornecer
                      uma melhor experiência de navegação.
                    </p>
                    <p>
                      Cookies Permanentes: são instalados permanentemente no seu
                      navegador e são ativados cada vez que visita o site, desde
                      que não desative seu uso.
                    </p>
                    <p>
                      Serviço: Relacionado a algum serviço personalizado dentro
                      do site, com prazo de vencimento inferiora 24 horas.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Cookies de Terceiros</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Ao utilizar o site, alguns cookies não são definidos pela
                      empresa, mas sim por terceiros. As empresas que geram
                      esses cookies têm as suas próprias políticas de
                      privacidade. Para obter mais informações sobre os cookies
                      que esses terceiros definem, visite os seguintes links:
                    </p>
                    <p>Para o Google</p>
                    <p>Para o Youtube</p>
                    <p>Para o Facebook</p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Ativar, Bloquear e Excluir cookies</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Você pode ativar, bloquear ou excluir cookies instalados
                      no seu dispositivo, definindo as opções do navegador
                      instaladas no seu computador. Se bloquear a instalação de
                      cookies no seu navegador, poderá não conseguir aceder a
                      algumas das seções da nossa web. A gestão de cookies é
                      feita através do seu navegador. Para definir cookies -
                      permitir, bloquear ou excluir - deve consultar os
                      seguintes links dos principais navegadores e dispositivos:
                    </p>
                    <p>Para o Internet Explorer ™</p>
                    <p>Para o Safari ™</p>
                    <p>Para o Chrome ™</p>
                    <p>Para o Firefox ™</p>
                    <p>Para o Opera ™</p>
                    <p>Para o Android ™</p>
                    <p>Para o Windows Phone ™</p>
                    <p>Para o Blackberry ™</p>
                    <p>Para o Safari e iOS (iPhone e iPad) ™</p>
                    <p>Para o Chrome no Android ™</p>
                    <p>Para o Firefox no Android ™</p>
                    <p>
                      Para definir cookies de terceiros, uma vez que não são
                      colocados pela sua empresa, deve consultar os respectivos
                      sites de terceiros (identificados acima) para saber como
                      pode identificá-los, activá-los, bloqueá-los ou
                      eliminá-los.
                    </p>
                    <p>
                      Se deseja obter mais informações sobre o uso de cookies,
                      solicite-a por e-mail.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
              </div>
            </Sections>
            <Sections tabValue={1}>
              <h3 className="text-2xl md:text-3xl font-bold text-darkGreen ">
                Termos de Serviço
              </h3>
              <p className="text-sm text-LightGreen uppercase font-extrabold mb-10">
                Última atualização a 3 de Janeiro de 2022
              </p>
              <div className="flex flex-col gap-5">
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Copyright e propriedade industrial</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Todos os dados, marcas e conteúdos gerais deste site são
                      propriedade ou uso exclusivo da VCapital e das suas
                      subsidiárias que são protegidas pela legislação geral e
                      pela legislação nacional e internacional de proteção à
                      propriedade intelectual. A reprodução para uso pessoal é
                      permitida, mas modificações não autorizadas, imitações,
                      empréstimos, locações, transmissões ou vendas de qualquer
                      conteúdo deste site são proibidas.
                    </p>
                    <p>
                      Todos os direitos não concedidos expressamente pela
                      VCapital são direitos reservados. Assim, todos os textos,
                      imagens, ilustrações, fotografias, publicidade, marcas
                      registradas e outros elementos do conteúdo do site são
                      protegidos por lei e qualquer cópia, reprodução,
                      disseminação ou transmissão, uso, modificação, venda,
                      publicação, distribuição é expressamente proibida, ou
                      qualquer outro uso, no todo ou em parte, por qualquer meio
                      usado. As excepções a esta proibição são as utilizações
                      gratuitas autorizadas por lei, nomeadamente o direito de
                      citação, desde que claramente identificada a sua origem.
                    </p>
                    <p>
                      É concedida permissão para usar documentos (como
                      comunicados de imprensa, anúncios, relatórios e contas)
                      deste site, desde que o aviso de direitos autorais apareça
                      em todas as cópias. A utilização destes documentos deve
                      ser apenas para fins informativos, e não para fins
                      comerciais ou pessoais, e não podem ser copiados ou
                      colocados em qualquer rede de computador ou transmitidos
                      em qualquer outro meio de comunicação. Nenhuma modificação
                      nestes documentos foi ainda autorizada. Os infratores
                      estarão sujeitos a processo judicial.
                    </p>
                    <p>
                      Embora os dados pessoais que nos forneça estejam dentro do
                      campo de aplicação da nossa política de proteção de dados,
                      entendemos que todas as informações que nos comunicar
                      através da Internet (incluindo sugestões, materiais ou
                      ideias) serão da nossa propriedade e uso exclusivo se bem
                      restrito. Essas informações não serão tratadas por nós
                      como confidenciais.
                    </p>
                    <p>
                      Qualquer software disponível para download no site da
                      VCapital também está sujeito às leis de proteção de
                      propriedade intelectual aplicáveis. Os termos de uso deste
                      software são definidos pela operadora que o disponibiliza,
                      portanto, recomendamos sua leitura.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Hiperlinks</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Qualquer software disponível para download no site da
                      VCapital também está sujeito às leis de proteção de
                      propriedade intelectual aplicáveis. Os termos de uso deste
                      software são definidos pela operadora que o disponibiliza,
                      portanto, recomendamos sua leitura
                    </p>
                    <p>
                      A VCapital não se responsabiliza pelo conteúdo ou tema de
                      qualquer outro portal, incluindo qualquer site que tenha
                      dado acesso ao seu portal ou que tenha sido acedido por
                      meio dele.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Uso e Risco</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Nem a VCapital nem qualquer outra parte envolvida na
                      criação, produção, manutenção ou implementação do site
                      será responsável por quaisquer danos ou danos decorrentes
                      da responsabilidade (incluindo, mas não se limitando a,
                      danos consequenciais, lucros cessantes e danos morais
                      causados diretamente ou indiretamente), decorrentes do uso
                      correto ou incorreto do site e do seu conteúdo pelo
                      utilizador, acesso ao computador e sistema de computador
                      do utilizador por terceiros, vírus, etc.
                    </p>
                    <p>
                      Embora estejamos empenhados na segurança do site e
                      implementamos as medidas de protecção técnica, física e
                      lógica adequadas para garantir a sua protecção, a VCapital
                      não garante ou representa que os arquivos disponíveis para
                      download no site estão livres de vírus, worms, cavalos de
                      Tróia, scripts ou qualquer outro código ou programa que
                      contenha propriedades destrutivas ou prejudiciais.
                    </p>
                    <p>
                      As informações do site devem ser visualizadas para fins
                      informativos. Apesar dos esforços da VCapital para manter
                      o conteúdo atualizado e confiável, este pode conter
                      imprecisões, erros tipográficos ou conteúdo desatualizado,
                      que pode ser alterado a qualquer momento sem aviso prévio
                      da VCapital. Portanto, o seu uso nas tomas de decisão não
                      é recomendado.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Política de Ação</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      É expressamente proibido utilizar o site para fins ilegais
                      ou quaisquer outros que possam ser considerados indignos
                      da imagem da VCapital ou das suas subsidiárias no mercado.
                    </p>
                    <p>
                      Roubo, falsificação, exploração de conteúdo usurpado ou
                      falsificado, identificação ilegítima e concorrência
                      desleal são puníveis criminalmente.
                    </p>
                    <p>
                      Está também proibido de criar ou introduzir neste site
                      qualquer tipo de vírus, worms, cavalos de Tróia, scripts
                      ou qualquer outro código ou programa que contenha
                      propriedades destrutivas ou prejudiciais, ou aconselhar
                      terceiros a fazê-lo.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
                <CustomAccordion elevation={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>Lei aplicável e Tribunal competente</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Estes Termos de Uso são regidos e interpretados de acordo
                      com a lei portuguesa. O Tribunal de Lisboa tem competência
                      para a exclusão de qualquer outro para resolver quaisquer
                      litígios decorrentes da interpretação e aplicação destes
                      Termos e Condições para o uso do site da VCapital.
                    </p>
                  </AccordionDetails>
                </CustomAccordion>
              </div>
            </Sections>
          </div>
        </div>
      </Wrapper>
      <img src={Fans} alt="" className="absolute left-0 bottom-0 z-[-1]" />
    </div>
  );
}
export default PolicyPrivacy;
