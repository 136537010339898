import React from "react";
import styled from "styled-components";
import Wrapper from "../../components/general/Wrapper";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import BG1 from "../../assets/contacts/contact_bg_1.svg";
import BG2 from "../../assets/contacts/contact_bg_2.svg";

const CornerArrow = styled("div")`
  clip-path: polygon(0% 0%, 100% 99%, 100% 0%);
  background-color: #989ea4;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
`;

function Contacts() {
  return (
    <div className="bg-Light relative z-[1]">
      <Wrapper>
        <h3 className="text-LightGreen font-bold text-3xl text-center pt-40">
          Contacte-nos
        </h3>
        <div className="flex flex-col md:flex-row gap-5 justify-center mt-20 ">
          <div
            className="bg-white cursor-pointer p-5 relative shadow-[0px_2px_25px_rgba(9,51,66,0.08)] basis-full md:basis-1/3 group/item"
            onClick={() => window.open("https://goo.gl/maps/ghAYC1wPoAi7NysD9")}
          >
            <h4 className="text-Dark font-bold text-xl">Morada</h4>
            <p className="text-Gray mt-5">
              Avenida Fontes Pereira de Melo nº16,
              <br /> Palácio Sotto Mayor <br />
              1050-121, Lisboa
            </p>
            <div className="absolute top-5 right-5 w-[50px] h-[30px] flex items-end group-hover/item:text-darkGreen text-Gray">
              Mapa
              <CornerArrow className="group-hover/item:bg-darkGreen group-hover/item:scale-150" />
            </div>
          </div>
          <div className="bg-white p-5 relative shadow-[0px_2px_25px_rgba(9,51,66,0.08)] basis-full md:basis-1/3">
            <h4 className="text-Dark font-bold text-xl">Contactos</h4>
            <p className="text-Gray mt-5">
              Email:{" "}
              <a href="mailto:info@vcapital.pt" rel="noopener noreferrer">
                info@vcapital.pt
              </a>
            </p>
            <p className="text-Gray mt-5">
              Telefone: <a href="tel:(+351)291600300">291 600 300</a>
            </p>
          </div>
          <div className="bg-white p-5 relative shadow-[0px_2px_25px_rgba(9,51,66,0.08)] basis-full md:basis-1/3">
            <h4 className="text-Dark font-bold text-xl">Redes Sociais</h4>
            <div className="text-Gray mt-5 flex gap-10">
              <LinkedInIcon
                fontSize="large"
                className="cursor-pointer hover:text-LightGreen"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/v-capital-b8272325b/",
                    "_blank"
                  )
                }
              />
              <InstagramIcon
                fontSize="large"
                className="cursor-pointer hover:text-LightGreen"
                onClick={() =>
                  window.open("https://www.instagram.com/_vcapital_/", "_blank")
                }
              />
              <FacebookRoundedIcon
                fontSize="large"
                className="cursor-pointer hover:text-LightGreen"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=100089891899597",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
        <h4 className="text-xl text-LightGreen font-bold text-center mt-20 uppercase">
          Siga as nossas redes sociais
        </h4>
        <p className="text-Gray text-center mt-5 pb-20 leading-7">
          Junte-se a nós nas redes sociais para descobrir as nossas últimas
          notícias, dicas
          <br /> e ver como a nossa abordagem única ajuda as empresas a alcançar
          o sucesso.
        </p>
      </Wrapper>
      <div className="absolute hidden md:block bottom-0 left-0 w-[300px] h-[500px] z-[-1]">
        <img src={BG1} alt="" className="object-contain w-full  z-[-1]" />
      </div>
      <div className="absolute -bottom-40 right-0 w-[300px] h-[500px] z-[-1]">
        <img src={BG2} alt="" className="absolute right-0  z-[-1]" />
      </div>
    </div>
  );
}

export default Contacts;
