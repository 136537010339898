import React from "react";
import Wrapper from "./Wrapper";

function InnerNavigation({ actualPage, innerSections, routerLinks }) {
  return (
    <div className="bg-white py-5 w-full hidden md:block md:text-sm xl:text-base sticky z-[5] top-[80px] shadow-[0px_1px_25px_rgba(0,0,0,0.05)]">
      <Wrapper>
        <div className="flex items-center justify-between gap-5 text-center">
          <div className="flex items-center gap-5">
            <p className="text-Dark font-semibold">{actualPage}</p>
            <div className="h-4 w-[2px] bg-LightGray" />
            {innerSections}
          </div>
          <div>{routerLinks}</div>
        </div>
      </Wrapper>
    </div>
  );
}

export default InnerNavigation;
