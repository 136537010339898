import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Logo } from "../../assets/navbar/capital_logo.svg";
import Buildings from "../../assets/navbar/submenu_bg.svg";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { ReactComponent as MobileBG } from "../../assets/navbar/navbar_bg.svg";
import Wrapper from "../general/Wrapper";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../components/general/CustomInput";
import CustomCheckbox from "../../components/general/CustomCheckbox";
import CloseIcon from "@mui/icons-material/Close";

function NavBar() {
  const navigate = useNavigate();
  const [ setAboutState] = useState(false);
  const aboutLinkRef = useRef(null);
  const investorsLinkRef = useRef(null);
  const entrepreneursLinkRef = useRef(null);
  const portfolioLinkRef = useRef(null);
  // const [investState, setInvestState] = useState(false);
  const [mobileAboutState, setMobileAboutState] = useState(false);
  // const [mobileInvestState, setMobileInvestState] = useState(false);
  const [open, setOpen] = useState(false);
  const [reserved, setReserved] = useState(false);
  const [login, setLogin] = useState(null);
  const [resetpassword, setResetPassword] = useState(false);
  const [step, setStep] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/entrepreneurs" &&
      entrepreneursLinkRef.current
    ) {
      entrepreneursLinkRef.current.focus();
    }
    if (location.pathname === "/portfolio" && portfolioLinkRef.current) {
      portfolioLinkRef.current.focus();
    }
    if (location.pathname === "/investors" && investorsLinkRef.current) {
      investorsLinkRef.current.focus();
    }

    if (
      aboutLinkRef.current &&
      (location.pathname === "/about" ||
        location.pathname === "/about/sustain" ||
        location.pathname === "/about/team" ||
        location.pathname === "/about/society")
    ) {
      aboutLinkRef.current.focus();
    }
  }, [location, entrepreneursLinkRef, portfolioLinkRef, investorsLinkRef]);

  return (
    <div className="bg-Light shadow-[0px_1px_30px_rgba(0,0,0,0.08)] border border-[#FFFFFF33] w-screen h-20 fixed top-0 left-0 z-10">
      <div className="flex justify-end md:justify-between items-center h-full w-11/12  mx-auto relative">
        <div className="flex items-center w-8/12 xl:w-7/12 2xl:w-6/12 justify-between">
          <Logo
            className="absolute left-1/2 -translate-x-1/2 md:relative md:left-0 md:translate-x-0 cursor-pointer"
            onClick={() => navigate("/")}
          />
          <div className="hidden lg:flex gap-5 font-semibold text-Dark ">
            <div className="group/about">
              <NavLink
                to={"#"}
                className="leading-[80px] cursor-pointer focus:text-[#337E84]"
                onMouseEnter={() => {
                  setAboutState(true);
                }}
                onMouseLeave={() => {
                  setAboutState(false);
                }}
                ref={aboutLinkRef}
              >
                Sobre Nós
              </NavLink>
              <div className=" bg-Light fixed left-0 top-[78px] w-full min-h-[100px] overflow-hidden hidden group-hover/about:flex z-[5] shadow-[inset_0px_1px_30px_rgba(0,0,0,0.08)] items-center">
                <div className="h-full w-11/12 md:w-8/12 mx-auto py-5">
                  <div className="flex flex-col gap-2 2xl:ml-[10%]">
                    <div className="flex gap-3 h-full items-center text-darkGreen">
                      <NavLink
                        to={"/about"}
                        className="hover:text-Salmon focus:text-Salmon transition-all duration-300"
                        onClick={() => {
                          setLogin(null);
                        }}
                      >
                        Quem Somos
                      </NavLink>
                    </div>
                    <div className="flex gap-3 h-full items-center text-darkGreen">
                      <NavLink
                        to={"/about/team"}
                        className="hover:text-Salmon focus:text-Salmon transition-all duration-300"
                        onClick={() => {
                          setLogin(null);
                        }}
                      >
                        Equipa
                      </NavLink>
                    </div>
                    <div className="flex gap-3 h-full items-center text-darkGreen">
                      <NavLink
                        to={"/about/sustain"}
                        className="hover:text-Salmon focus:text-Salmon transition-all duration-300"
                        onClick={() => {
                          setLogin(null);
                        }}
                      >
                        Sustentabilidade e Responsabilidade
                      </NavLink>
                    </div>
                    <div className="flex gap-3  h-full items-center text-darkGreen">
                      <NavLink
                        to={"/about/society"}
                        className="hover:text-Salmon focus:text-Salmon transition-all duration-300"
                        onClick={() => {
                          setLogin(null);
                        }}
                      >
                        Identificação da Sociedade
                      </NavLink>
                    </div>
                    <div className="flex gap-3  h-full items-center text-darkGreen">
                      <NavLink
                        to={"/about/regulation"}
                        className="hover:text-Salmon focus:text-Salmon transition-all duration-300"
                        onClick={() => {
                          setLogin(null);
                        }}
                      >
                        Regulamentos
                      </NavLink>
                    </div>
                  </div>
                  <img
                    src={Buildings}
                    alt=""
                    className="absolute top-10 object-top object-contain min-w-[280px] z-[-1]"
                  />
                </div>
              </div>
            </div>
            {/* <div className="group/invest">
              <p
                className="leading-[80px] cursor-pointer"
                onMouseEnter={() => {
                  setInvestState(true);
                }}
                onMouseLeave={() => {
                  setInvestState(false);
                }}
              >
                Para Investidores
              </p>
              <div className=" bg-Light fixed left-0 top-[78px] w-screen min-h-[100px] overflow-hidden hidden group-hover/invest:flex z-[5] shadow-[inset_0px_1px_30px_rgba(0,0,0,0.08)] items-center">
                <div className="h-full w-11/12 md:w-8/12 mx-auto py-5">
                  <div className="flex flex-col gap-2 ml-[33%]">
                    <div className="flex gap-3  h-full items-center text-Salmon">
                      <Link
                        to={"/investors"}
                        className="hover:text-darkGreen transition-all duration-300"
                      >
                        Para Investidores
                      </Link>
                    </div>
                    <div className="flex gap-3  h-full items-center text-Salmon">
                      <Link
                        to={"/investors/politics"}
                        className="hover:text-darkGreen transition-all duration-300"
                      >
                        Política de Investimento
                      </Link>
                    </div>
                  </div>
                  <img
                    src={Buildings}
                    alt=""
                    className="absolute top-0 object-top object-contain min-w-[280px] z-[-1]"
                  />
                </div>
              </div>
            </div> */}
            <NavLink
              color="#1B1B1B"
              to={"/investors"}
              ref={investorsLinkRef}
              className="leading-[80px] cursor-pointer focus:text-[#337E84]"
              onClick={() => {
                setLogin(null);
              }}
            >
              Para Investidores
            </NavLink>
            <NavLink
              color="#1B1B1B"
              to={"/entrepreneurs"}
              ref={entrepreneursLinkRef}
              className="leading-[80px] cursor-pointer focus:text-[#337E84]"
              onClick={() => {
                setLogin(null);
              }}
            >
              Para Empreendedores
            </NavLink>
            <NavLink
              color="#1B1B1B"
              to={"/portfolio"}
              ref={portfolioLinkRef}
              className="leading-[80px] cursor-pointer focus:text-[#337E84]"
              onClick={() => {
                setLogin(null);
              }}
            >
              Portfolio
            </NavLink>
          </div>
        </div>
        <div
          className="block lg:hidden cursor-pointer text-darkGreen"
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <CloseRoundedIcon fontSize="large" />
          ) : (
            <MenuRoundedIcon fontSize="large" />
          )}
        </div>
        <div className="gap-2 items-center hidden lg:flex">
          <Link
            to={"/contacts"}
            className="hidden lg:block border border-[#E2E8F0] rounded px-4 py-3 text-Gray bg-[#FFFFFFB2]"
            onClick={() => {
              setLogin(null);
            }}
          >
            Contactos
          </Link>
          <div
            className="hidden lg:block border relative border-[#E2E8F0] rounded px-4 py-3 text-Salmon font-semibold bg-[#FFFFFFB2] cursor-pointer"
            onClick={() => setReserved(!reserved)}
          >
            Área Reservada
          </div>
          {reserved && (
            <div
              onClick={() => setReserved(false)}
              className="fixed top-[80px] right-0 z-[2] text-darkGreen font-semibold flex flex-col gap-3 bg-Light py-5 pl-10 pr-40 shadow-[inset_0px_2px_8px_-2px_rgba(0,0,0,0.08)]"
            >
              <div
                className="hover:text-Salmon cursor-pointer"
                onClick={() => setLogin("investor")}
              >
                Para Investidores
              </div>
              <div
                className="hover:text-Salmon cursor-pointer"
                onClick={() => setLogin("entrepreneur")}
              >
                Para Empreendedores
              </div>
              <div
                className="hover:text-Salmon cursor-pointer"
                onClick={() => setLogin("vcapital")}
              >
                VCapital
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${
          open ? "block" : "hidden"
        } sx:hidden absolute top-16 left-0 h-screen w-full bg-Light py-10 z-[1]`}
      >
        <Wrapper>
          <div className="flex flex-col gap-10">
            <div
              className="flex flex-col"
              onClick={() => setMobileAboutState(!mobileAboutState)}
            >
              <div className="text-2xl font-semibold text-darkGreen flex gap-2 items-center">
                Sobre Nós
                <KeyboardArrowDownRoundedIcon fontSize="large" />
              </div>
              <div
                className={`${
                  mobileAboutState ? "max-h-[200px]" : "max-h-0"
                } flex flex-col pl-1 mt-2 overflow-hidden transition-all duration-300 text-lg text-darkGreen font-semibold gap-2`}
              >
                <Link
                  color="#337E84"
                  to={"/about"}
                  onClick={() => setOpen(false)}
                >
                  Quem Somos
                </Link>
                <Link
                  color="#337E84"
                  to={"/about/team"}
                  onClick={() => setOpen(false)}
                >
                  Equipa
                </Link>
                <Link
                  color="#337E84"
                  to={"/about/sustain"}
                  onClick={() => setOpen(false)}
                >
                  Sustentabilidade e Responsabilidade
                </Link>
                <Link
                  color="#337E84"
                  to={"/about/society"}
                  onClick={() => setOpen(false)}
                >
                  Identificação da Sociedade
                </Link>
                <Link
                  color="#337E84"
                  to={"/about/regulation"}
                  onClick={() => setOpen(false)}
                >
                  Regulamentos
                </Link>
              </div>
            </div>
            {/* <div
              className="flex flex-col"
              onClick={() => setMobileInvestState(!mobileInvestState)}
            >
              <div className="text-2xl font-semibold text-Salmon flex gap-2 items-center">
                Para Investidores
                <KeyboardArrowDownRoundedIcon fontSize="large" />
              </div>
              <div
                className={`${
                  mobileInvestState ? "h-[70px]" : "h-0"
                } flex flex-col pl-1 mt-2 overflow-hidden transition-all duration-300 text-lg text-Salmon font-semibold gap-2`}
              >
                <Link
                  color="#337E84"
                  to={"/investors"}
                  onClick={() => setOpen(false)}
                >
                  Para Investidores
                </Link>
                <Link
                  color="#337E84"
                  to={"/investors/politics"}
                  onClick={() => setOpen(false)}
                >
                  Política de Investimento
                </Link>
              </div>
            </div> */}
            <Link
              color="#1B1B1B"
              to={"/investors"}
              className="text-2xl font-semibold"
              onClick={() => setOpen(false)}
            >
              Para Investidores
            </Link>
            <Link
              color="#1B1B1B"
              to={"/entrepreneurs"}
              className="text-2xl font-semibold"
              onClick={() => setOpen(false)}
            >
              Para Empreendedores
            </Link>
            <Link
              color="#1B1B1B"
              to={"/portfolio"}
              className="text-2xl font-semibold"
              onClick={() => setOpen(false)}
            >
              Portfolio
            </Link>
            <Link
              color="#1B1B1B"
              to={"/contacts"}
              className="text-2xl font-semibold"
              onClick={() => setOpen(false)}
            >
              Contactos
            </Link>
            <div
              className="text-2xl font-semibold text-Dark flex gap-2 items-center cursor-pointer"
              onClick={() => setReserved(!reserved)}
            >
              Área Reservada
              <KeyboardArrowDownRoundedIcon fontSize="large" />
            </div>
            {reserved && (
              <div
                onClick={() => setReserved(false)}
                className="text-darkGreen font-semibold flex flex-col gap-3 text-lg"
              >
                <div
                  className="hover:text-Salmon cursor-pointer"
                  onClick={() => setLogin("investor")}
                >
                  Para Investidores
                </div>
                <div
                  className="hover:text-Salmon cursor-pointer"
                  onClick={() => setLogin("entrepreneur")}
                >
                  Para Empreendedores
                </div>
                <div
                  className="hover:text-Salmon cursor-pointer"
                  onClick={() => setLogin("vcapital")}
                >
                  VCapital
                </div>
              </div>
            )}
            {login !== null && (
              <div className="fixed right-0 top-0 w-full h-full bg-Light ">
                {!resetpassword ? (
                  <div className="flex flex-col items-center h-full justify-center w-11/12 mx-auto gap-5 relative">
                    <CloseIcon
                      className="absolute right-0 top-20 cursor-pointer"
                      onClick={() => {
                        setLogin(null);
                        setResetPassword(false);
                        setStep(0);
                      }}
                    />
                    <h3 className="text-2xl text-center font-bold">
                      Login{" "}
                      {login === "investor" ? (
                        <span className="text-darkGreen"> Investidor</span>
                      ) : login === "entrepreneur" ? (
                        <span className="text-Salmon"> Empreendedor</span>
                      ) : (
                        <span className="from-[#395960] to-[#9DC5C3] bg-clip-text bg-gradient-to-r text-transparent">
                          VCapital
                        </span>
                      )}
                    </h3>
                    <div className="w-full">
                      <p>Utilizador</p>
                      <CustomInput
                        type={"text"}
                        placeholder="utilizador@exemplo.com"
                      />
                    </div>
                    <div className="w-full">
                      <p>Password</p>
                      <CustomInput
                        type={"passwrod"}
                        placeholder="Escreva aqui a sua password"
                      />
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <CustomCheckbox
                        label={"Lembrar"}
                        type="checkbox"
                        squared
                      />
                      <p
                        className="text-Gray cursor-pointer"
                        onClick={() => setResetPassword(true)}
                      >
                        Esqueceu a sua password?
                      </p>
                    </div>
                    <button
                      className={`rounded px-20 py-3 text-white font-bold uppercase ${
                        login === "investor"
                          ? "bg-darkGreen"
                          : login === "entrepreneur"
                          ? "bg-Salmon"
                          : "bg-gradient-to-r from-[#395960] to-[#9DC5C3]"
                      }`}
                    >
                      Entrar
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-center h-full justify-center w-11/12 mx-auto gap-5 relative">
                    <CloseIcon
                      className="absolute right-0 top-20 cursor-pointer"
                      onClick={() => {
                        setLogin(null);
                        setResetPassword(false);
                        setStep(0);
                      }}
                    />
                    <div className="w-full flex flex-col gap-5 justify-center items-center">
                      <h3 className="text-2xl text-center font-bold">
                        {step === 0
                          ? "Redefinir Password"
                          : step === 1
                          ? "Verificar email"
                          : "Criar Nova Password"}
                      </h3>
                      <p className="text-Gray text-center">
                        {step === 0
                          ? "   Por favor insira o seu email para receber um código de verificação."
                          : step === 1
                          ? "Por favor insira o código de 4 dígitos enviados para email@email.com"
                          : "A sua nova password deve conter 234813409138 mil coisas."}
                      </p>
                      <div className="w-full">
                        {step === 0 ? (
                          <>
                            <p>Email</p>
                            <CustomInput
                              type={"text"}
                              placeholder="utilizador@exemplo.com"
                            />
                          </>
                        ) : step === 1 ? (
                          <>
                            <CustomInput
                              type={"text"}
                              placeholder="utilizador@exemplo.com"
                            />
                            <p className="text-center">Reenviar Código</p>
                          </>
                        ) : (
                          <>
                            <p>Nova Password</p>
                            <CustomInput
                              type={"password"}
                              placeholder="Insira a Password"
                            />
                            <p>Confirmar Password</p>
                            <CustomInput
                              type={"password"}
                              placeholder="Insira a Password"
                            />
                          </>
                        )}
                      </div>
                      <button
                        type={step !== 2 ? "button" : "submit"}
                        disabled
                        className={`rounded px-20 py-3 text-white font-bold uppercase bg-Dark`}
                        onClick={() => {
                          setStep(step + 1);
                        }}
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                )}
                <img
                  src={Buildings}
                  alt=""
                  className="absolute -bottom-40 right-0 object-top object-contain min-w-[380px] z-[-1]"
                />
              </div>
            )}
          </div>
        </Wrapper>
        <div className="absolute left-0 bottom-0 w-full h-full z-[-1]">
          <MobileBG className="object-contain w-full h-full relative -bottom-[150px] " />
        </div>
      </div>
      {login !== null && (
        <>
          <div className="fixed top-20 left-0 w-full h-full z-[-99999] bg-black opacity-30"></div>
          <div className="hidden lg:block fixed right-0 w-1/2 h-full bg-Light shadow-[inset_0px_4px_50px_-2px_rgba(0,0,0,0.08)]">
            <div className="flex flex-col items-center h-full justify-center w-2/3 mx-auto gap-5 relative">
              <CloseIcon
                className="absolute right-0 top-40 cursor-pointer"
                onClick={() => {
                  setLogin(null);
                  setResetPassword(false);
                  setStep(0);
                }}
              />
              {!resetpassword ? (
                <div className="w-full flex flex-col gap-5 justify-center items-center">
                  <h3 className="text-2xl text-center font-bold">
                    Login{" "}
                    {login === "investor" ? (
                      <span className="text-darkGreen"> Investidor</span>
                    ) : login === "entrepreneur" ? (
                      <span className="text-Salmon"> Empreendedor</span>
                    ) : (
                      <span className="from-[#395960] to-[#9DC5C3] bg-clip-text bg-gradient-to-r text-transparent">
                        VCapital
                      </span>
                    )}
                  </h3>
                  <div className="w-full">
                    <p>Utilizador</p>
                    <CustomInput
                      type={"text"}
                      placeholder="utilizador@exemplo.com"
                    />
                  </div>
                  <div className="w-full">
                    <p>Password</p>
                    <CustomInput
                      type={"passwrod"}
                      placeholder="Escreva aqui a sua password"
                    />
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <CustomCheckbox label={"Lembrar"} type="checkbox" squared />
                    <p
                      className="text-Gray cursor-pointer"
                      onClick={() => setResetPassword(true)}
                    >
                      Esqueceu a sua password?
                    </p>
                  </div>
                  <button
                    className={`rounded px-20 py-3 text-white font-bold uppercase ${
                      login === "investor"
                        ? "bg-darkGreen"
                        : login === "entrepreneur"
                        ? "bg-Salmon"
                        : "bg-gradient-to-r from-[#395960] to-[#9DC5C3]"
                    }`}
                  >
                    Entrar
                  </button>
                </div>
              ) : (
                <div className="w-full flex flex-col gap-5 justify-center items-center">
                  <h3 className="text-2xl text-center font-bold">
                    {step === 0
                      ? "Redefinir Password"
                      : step === 1
                      ? "Verificar email"
                      : "Criar Nova Password"}
                  </h3>
                  <p className="text-Gray text-center">
                    {step === 0
                      ? "   Por favor insira o seu email para receber um código de verificação."
                      : step === 1
                      ? "Por favor insira o código de 4 dígitos enviados para email@email.com"
                      : "A sua nova password deve conter 234813409138 mil coisas."}
                  </p>
                  <div className="w-full">
                    {step === 0 ? (
                      <>
                        <p>Email</p>
                        <CustomInput
                          type={"text"}
                          placeholder="utilizador@exemplo.com"
                        />
                      </>
                    ) : step === 1 ? (
                      <>
                        <CustomInput
                          type={"text"}
                          placeholder="utilizador@exemplo.com"
                        />
                        <p className="text-center">Reenviar Código</p>
                      </>
                    ) : (
                      <>
                        <p>Nova Password</p>
                        <CustomInput
                          type={"password"}
                          placeholder="Insira a Password"
                        />
                        <p>Confirmar Password</p>
                        <CustomInput
                          type={"password"}
                          placeholder="Insira a Password"
                        />
                      </>
                    )}
                  </div>
                  <button
                    type={step !== 2 ? "button" : "submit"}
                    disabled
                    className={`rounded px-20 py-3 text-white font-bold uppercase bg-Dark`}
                    onClick={() => {
                      setStep(step + 1);
                    }}
                  >
                    Continuar
                  </button>
                </div>
              )}
            </div>
            <img
              src={Buildings}
              alt=""
              className="absolute -bottom-40 right-0 object-top object-contain min-w-[380px] z-[-1]"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default NavBar;
