import React, { useEffect, useState } from "react";
import Wrapper from "../../components/general/Wrapper";
import { ReactComponent as WalletBG } from "../../assets/wallet/wallet_bg.svg";
import { AnimationDiv } from "./styles/index_styles";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import AnthonyLogo from "../../assets/portfolio/anthony_icon.svg";
import BiomaskLogo from "../../assets/portfolio/biomask_icon.svg";
import HWLLogo from "../../assets/portfolio/hwl_icon.svg";
import IlhaPneuLogo from "../../assets/portfolio/ilhapneu_icon.png";
import NMDSLogo from "../../assets/portfolio/nmds_icon.svg";
import PneuzarcoLogo from "../../assets/portfolio/pneuzarco_icon.svg";
import PoderLogo from "../../assets/portfolio/poder_icon.png";
import SeehealthLogo from "../../assets/portfolio/seehealth_icon.svg";
import VipaconnectLogo from "../../assets/portfolio/vipaconnect_icon.svg";
import VipaenergyLogo from "../../assets/portfolio/vipaenergy_icon.svg";
import VipainternationalLogo from "../../assets/portfolio/vipainternational_icon.svg";
import VipalabLogo from "../../assets/portfolio/vipalab_icon.svg";
import VipaskyLogo from "../../assets/portfolio/vipasky_icon.svg";
import VipatelecomLogo from "../../assets/portfolio/vipatelecom_icon.png";
import VipacorpLogo from "../../assets/portfolio/vipacorp_icon.svg";
import dsLogo from "../../assets/portfolio/DS_icon.svg";
import corporateLogo from "../../assets/portfolio/corporate_icon.svg";
import psLogo from "../../assets/portfolio/PS_icon.svg";
import GDELogo from "../../assets/portfolio/GDE_icon.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

function CompanyShow() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [company, setCompany] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [companyDesignation, setCompanyDesignation] = useState(null);
  const [tech, setTech] = useState(null);
  const [sector, setSector] = useState(null);
  const [date, setDate] = useState(null);
  const [desc, setDesc] = useState(null);
  const [local, setLocal] = useState(null);
  const [value, setValue] = useState(null);
  const [linkIn, setLinkIn] = useState(null);
  const [ig, setIg] = useState(null);
  const [face, setFace] = useState(null);
  const [website, setWebsite] = useState(null);

  function HandleLogo(temp) {
    switch (temp) {
      case "vipaconnect":
        return (
          <img
            src={VipaconnectLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      case "vipalab":
        return (
          <img
            src={VipalabLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "anthony":
        return (
          <img
            src={AnthonyLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "biomask":
        return (
          <img
            src={BiomaskLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "hwl":
        return (
          <img src={HWLLogo} alt="" className="w-full h-full object-contain" />
        );

      case "nmds":
        return (
          <img src={NMDSLogo} alt="" className="w-full h-full object-contain" />
        );

      case "pneuzarco":
        return (
          <img
            src={PneuzarcoLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "seehealth":
        return (
          <img
            src={SeehealthLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "vipaenergy":
        return (
          <img
            src={VipaenergyLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "vipainternational":
        return (
          <img
            src={VipainternationalLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );

      case "vipasky":
        return (
          <img
            src={VipaskyLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      case "ilhapneu": {
        return (
          <img
            src={IlhaPneuLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      }
      case "vipatelecom": {
        return (
          <img
            src={VipatelecomLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      }
      case "poder": {
        return (
          <img
            src={PoderLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      }
      case "ps": {
        return (
          <img src={psLogo} alt="" className="w-full h-full object-contain" />
        );
      }
      case "corporate": {
        return (
          <img
            src={corporateLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      }
      case "gde": {
        return (
          <img src={GDELogo} alt="" className="w-full h-full object-contain" />
        );
      }
      case "vipacorp": {
        return (
          <img
            src={VipacorpLogo}
            alt=""
            className="w-full h-full object-contain"
          />
        );
      }
      case "ds": {
        return (
          <img src={dsLogo} alt="" className="w-full h-full object-contain" />
        );
      }
      default:
        break;
    }
  }
  function GetInfo(company) {
    switch (company) {
      case "vipaconnect":
        setCompanyName("VGPC, Lda.");
        setCompanyDesignation(" ( Vipa Connect )");
        setTech();
        setSector("Consultoria/Tecnologia e Inovação");
        setDate("2019 - 2022");
        setDesc(
          "Desenvolvimento e manutenção de aplicações multiplataforma, SEO, Data Science, Cloud Computing e Publicidade Digital."
        );
        setLocal("Madeira");
        setValue("147.500,00€");
        setLinkIn("https://www.linkedin.com/company/vipaconnect/");
        setIg("https://www.instagram.com/vipaconnect/");
        setFace("https://pt-br.facebook.com/vipaconnecting/");
        setWebsite("https://www.vipaconnect.com/");
        break;

      case "biomask":
        setCompanyName("The BioMask Company, Lda.");
        setTech();
        setSector("Tecnologia e Inovação/Reciclagem");
        setDate("2021 - Presente");
        setDesc(
          "Atua na área da saúde, através do desenvolvimento de máscaras biodegradáveis, laváveis e com filtro (FFP) de classificação FFP2 ECDC."
        );
        setLocal("Madeira");
        setValue("29.850,00€");
        setLinkIn();
        setIg();
        setFace();
        setWebsite();
        break;

      case "nmds":
        setCompanyName("Números Distintos, Lda.");
        setCompanyDesignation(" ( NMDS )");
        setTech();
        setSector("Consultoria e Contabilidade");
        setDate("2021 - Presente");
        setDesc(
          "Opera nos setores de Gestão Financeira e Consultoria, onde é sempre garantida a transparência, compromisso, confidencialidade e rigor em todos os seus serviços."
        );
        setLocal("Madeira");
        setValue("6.955,00€");
        setLinkIn("https://www.linkedin.com/company/nmdsconsulting/");
        setIg("https://www.instagram.com/nmdsconsulting/");
        setFace("https://www.facebook.com/numerosdistintos/");
        setWebsite("https://www.nmds.pt/");
        break;

      case "pneuzarco":
        setCompanyName("Futurimbatível, Lda.");
        setCompanyDesignation(" ( Pneuzarco )");
        setTech("Tecnologia industrial");
        setSector("Setor Automóvel/Reciclagem");
        setDate("2015-2017");
        setDesc(
          "Fornecimento de serviços especializados em gestão de frotas, destacando-se no mercado pelo apoio técnico especializado, qualidade de serviços e na escolha de produtos de excelência."
        );
        setLocal("Madeira");
        setValue("199.088,75€");
        setLinkIn("https://www.linkedin.com/company/pneuzarco/about/");
        setIg("https://www.instagram.com/pneuzarco/");
        setFace("https://www.facebook.com/pneuzarco");
        setWebsite("https://www.pneuzarco.pt/");
        break;

      case "seehealth":
        setCompanyName("SH - SeeHealth, Lda.");
        setCompanyDesignation(" ( See Health )");
        setTech();
        setSector("Tecnologia e Inovação");
        setDate("2020 - Presente");
        setDesc(
          "Pesquisa científica, ferramentas tecnológicas e Design UX/UI no desenvolvimento de software e aplicações para sistemas de saúde."
        );
        setLocal("Madeira");
        setValue("99.750,00€");
        setLinkIn("https://www.linkedin.com/company/seehealth/");
        setIg("https://www.instagram.com/sh_seehealth/");
        setFace("https://www.facebook.com/seehealth");
        setWebsite("https://www.seehealth.pt/");
        break;

      case "vipaenergy":
        setCompanyName("VE - ERM, Lda.");
        setCompanyDesignation(" ( Vipa Energy )");
        setTech();
        setSector("Energia Renovável");
        setDate("2020 - Presente");
        setDesc(
          "Inspirada por tecnologias que inovam a produção de energia, com o objetivo de construir centrais que recolhem a energia das ondas e produzem eletricidade sem causar poluição do ar."
        );
        setLocal("Madeira");
        setValue("11.300,00€");
        setLinkIn("https://www.linkedin.com/company/vipaenergy/");
        setIg("https://www.instagram.com/vipaenergy/");
        setFace("https://www.facebook.com/vipaenergy/");
        setWebsite("https://www.vipaenergy.com/");
        break;

      case "ilhapneu":
        setCompanyName("Despertafama, Lda.");
        setCompanyDesignation(" ( Ilha Pneu )");
        setTech("Tecnologia industrial");
        setSector("Setor Automóvel");
        setDate("2009 - 2017");
        setDesc(
          "A Ilha Pneu é uma Rede de Centros Auto desde 2009, que tem a sua implementação na Região Autónoma da Madeira."
        );
        setLocal("Madeira");
        setValue("359.850,00€");
        setLinkIn();
        setIg("https://www.instagram.com/ilha.pneu/");
        setFace("https://www.facebook.com/ilhapneu/");
        setWebsite("https://www.ilhapneu.pt/");
        break;

      case "poder":
        setCompanyName("Poder & Negócios, Lda.");
        setTech();
        setSector("Publicidade Digital");
        setDate("2019 - Presente");
        setDesc(
          "Prestação de serviços de marketing e comunicação, organização de eventos e espetáculos, e venda e aluguer de equipamentos para eventos."
        );
        setLocal("Lisboa/Madeira");
        setValue("27.125,00€");
        setLinkIn();
        setIg();
        setFace();
        setWebsite("https://www.poderenegocios.pt/");
        break;

      case "ps":
        setCompanyName("Pesquisa Subtil, Lda.");
        setTech();
        setSector("Comercialização de metais preciosos");
        setDate("2021 - Presente");
        setDesc(
          "Atividades de comércio, importação, exportação, representação e distribuição de minérios metálicos, estando registada na Direção-Geral das Atividades Económicas (DGAE) sob a licença n.º072."
        );
        setLocal("Madeira");
        setValue("5.750,00€");
        setLinkIn();
        setIg();
        setFace();
        setWebsite();
        break;

      case "corporate":
        setCompanyName("PeopleCorp, Lda.");
        setTech();
        setSector("Consultoria");
        setDate("2018 - 2019");
        setDesc();
        setLocal("Lisboa");
        setValue("30.800,00€");
        setLinkIn();
        setIg();
        setFace();
        setWebsite();
        break;

      case "gde":
        setCompanyName("Geração D’Ética, Lda.");
        setTech();
        setSector("Automóvel");
        setDate("2018 - 2019");
        setDesc();
        setLocal("Lisboa");
        setValue("66.500,00€");
        setLinkIn();
        setIg();
        setFace();
        setWebsite();
        break;

      case "vipacorp":
        setCompanyName("VIPAPAR SGPS, Lda.");
        setCompanyDesignation(" ( Vipa )");
        setTech();
        setSector("Gestão de participações sociais");
        setDate("2019 - Presente");
        setDesc();
        setLocal("Lisboa");
        setValue("200.250,00€");
        setLinkIn("https://www.linkedin.com/company/vipacorp/");
        setIg("https://www.instagram.com/vipacorporation/");
        setFace("https://www.facebook.com/vipacorporation");
        setWebsite("https://www.vipacorporation.com/");
        break;

      case "ds":
        setCompanyName("Dear Smile, Lda.");
        setTech();
        setSector("Turismo Rural");
        setDate("2017 - 2018");
        setDesc();
        setLocal("Madeira");
        setValue("221.700,00€");
        setLinkIn();
        setIg();
        setFace();
        setWebsite();
        break;
      case "hwl":
        setCompanyName("VP-HWL, Lda.");
        setCompanyDesignation(" ( HWL )");
        setTech();
        setSector("Imobiliário");
        setDate("2020 - 2022");
        setDesc(
          "Consultora especializada na prestação de serviços imobiliários, nomeadamente na promoção e venda de imóveis."
        );
        setLocal("Madeira");
        setValue("47.500,00€");
        setLinkIn();
        setIg("https://www.instagram.com/hwl_homeswithlove/");
        setFace("https://www.facebook.com/hwlrealestate");
        setWebsite("https://www.hwl.pt/");
        break;
      case "anthony":
        setCompanyName("St. Anthony, Lda.");
        setTech();
        setSector("Turismo Rural");
        setDate("2021 - 2022");
        setDesc(
          "Empresa de serviços que se dedica à gestão de aluguer de empreendimentos turísticos em espaços rurais com o objetivo de fortalecer o desenvolvimento da região e agregar valor aos produtos e serviços típicos locais."
        );
        setLocal("Madeira");
        setValue("44.150,00€");
        setLinkIn();
        setIg();
        setFace();
        setWebsite();
        break;

      default:
        break;
    }
  }
  useEffect(() => {
    setCompany(searchParams.get("company"));

    if (company != null) {
      GetInfo(company);
    }
  }, [company, searchParams]);

  const handleNavigation = () => {
    navigate(-1);
    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
  };
  
  return (
    <>
      <div className="w-full relative  bg-Light z-[1]">
        <div className=" bg-Light mt-20 border-b border-b-[#E2E8F0]">
          <Wrapper>
            <div
              className="flex items-center gap-2 py-5 cursor-pointer"
              onClick={handleNavigation}
            >
              <ArrowBackRoundedIcon />
              <span className="text-Gray uppercase text-sm font-bold">
                Retroceder2
              </span>
            </div>
          </Wrapper>
        </div>
        {company && (
          <Wrapper>
            <div className="flex flex-col md:flex-row gap-10 min-h-[90vh] pb-10 pt-20">
              <div className="flex gap-10 items-start basis-full md:basis-3/4 justify-center">
                <div
                  className={`rounded-full border-[4px] border-[rgba(51,126,132,0.25)] flex items-center justify-center relative z-[1]`}
                >
                  <div className="w-[130px] h-[130px] rounded-full border-[6px] border-Light bg-white flex items-center justify-center">
                    <div className="w-4/6 h-4/6">{HandleLogo(company)}</div>
                  </div>
                </div>
                <div className="mt-5">
                  <h3 className="text-3xl font-bold text-darkGreen leading-8">
                    {companyName}
                    <span className="opacity-50">{companyDesignation}</span>
                  </h3>
                  <p className="text-Gray font-semibold text-lg leading-8">
                    <span className="opacity-50">{tech}</span> | {sector}
                  </p>
                  <p className="text-Gray">{date}</p>
                  <div className="mt-10">
                    {desc && (
                      <>
                        <h4 className="text-xl text-Dark font-semibold leading-8">
                          Ideação
                        </h4>
                        <p className="text-Gray leading-8">{desc}</p>
                      </>
                    )}
                    <div className="flex my-10 items-center gap-5">
                      <h4 className="text-xl text-Dark font-semibold">
                        Localização:
                      </h4>
                      <p className="text-LightGreen text-xl font-semibold">
                        {local}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="basis-full md:basis-1/4 flex flex-col gap-5 mt-5">
                <div className="bg-white border border-LightGray shadow-[0px_2px_25px_rgba(9,51,66,0.08)] p-10">
                  <h3 className="text-2xl font-bold text-Dark text-center">
                    {value}
                  </h3>
                  <p className="text-Gray  text-center mt-3">
                    Montante Investido
                  </p>
                </div>
                <div className="bg-white border border-LightGray shadow-[0px_2px_25px_rgba(9,51,66,0.08)] p-10">
                  <div className="flex gap-5 justify-center text-Gray">
                    <a href={linkIn} target="_blank" rel="noreferrer noopener">
                      <LinkedInIcon
                        fontSize="large"
                        className="hover:text-[#99CBC7]"
                      />
                    </a>
                    <a href={ig} target="_blank" rel="noreferrer  noopener">
                      <InstagramIcon
                        fontSize="large"
                        className="hover:text-[#99CBC7]"
                      />
                    </a>
                    <a href={face} target="_blank" rel="noreferrer noopener">
                      <FacebookRoundedIcon
                        fontSize="large"
                        className="hover:text-[#99CBC7]"
                      />
                    </a>
                  </div>
                  <a href={website} target="_blank" rel="noreferrer">
                    <p className="text-Dark text-center font-semibold mt-3">
                      Visitar Website
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </Wrapper>
        )}
        <AnimationDiv className="absolute top-0 left-0 z-[-1] w-full h-full md:h-auto pt-10">
          <WalletBG className="relative w-full h-full object-contain " />
        </AnimationDiv>
        <div className="bg-LightGray w-full py-5 border-t border-t-[#fff]">
          <div className="w-11/12 md:w-10/12 mx-auto flex flex-col md:flex-row justify-between items-start md:items-center gap-5">
            <p className="text-[#337E84] text-xl font-bold">
              Continuamente a trabalhar para um futuro sustentável através da
              potencialização de soluções inovadoras.
            </p>
            <Link
              to={"/about"}
              className="bg-white w-3/10 text-center px-5 py-3 uppercase text-LightGreen font-bold rounded"
            >
              Sobre nós
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default CompanyShow;
