import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../../../components/general/Wrapper";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { StepLabel } from "@mui/material";
import { CustomStep, CustomStepper } from "./components/index_styles";
import { FirstStep } from "./steps/FirstStep";
import { SecondStep } from "./steps/SecondStep";
import { ThirdStep } from "./steps/ThirdStep";
import { FourthStep } from "./steps/FourthStep";
import { FifthStep } from "./steps/FifthStep";
import { SixthStep } from "./steps/SixthStep";
import { SeventhStep } from "./steps/SeventhStep";
import { EightStep } from "./steps/EightStep";
import { NinthStep } from "./steps/NinthStep";
import { TenthStep } from "./steps/TenthStep";

function CreateProject() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "Identificação",
    "Sobre o Projeto",
    "Sobre a Empresa",
    "O Mercado",
    "A Equipa",
    "O Impacto",
    "Governação",
    "Os Financeiros",
    "Documentos",
    "Observações Finais",
  ];

  const handleNext = () => {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <div className=" bg-Light mt-20 border-b border-b-[#E2E8F0]">
        <Wrapper>
          <div
            className="flex items-center gap-2 py-5 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ArrowBackRoundedIcon />
            <span className="text-Gray uppercase text-sm font-bold">
              Retroceder
            </span>
          </div>
          <div className="py-10">
            <h3 className="text-center text-3xl font-bold text-LightGreen mb-5">
              Submeter Projecto
            </h3>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto">
              A VC - SGPS, Lda., comercialmente denomidada de VCapital, é uma
              sociedade de gestão de participações sociais.
            </p>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto my-5">
              Este formulário é dirigido a startups, desde a semente (ideação)
              até ao mercado, desejando apresentar os projetos ao nosso grupo de
              investidores com o objectivo de angariar capital e/ou obter
              mentoria e aconselhamento.
            </p>
            <p className="text-Gray text-center  w-full md:w-2/3 mx-auto ">
              Em caso de dúvidas, envie por favor um e-mail para
              info@vcapital.pt
            </p>
          </div>
        </Wrapper>
      </div>
      <Wrapper>
        <CustomStepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            return (
              <CustomStep key={label}>
                <StepLabel>{label}</StepLabel>
              </CustomStep>
            );
          })}
        </CustomStepper>
        <form>
          <FirstStep
            className={
              activeStep === 0 ? "visible" : "invisible absolute top-0"
            }
          />
          <SecondStep
            className={
              activeStep === 1 ? "visible" : "invisible absolute top-0"
            }
          />
          <ThirdStep
            className={
              activeStep === 2 ? "visible" : "invisible absolute top-0"
            }
          />
          <FourthStep
            className={
              activeStep === 3 ? "visible" : "invisible absolute top-0"
            }
          />
          <FifthStep
            className={
              activeStep === 4 ? "visible" : "invisible absolute top-0"
            }
          />
          <SixthStep
            className={
              activeStep === 5 ? "visible" : "invisible absolute top-0"
            }
          />
          <SeventhStep
            className={
              activeStep === 6 ? "visible" : "invisible absolute top-0"
            }
          />
          <EightStep
            className={
              activeStep === 7 ? "visible" : "invisible absolute top-0"
            }
          />
          <NinthStep
            className={
              activeStep === 8 ? "visible" : "invisible absolute top-0"
            }
          />
          <TenthStep
            className={
              activeStep === 9 ? "visible" : "invisible absolute top-0"
            }
          />

          <div className="flex w-full gap-5 items-center justify-end my-20">
            <button
              type="button"
              disabled={activeStep === 0}
              onClick={handleBack}
              className="bg-LightGray px-10 py-4 uppercase text-Gray font-bold rounded text-sm"
            >
              Anterior
            </button>

            <button
              type="button"
              onClick={handleNext}
              className="bg-LightGreen px-10 py-4 uppercase text-white font-bold rounded text-sm"
            >
              {activeStep === steps.length - 1 ? "Terminar" : "Continuar"}
            </button>
          </div>
        </form>
      </Wrapper>
    </>
  );
}

export default CreateProject;
