import React from "react";
import styled from "styled-components";

const CornerArrow = styled("div")`
  clip-path: polygon(0% 0%, 100% 99%, 100% 0%);
  background-color: #989ea4;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
`;

function RegulationItem({ header, summary, pdf, doc, link }) {
  return (
    <a
      href={pdf ?? doc}
      target="_blank"
      rel="noreferrer"
      className={`bg-LightGray hover:bg-[#F8F9FA] shadow-[0px_2px_25px_rgba(9,51,66,0.08)] p-8 relative basis-[calc(100%_/_2_-_20px)] group/item  hover:outline hover:outline-4 outline-[#337E841A] transition-all duration-300 text-left ${
        pdf || doc ? "text-Dark hover:text-darkGreen" : ""
      }`}
    >
      <div className="flex flex-col h-full justify-start">
        <h3 className="text-2xl font-bold mb-5 w-[calc(100%_-_80px)] leading-8">
          {header}
        </h3>
        <p className="text-Gray leading-8">{summary}</p>
        <p className="text-Gray leading-6">{link}</p>
      </div>
      {(pdf || doc) && (
        <div className="absolute top-8 right-8 w-[50px] h-[30px] flex items-end group-hover/item:text-darkGreen">
          <p>{pdf ? "PDF" : "DOC"}</p>
          <CornerArrow className="group-hover/item:bg-darkGreen group-hover/item:scale-150 " />
        </div>
      )}
    </a>
  );
}

export default RegulationItem;
