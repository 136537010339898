import React from "react";
import { Link } from "react-router-dom";
import InnerNavigation from "../../components/general/InnerNavigation";
import Wrapper from "../../components/general/Wrapper";

function Politics() {
  return (
    <div className="mt-20">
      <InnerNavigation
        actualPage={"Política de Investimento"}
        routerLinks={
          <div className="flex gap-5 text-Gray font-semibold">
            <Link to={"/investors"} className="hover:text-Salmon">
              Para Investidores
            </Link>
          </div>
        }
      />
      <Wrapper>
        <div className="my-20 py-10 px-10 bg-Light rounded-lg">
          <h3 className="text-4xl font-bold text-center text-darkGreen mt-5">
            Política de Investimento
          </h3>
          <p className="text-Gray text-center my-10 sm:mx-20 leading-8 text-lg">
            A “Política de Investimento” espelha a definição pela Vipa Capital
            (VC - SGPS, Lda.) dos princípios basilares a serem aplicados
            transversalmente no grupo societário que encabeça, no âmbito do
            investimento em startups nas fases{" "}
            <span className="text-Gray font-semibold">
              Pré-Seed, Seed e Early Stage{" "}
            </span>{" "}
            que promovam um investimento de impacto localizadas{" "}
            <span className="text-Gray font-semibold">em Portugal.</span>
          </p>
          {/* <p className="text-Gray text-center mt-10">Consulte o PDF completo</p>
          <div className="flex items-center mt-3 justify-center gap-2 text-Gray ">
            <FileCopyIcon />
            <p className="font-bold uppercase text-sm">
              Política de Investimento VCapital
            </p>
          </div> */}
        </div>
      </Wrapper>
    </div>
  );
}

export default Politics;
