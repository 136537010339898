import React from "react";
import styled from "styled-components";

const CornerArrow = styled("div")`
  clip-path: polygon(0% 0%, 100% 99%, 100% 0%);
  background-color: #989ea4;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
`;

function SubmitItem({ header, summary, project, onClick }) {
  return (
    <button
      onClick={onClick}
      className="bg-Light shadow-[0px_2px_25px_rgba(9,51,66,0.08)] p-5 relative basis-[calc(100%_/_2_-_20px)] lg:basis-[calc(100%_/_2_-_100px)]  group/item  hover:outline hover:outline-[6px] outline-[#ECF1EF80] transition-all duration-500 text-left"
    >
      <div className="flex flex-col h-full justify-start">
        <h3
          className={`${
            project
              ? "group-hover/item:text-Salmon"
              : "group-hover/item:text-darkGreen"
          } text-xl text-Gray font-bold mb-5 w-[calc(100%_-_55px)] transition-all duration-500`}
        >
          {project ? "Submeter Projecto" : "Submeter Teaser"}
        </h3>

        {project ? (
          <p className="text-Gray leading-6 text-normal">
            Está à procura de um projeto para impulsionar?
            <br /> <b>Descubra mais.</b>
          </p>
        ) : (
          <p className="text-Gray leading-6">
            Um teaser de investimento é um sumário de um ou dois slides com o
            potencial processo do negócio.
          </p>
        )}
      </div>
      <div
        className={`absolute top-5 right-5 w-[50px] h-[30px] flex items-end ${
          project
            ? "group-hover/item:text-Salmon"
            : "group-hover/item:text-darkGreen"
        } `}
      >
        <CornerArrow
          className={`${
            project
              ? "group-hover/item:bg-Salmon"
              : "group-hover/item:bg-darkGreen"
          } group-hover/item:scale-150`}
        />
      </div>
    </button>
  );
}

export default SubmitItem;
