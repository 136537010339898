import CustomCheckbox from "../../../../components/general/CustomCheckbox";
import CustomInput from "../../../../components/general/CustomInput";
import CustomTextField from "../../../../components/general/CustomTextField";

export const FifthStep = ({ className }) => (
  <div className={className}>
    <h3 className="text-Dark font-bold text-3xl">5. A Equipa</h3>
    <p className="text-Gray mb-3">
      Identifique as competências, experiência e motivação da sua equipa para
      ter sucesso
    </p>
    <div className="flex flex-col md:flex-row items-center md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Perfil profissional e motivação dos fundadores
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Empresa"
          name={"Empresa"}
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Novas contratações previstas para os próximos anos
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Morada"
          name={"Morada"}
        />
      </div>
    </div>
    <div className="flex  items-center gap-5">
      <div className=" w-full md:w-1/2 md:pr-[10px]">
        <p className="font-semibold text-DarkGray">
          Tamanho e perfil da equipa no ano de maturidade
          <span className="text-[#ED1D24]">*</span>
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="corporativo"
          name={"corporativo"}
        />
      </div>
    </div>
    <p className="font-semibold text-DarkGray">
      Quais os pontos fracos da sua equipa?
      <span className="text-[#ED1D24]">*</span>
    </p>
    <CustomCheckbox label={"Gestão"} value="Gestão" id="Gestão" name="Gestão" />
    <CustomCheckbox
      label={"Distribuição / Logística"}
      value="Distribuição / Logística"
      id="Distribuição / Logística"
      name="Distribuição / Logística"
    />
    <CustomCheckbox
      label={"Estratégia Empresarial"}
      value="Estratégia Empresarial"
      id="Estratégia Empresarial"
      name="Estratégia Empresarial"
    />
    <CustomCheckbox
      label={"Internacionalização"}
      value="Internacionalização"
      id="Internacionalização"
      name="Internacionalização"
    />
    <CustomCheckbox
      label={"Finanças"}
      value="Finanças"
      id="Finanças"
      name="Finanças"
    />
    <CustomCheckbox
      label={"Gestão de Pessoas"}
      value="Gestão de Pessoas"
      id="Gestão de Pessoas"
      name="Gestão de Pessoas"
    />
    <CustomCheckbox
      label={"Marketing"}
      value="Marketing"
      id="Marketing"
      name="Marketing"
    />
    <CustomCheckbox
      label={"Comercial / Vendas"}
      value="Comercial / Vendas"
      id="Comercial / Vendas"
      name="Comercial / Vendas"
    />
    <CustomCheckbox
      label={"Aptidões Técnicas"}
      value="Aptidões Técnicas"
      id="Aptidões Técnicas"
      name="Aptidões Técnicas"
    />
    <CustomCheckbox
      label={"Legislação"}
      value="Legislação"
      id="Legislação"
      name="Legislação"
    />
    <CustomCheckbox label={"Outra"} value="Outra" id="Outra" name="Outra" />
    <p className="font-semibold text-DarkGray">
      Outra informação relevante sobre a sua equipa
      <span className="text-[#ED1D24]">*</span>
    </p>
    <CustomTextField placeholder={"Escreva aqui"} name="informação equipa" />
  </div>
);
