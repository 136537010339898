import React from "react";
import StatsBG from "../../../assets/stats/stats_bg.svg";

function StatsSection() {
  return (
    <div className="w-11/12 md:w-full mx-auto relative">
      <div className="relative overflow-hidden py-20 xl:px-32 z-[1]">
        <h3 className="text-darkGreen text-4xl font-bold text-center">
          Os nossos números
        </h3>
        <p className="text-lg text-Gray font-semibold text-center">
          Temos o prazer de contar com a confiança de quem trabalha connosco.
        </p>
        <div className="grid md:grid-cols-2 xl:grid-cols-4 items-center mx-auto gap-5 mt-10 w-11/12">
          <div className="sm:min-h-[140px] lg:min-h-[160px] lg:min-w-[200px] mx-5 md:mx-1 p-4 bg-white shadow-[0px_2px_25px_rgba(9,51,66,0.08)] border border-[#EBEFF2] flex flex-col items-center justify-between md:items-start text-left gap-2 h-full">
            <div className="bg-[#337E841A] px-2 py-1 text-darkGreen font-bold text-sm uppercase">
              Investidores
            </div>
            <p className="text-Dark font-bold text-3xl">2</p>
            <p className="text-Gray text-lg">Total de Business Angels</p>
          </div>
          <div className="sm:min-h-[140px] lg:min-h-[160px] lg:min-w-[200px] mx-5 md:mx-1 p-4 bg-white shadow-[0px_2px_25px_rgba(9,51,66,0.08)] border border-[#EBEFF2] flex flex-col items-center justify-between md:items-start text-left gap-2 h-full">
            <div className="bg-[#FDA9921A] px-2 py-1 text-Salmon font-bold text-sm uppercase">
              Startups
            </div>
            <p className="text-Dark font-bold text-3xl">15</p>
            <p className="text-Gray text-lg leading-5">
              Total de empresas <br />
              apoiadas nos últimos 10 anos
            </p>
          </div>
          <div className="sm:min-h-[140px] lg:min-h-[160px] lg:min-w-[200px] mx-5 md:mx-1 p-4 bg-white shadow-[0px_2px_25px_rgba(9,51,66,0.08)] border border-[#EBEFF2] flex flex-col items-center justify-between md:items-start text-left gap-2 h-full">
            <div className="bg-[#337E841A] px-2 py-1 text-darkGreen font-bold text-sm uppercase">
              Investidores
            </div>
            <p className="text-Dark font-bold text-3xl">1.498.068,75€</p>
            <p className="text-Gray text-lg">Montante Investido</p>
          </div>
          <div className="sm:min-h-[140px] lg:min-h-[160px] lg:min-w-[200px] mx-5 md:mx-1 p-4 bg-white shadow-[0px_2px_25px_rgba(9,51,66,0.08)] border border-[#EBEFF2] flex flex-col items-center justify-between md:items-start text-left gap-2 h-full">
            <div className="bg-[#99CBC726] px-2 py-1 text-LightGreen font-bold text-sm uppercase">
              Projetos de I&D
            </div>
            <p className="text-Dark font-bold text-3xl">2.297.120,00€</p>
            <p className="text-Gray text-lg">Montante Investido</p>
          </div>
        </div>
        <img
          src={StatsBG}
          alt=""
          className="absolute w-full h-full object-cover left-0 top-0 z-[-1]"
        />
      </div>
    </div>
  );
}

export default StatsSection;
