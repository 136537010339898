import React from "react";
import styled from "styled-components";

const CheckboxWrapper = styled("div")`
  position: relative;
  border-radius: ${(props) => (props.squared ? 0 : "50%")};
  border: 2px solid #656565;
  z-index: 1;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  & input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
  }
  & .check {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;

    &::after {
      display: block;
      content: "";
      background-color: transparent;
      width: ${(props) => (props.squared ? "5px" : "10px")};
      height: ${(props) => (props.squared ? "10px" : "10px")};
      border-radius: ${(props) => (props.squared ? "unset" : "50%")};
      border: ${(props) => (props.squared ? "solid white" : "unset")};
      position: relative;
      top: ${(props) => (props.squared ? " 1.5px" : "3.5px")};
      left: ${(props) => (props.squared ? " 5px" : "3px")};
      border-width: ${(props) => (props.squared ? " 0 3px 3px 0" : "unset")};
      transform: ${(props) => (props.squared ? "  rotate(45deg)" : "unset")};
    }
  }
  & input:checked {
    & + .check {
      background-color: #99cbc7;
      &::after {
        background-color: ${(props) => (props.squared ? " unset" : "white")};
      }
    }
  }
`;

function CustomCheckbox({ label, id, name, value, type, align, squared }) {
  return (
    <div
      className={`flex gap-2 items-center my-2  ${
        align ? "justify-center" : "justify-start"
      }`}
    >
      <CheckboxWrapper squared={squared}>
        <input
          type={type ?? "checkbox"}
          id={id}
          name={name}
          value={value}
          className="cursor-pointer"
        />
        <div className="check" squared={squared} />
      </CheckboxWrapper>
      <label for={id} className="text-Gray">
        {label}
      </label>
    </div>
  );
}

export default CustomCheckbox;
