import React from "react";
import { ReactComponent as WhiteLogo } from "../../assets/navbar/capital_white_logo.svg";
import { ReactComponent as WhiteLogoCenter } from "../../assets/navbar/capital_center_white.svg";
import FooterBG from "../../assets/footer/footer_bg.svg";
import FooterLeftBG from "../../assets/footer/footer_bg_left.svg";
import FooterRightBG from "../../assets/footer/footer_bg_right.svg";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";

function Footer() {
  const small = useMediaQuery("(min-width:768px)");
  return (
    <div className="w-full min-h-[500px] bg-Dark pt-12 relative z-[1]">
      <div className="w-11/12 md:w-10/12 mx-auto h-full">
        <div className="flex flex-col items-center gap-10 md:gap-0 md:flex-row w-full justify-between pb-10">
          <div className="flex flex-col items-center md:items-start gap-5">
            {small ? <WhiteLogo /> : <WhiteLogoCenter />}
            <div className="flex gap-5 items-center justify-center md:justify-start text-Light">
              <FacebookRoundedIcon
                fontSize="medium"
                className="cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=100089891899597",
                    "_blank"
                  )
                }
              />
              <InstagramIcon
                fontSize="medium"
                className="cursor-pointer"
                onClick={() =>
                  window.open("https://www.instagram.com/_vcapital_/", "_blank")
                }
              />
              <LinkedInIcon
                fontSize="medium"
                className="cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/v-capital-b8272325b/",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
          <div className="flex flex-col justify-end text-white no-underline text-center md:text-right gap-3">
            <Link to="/privacy-policy?tab=0">Política de Privacidade</Link>
            <Link to="/privacy-policy?tab=1">Termos de Serviço</Link>
            <a
              href="https://www.livroreclamacoes.pt/Inicio"
              target={"_blank"}
              rel="noreferrer"
            >
              Elogios, Sugestões e Reclamações
            </a>
            <Link to="/complaint">Canal de Denúncias</Link>
          </div>
        </div>
        <div className="h-[0.5px] w-full bg-Gray" />
        <p className="text-sm text-white text-center mt-5">
          © {moment().format("YYYY")} VC - SGPS, Lda. Todos os Direitos Reservados.
        </p>
      </div>
      <div className="h-full w-full relative min-h-[300px] sm:absolute sm:min-h-0 sm:max-h-[200px] bottom-0 z-[-1]">
        <img
          src={FooterLeftBG}
          alt=""
          className="absolute bottom-0 left-0 object-contain w-full sm:h-full sm:w-auto object-top"
        />
        <img
          src={FooterBG}
          alt=""
          className="absolute bottom-0 left-1/2 -translate-x-1/2 object-contain h-3/5 object-top hidden sm:block"
        />
        <img
          src={FooterRightBG}
          alt=""
          className="absolute bottom-0 right-0 object-contain h-full object-top hidden sm:block"
        />
      </div>
    </div>
  );
}
export default Footer;
