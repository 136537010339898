import React from "react";
import styled from "styled-components";
import { ReactComponent as LargeFan } from "../../../assets/hero/large_fan.svg";
import HeroBGTest from "../../../assets/hero/hero_bg.svg";
import { useNavigate } from "react-router-dom";

export function HeroSection() {
  const CircleBG = styled("div")`
    background-color: #f8f9fa1a;
    width: 190%;
    height: 110%;
    left: -130%;
    top: -10%;
    position: absolute;
    border-radius: 50%;
    @media (min-width: 768px) {
      width: 100%;
      height: 240%;
      left: -50%;
      top: -100%;
    }
  `;
  const AnimationDiv = styled("div")`
    & #large_fan {
      & #fan {
        & #fan_center {
          transform-origin: 65px 65px;
          -webkit-animation: rotate-center 3s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
          animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s
            infinite both;
        }
      }
    }
    &.left {
      & #large_fan {
        & #fan {
          & #fan_center {
            transform-origin: 65px 65px;
            -webkit-animation: rotate-center 8.5s
              cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
            animation: rotate-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
              8.5s infinite both;
          }
        }
      }
    }
    &.middle {
      & #large_fan {
        & #fan {
          & #fan_center {
            transform-origin: 65px 65px;
            -webkit-animation: rotate-center 2.5s
              cubic-bezier(0.94, 0.46, 0.45, 0.25) infinite both reverse;
            animation: rotate-center 3s cubic-bezier(0.94, 0.46, 0.45, 0.25)
              2.5s infinite reverse;
          }
        }
      }
    }

    @keyframes rotate-center {
      0% {
        -webkit-transfoorm: rotate(0);
        transform: rotate(0);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rtate(360deg);
      }
    }
  `;

  const AnimatedLargeFan = ({ className }) => {
    return (
      <AnimationDiv className={className}>
        <LargeFan />
      </AnimationDiv>
    );
  };

  const navigate = useNavigate();
  return (
    <div className="w-full h-screen bg-darkGreen relative overflow-hidden">
      <CircleBG />
      <div className="w-11/12 md:w-10/12 mx-auto h-full">
        <div className="w-full md:w-1/2 text-left h-full flex flex-col justify-center items-start">
          <h1 className="uppercase font-bold text-4xl md:text-5xl text-white">
            Investimentos
            <br /> Sustentáveis
          </h1>
          <p className="text-white text-lg mb-7">
            A VCapital é uma sociedade veículo de investimento em empresas
            <br />
            que prezam pela sustentabilidade ambiental, social e financeira.
          </p>
          <button
            className="bg-Salmon text-white uppercase font-bold py-2 px-5 rounded z-[1]"
            onClick={() => navigate("/about")}
          >
            Sobre Nós
          </button>
        </div>
      </div>
      <div className="absolute w-full bottom-0">
        <img src={HeroBGTest} className="object-contain w-full h-full" alt="" />
        {/* <HeroBG className="object-cover w-full h-full" /> */}
        <AnimatedLargeFan
          className={"absolute w-[120px] h-[339px] bottom-0 z-0 left-[60%]"}
        />
        <AnimatedLargeFan
          className={
            "middle absolute w-[120px] h-[260px] bottom-0 z-0 hidden md:block left-[50%] md:left-[55%]"
          }
        />
        <AnimatedLargeFan
          className={
            "left absolute w-[120px] h-[200px] bottom-0 z-0 left-[45%] md:left-[50%]"
          }
        />
      </div>
    </div>
  );
}
