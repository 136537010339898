import CustomCheckbox from "../../../../components/general/CustomCheckbox";
import CustomInput from "../../../../components/general/CustomInput";

export const ThirdStep = ({ className }) => (
  <div className={className}>
    <h3 className="text-Dark font-bold text-3xl">3. Sobre a Empresa</h3>
    <p className="font-semibold text-DarkGray">
      Constituição da empresa<span className="text-[#ED1D24]">*</span>
    </p>
    <div>
      <CustomCheckbox
        type={"radio"}
        id={"Incorporado"}
        name={"Incorporado"}
        value={"Incorporado"}
        label={"Incorporado"}
      />
      <CustomCheckbox
        type={"radio"}
        id={"Incorporado"}
        name={"Incorporado"}
        value={"Ainda Não Incorporado"}
        label={"Ainda Não Incorporado"}
      />
    </div>
    <div className="flex flex-col md:flex-row items-center md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Denominação Oficial da Empresa
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Empresa"
          name={"Empresa"}
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">Morada Oficial</p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Morada"
          name={"Morada"}
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row items-center md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">Número corporativo / IVA</p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="corporativo"
          name={"corporativo"}
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">Capital Social (EUR)</p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Capital"
          name={"Capital"}
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row items-center md:gap-5">
      <div className="w-full">
        <p className="font-semibold text-DarkGray">Gestor(es) Oficial(is)</p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Gestor"
          name={"Gestor"}
        />
      </div>
      <div className="w-full">
        <p className="font-semibold text-DarkGray">
          Atividade Económica Oficial (CAE)
        </p>
        <CustomInput
          placeholder={"Escreva aqui"}
          type="text"
          id="Atividade"
          name={"Atividade"}
        />
      </div>
    </div>
  </div>
);
