import React from "react";
import Wrapper from "../../components/general/Wrapper";
import RegulationItem from "./components/regulationItem";
import { ReactComponent as ImageBG } from "../../assets/regulations/regulations_bg.svg";
import InnerNavigation from "../../components/general/InnerNavigation";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Regulations() {
  const Break = styled.div`
    height: 23px;
    border: 1px solid #ebeff2;
    border-radius: 0.25rem;
  `;
  return (
    <div className="mt-20">
      <InnerNavigation
        actualPage={"Regulamentos"}
        routerLinks={
          <div className="flex gap-2 text-Gray font-semibold">
            <Link to={"/about"} className="hover:text-LightGreen">
              Quem Somos
            </Link>
            <Break />
            <Link to={"/about/team"} className="hover:text-LightGreen">
              Equipa
            </Link>
            <Break />
            <Link to={"/about/sustain"} className="hover:text-LightGreen">
              Sustentabilidade e Responsabilidade
            </Link>
            <Break />
            <Link to={"/about/society"} className="hover:text-LightGreen">
              Identificação da Sociedade
            </Link>
          </div>
        }
      />

      <div className="mt-20 relative">
        <ImageBG className="right-0 absolute top-0" />
        <Wrapper>
          <h3 className="text-Dark font-bold text-2xl md:text-4xl text-center  mb-10 mt-20">
            Regulamentos Internos
          </h3>
          <div className="flex flex-col md:flex-row flex-wrap gap-5">
            <RegulationItem
              pdf={"/files/Vipa_Codigo_Conduta.pdf"}
              header={"Código de Conduta"}
              summary={
                "O Código de Conduta da Vipa é o documento que integra o conjunto de princípios e regras de natureza ética e deontológica que rege a atividade das empresas do grupo, sendo um guia  para a atuação de todas as entidades que se relacionam económica, institucional ou socialmente com a empresa, nos quais se incluem Fornecedores, Clientes e Parceiros de Negócio. "
              }
            />
            <RegulationItem
              pdf={"/files/Plano_de_Igualdade_de_Genero.pdf"}
              header={"Plano de Igualdade de Género"}
              summary={
                "O Plano de Igualdade de Género da VC - SGPS, Lda. reforça o conjunto de compromissos e ações que visam promover a igualdade de género através da distribuição equitativa de poder, direitos, condições e oportunidades a todos os indivíduos da sociedade."
              }
            />
            <RegulationItem
              pdf={"/files/Politica_de_Prevencao_do_Branqueamento.pdf"}
              header={
                "Política de Prevenção de Branqueamento de Capitais e do Financiamento do Terrorismo"
              }
              summary={
                "A “Política de Prevenção do Branqueamento de Capitais e do Financiamento do Terrorismo” espelha a definição pela VCapital (VC - SGPS, Lda.) dos princípios basilares a serem aplicados transversalmente no grupo societário que encabeça, no âmbito da prevenção, deteção e combate do branqueamento de capitais e do financiamento do terrorismo e de financiamento à proliferação de armas de destruição massiva."
              }
            />
            <RegulationItem
              pdf={"/files/Regime_geral_de_protecao_de_dados.pdf"}
              header={"Regulamento Geral de Proteção de Dados"}
              summary={
                "A Política de Tratamento de Dados Pessoais regula a forma como a empresa VC - SGPS, Lda. trata dados pessoais dos titulares, independentemente da natureza ou qualidade com que o fazem."
              }
            />
            <RegulationItem
              pdf={"/files/Politica_de_Conflito_de_Interesses_VCapital.pdf"}
              header={"Política de Gestão de Conflito de Interesses"}
              summary={
                "O presente documento serve para apresentar a Política de Gestão de Conflito de Interesses aplicado pela VC-SGPS, Lda."
              }
            />

            <RegulationItem
              pdf={"/files/Politica_de_Selecao_e_Avaliacao.pdf"}
              header={
                "Política de Seleção e Avaliação para a Contratação de Serviços Distintos de Auditoria Não Proibidos"
              }
              summary={
                "A presente Política visa o estabelecimento dos procedimentos de seleção avaliação para a contratação de serviços de auditoria não proibidos. O documento encontra-se de acordo e dá resposta às orientações compreendidas na legislação nacional e europeia."
              }
            />

            <RegulationItem
              pdf={"/files/Manual_Interno_de_Conformidade_VCapital.pdf"}
              header={"Manual Interno de Conformidade"}
              summary={
                "A presente norma foi elaborada de acordo com a legislação, regulamentação, recomendações e orientações vigentes em matéria de Prevenção do Branqueamento de Capitais e Combate ao Financiamento do Terrorismo (PBC/CFT) com o objetivo de dotar a Entidade-Veículo (EV) de diretrizes, normas e procedimentos de cumprimento obrigatório, relativamente a essa matéria."
              }
            />
            <RegulationItem
              header={"Política de Investimento"}
              summary={
                "A “Política de Investimento” espelha a definição pela Vipa Capital (VC - SGPS, Lda.) dos princípios basilares a serem aplicados transversalmente no grupo societário que encabeça, no âmbito do investimento em startups nas fases Pré-Seed, Seed e Early Stage que promovam um investimento de impacto localizadas em Portugal."
              }
            />
            <RegulationItem
              pdf={"/files/Estrategia_de_saida.pdf"}
              header={"Estratégia de Saída"}
              summary={
                "A estratégia de saída é parte integrante do Plano Anual de Atividades da VCapital, sendo aprovada para os 12 meses seguintes a partir da data de início da sua execução."
              }
            />
          </div>
          <h3 className="text-Dark font-bold text-2xl md:text-4xl text-center mb-10 mt-20">
            Informação Complementar
          </h3>
          <div className="flex flex-col md:flex-row flex-wrap gap-5 mb-20">
            <RegulationItem
              pdf={"https://www.apcri.pt/download/820/"}
              header={
                "Lei nº18/2015 - Regime Jurídico do Capital de Risco, do Empreendorismo Social e do Investimento Especializado"
              }
              summary={
                "Transpõe parcialmente as Diretivas n.os 2011/61/UE, do Parlamento Europeu e do Conselho, de 8 de junho, e 2013/14/UE, do Parla- mento Europeu e do Conselho, de 21 de maio, que asseguram a execução, na ordem jurídica interna, dos Regulamentos (UE) n.os 345/2013 e 346/2013, do Parlamento Europeu e do Conselho, de 17 de abril, e procede à revisão do regime aplicável ao exercício da atividade de investimento em capital de risco."
              }
            />
            <RegulationItem
              pdf={"https://www.apcri.pt/download/832/"}
              header={"Regulamento da CMVM 3/2015 Relativo ao Capital de Risco"}
              summary={
                "O presente regulamento impõe que a se proceda a uma revisão global do regime regulamentar aplicável ao capital de risco, até à data constante do Regulamento da CMVM n.o 1/2008, bem como regulamentar as atividades de empreendedorismo social e do investimento especializado."
              }
            />
            <RegulationItem
              header={
                "Contexto da UE de combate ao branqueamento de capitais e ao financiamento do terrorismo"
              }
              summary={
                <>
                  <span>
                    O combate à lavagem de dinheiro e ao financiamento do
                    terrorismo contribui para a segurança global, a integridade
                    do sistema financeiro e o crescimento sustentável.
                  </span>
                  <br />
                  <br />
                  <span>
                    Mais informações aceda ao seguinte link:
                    <br />
                  </span>
                </>

                // "Mais informações aceda ao seguinte link:" +
                // " "
              }
              link={
                <a
                  href="https://finance.ec.europa.eu/financial-crime/eu-context-anti-money-laundering-and-countering-financing-terrorism_en"
                  className="text-[#99CBC7]"
                >
                  https://finance.ec.europa.eu/financial-crime/eu-context-anti-money-laundering-and-countering-financing-terrorism_en
                </a>
              }
            />
            <RegulationItem
              pdf={"/files/AML International Best Practices.pdf"}
              header={
                "Overview international best practices on customer due diligence and related anti-money laundering measures"
              }
              summary={
                "Artigo sobre o panorama internacional de melhores práticas, sobre clientes devidos às deligências das medidas anti-branqueamento de capitais, escrito por Howard Chitimira e Sharon Munedzi da Faculdade de Direito, North West University, Potchefstroom, África do Sul. Journal of Money Laundering Control Vol. 26 No. 7, 2023 pp. 53-62 Emerald Publishing Limited 1368-5201 DOI 10.1108/JMLC-07-2022-0102"
              }
            />
            <RegulationItem
              doc={"/files/Ficha KYC - Pessoas Singulares.docx"}
              header={"KYC Pessoas Singulares"}
              summary={
                "Política de KYC para aferir AML/CFT, a EV cumpre escrupulosamente as melhores práticas internacionais, sendo que todos os sócios/acionistas da V Capital, e suas participadas, são previamente validados segundo as melhores práticas de KYC relativamente a temas de AML/CFT;"
              }
            />
            <RegulationItem
              doc={"/files/Ficha KYC - Pessoas Coletivas.docx"}
              header={"KYC Pessoas Coletivas"}
              summary={
                "Política de KYC para aferir AML/CFT, a EV cumpre escrupulosamente as melhores práticas internacionais, sendo que todos os sócios/acionistas da V Capital, e suas participadas, são previamente validados segundo as melhores práticas de KYC relativamente a temas de AML/CFT;"
              }
            />
          </div>
        </Wrapper>
      </div>
    </div>
  );
}

export default Regulations;
